import { Assets, Container, Graphics, Sprite, Spritesheet} from "pixi.js";
import gsap from "gsap";


export class PentagonalMeter extends Container {
    private pentagonalFontTexture !: Spritesheet;
    private pentagonalMeter !: Container;
    private newBox !: Container;
    private winMeterGsapTween !: gsap.core.Tween;
    private lastTargetValue : number = 0;
    private currentTargetValue : number = 0;


    constructor() {
        super();
        this.init();
        this.addToStage();
    }

    private init(): void {
        this.pentagonalFontTexture = Assets.get("pentonalfont");
        this.pentagonalMeter = new Container();
        this.newBox = new Container();
        const graphics: Graphics = new Graphics().rect(0, 0, 100, 65.5).fill("#000000");
        graphics.alpha = 0;
        this.newBox.addChild(graphics);
        this.pentagonalMeter.scale.set(0.55);
        this.setNumber('0/50');
        this.updatePentagonalMeterPosition();
    }

    private updatePentagonalMeterPosition() :void{
        this.pentagonalMeter.position.set(((this.newBox.width - this.pentagonalMeter.width) * 0.5),
        ((this.newBox.height - this.pentagonalMeter.height) * 0.5));
    }

    private setNumber(value: string) {
        this.pentagonalMeter.removeChildren(0, this.pentagonalMeter.children.length)
        const digits = value.split("");
        let xOffset = 0;
        digits.forEach((digit) => {
            if (digit === "/") {
                digit = 'slash'
            }
            const digitSprite = new Sprite(this.pentagonalFontTexture.textures[`${digit}.png`]);
            digitSprite.x = xOffset;
            digit === "slash" && (digitSprite.y = 0);
            this.addChild(digitSprite);
            this.pentagonalMeter.addChild(digitSprite);
            xOffset += digitSprite.width * 0.7; // Adjust spacing
        });
    }

    private playIncrementAnimation(targetValue: number) {
        let tweenObj = { value: this.currentTargetValue };

        if (targetValue <= 0) {
            this.setNumber(`${0}/50`);
            this.lastTargetValue = 0;
            this.updatePentagonalMeterPosition();
            return;
        }
        // this.currentTargetValue = this.lastTargetValue;
        this.winMeterGsapTween = gsap.to(tweenObj, {
            ease: 'power1.out',
            value: targetValue,
            duration: 0.5,
            onUpdate: () => {
                this.currentTargetValue = Math.round(tweenObj.value);
                this.setNumber(`${this.currentTargetValue}/50`);
                this.updatePentagonalMeterPosition();
            },
            onComplete: () => {
                this.lastTargetValue = this.currentTargetValue;
            }
        });
        this.winMeterGsapTween.play();
    }

    private addToStage(): void {
        this.addChild(this.newBox);
        this.newBox.addChild(this.pentagonalMeter);
    }

    updatePentagonalCount(value: number): void {
        if (value > 50) {
            value = 50;
        }
        this.currentTargetValue = this.lastTargetValue;
        // this.setNumber(`${value}/50`);
        this.playIncrementAnimation(value);
        this.updatePentagonalMeterPosition();
    }

    resetWinAmount(): void {
        this.setNumber(`${0}/50`);
        this.lastTargetValue = 0;
        this.updatePentagonalMeterPosition();
    }

}