import { Assets, Container, Sprite } from "pixi.js";

export class LargeWinTitleContainer extends Container{
    private textBg !: Sprite;
    private text !: Sprite;
    constructor(private state : string, private winstate : string){
        super();
        this.init();
        this.addtoStage();
        this.scale.set(0.5);
        this.pivot.set(this.width/2,this.height/2);
    }

    private init() {
        this.textBg = new Sprite(Assets.get(`background_${this.winstate}`));
        // this.textBg.anchor.set(0.5);
        this.text = new Sprite(Assets.get(`text_${this.winstate}`));
        // this.text.anchor.set(0.5);
        this.text.position.set((this.textBg.width - this.text.width)/2,(this.textBg.height - this.text.height)/3);
    }

    private addtoStage() {
        this.addChild(this.textBg);
        this.addChild(this.text);
    }

    updateTextureState(newWinState : string):void{
       this.winstate = newWinState;
       this.textBg.texture = Assets.get(`background_${this.winstate}`);
       this.text.texture = Assets.get(`text_${this.winstate}`);
       this.text.position.set((this.textBg.width - this.text.width)/2,(this.textBg.height - this.text.height)/3);
    //    this.pivot.set(this.width,this.height);
    }
}