import { Container } from "pixi.js";
import { StaticSymbol } from "../Symbol/StaticSymbol";
import { StaticWild3x3 } from "../Symbol/StaticWild3x3";
import { Game } from "../game";
import { CommonConfig } from "@/Common/CommonConfig";
import { Spine } from "@esotericsoftware/spine-pixi-v8";
import gsap from "gsap";
import { SingleWinLine } from "../Winframe/SingleWinLine";

export class FreeGamePos extends Container {
    symContainer !: Container;
    private blastAnimation !: Spine;
    private replacementAnimation !: Spine;
    private winlineAnim !: SingleWinLine;
    private symName : string ="";

    constructor(private reelId : number) {
        super();
        this.symContainer = new Container();
        this.addChildren();
        this.blastAnimation = Spine.from({ skeleton: "Symbol_Replace_FX_spine_data", atlas: "Symbol_Replace_FX_spine_atlas" });
        this.addChild(this.blastAnimation);
        this.replacementAnimation = Spine.from({ skeleton: "Symbol_Replace_V2_spine_data", atlas: "Symbol_Replace_V2_spine_atlas" });
        this.addChild(this.replacementAnimation);
        this.blastAnimation.scale.set(1.2);
        this.winlineAnim = new SingleWinLine();
        this.addChild(this.winlineAnim);
        this.blastAnimation.visible = false;
        this.replacementAnimation.scale.set(0.5);
        this.replacementAnimation.visible = false;
        Game.the.app.stage.on(CommonConfig.FG_SPIN_STOPPED, this.onSpinStopped, this);
        Game.the.app.stage.on(CommonConfig.FG_START_SPIN, this.removeDark, this);
        Game.the.app.stage.on(CommonConfig.DARKEN_SYMBOL, this.darkenSymbol, this);
    }

    private darkenSymbol(): void {
        this.alpha = 0.35;
    }

    private removeDark(): void {
        this.alpha = 1;
        this.symContainer.alpha = 1;
    }


    private onSpinStopped(): void {
        this.alpha = 1;
        this.symContainer.alpha = 1;
    }

    private addChildren(): void {
        this.addChild(this.symContainer);
    }

    public getSymContainer(): Container {
        return this.symContainer;
    }

    updatePosWithSym(sym: StaticSymbol): void {
        // this.addChildren();
        if (this.symContainer.children.length) {
            this.symContainer.removeChildren();
            this.symContainer.addChild(sym);
        } else {
            this.symContainer.addChild(sym);
        }
        this.symName = sym.name;
    }

    playLanding(): Promise<void> {
        return new Promise<void>((resolve) => {
            if (this.symContainer.children.length > 0) {
                const staticSymbol = this.symContainer.children[0];
                if (staticSymbol.name === "sym_3x3Wild") {
                    (staticSymbol as StaticWild3x3).playLandingAnimation().then(() => {
                        resolve();
                    });
                } else {
                    (staticSymbol as StaticSymbol).playLandingAnimation().then(() => {
                        resolve();
                    });
                }
            } else {
                resolve();
            }
        });
    }

    private isInWild() :boolean{
        if((this.reelId >= CommonConfig.the.getInitial3x3WildGridId()[0] && this.reelId <= CommonConfig.the.getInitial3x3WildGridId()[0] + 2) && 
        (this.rowId() >= CommonConfig.the.getInitial3x3WildGridId()[1] && this.rowId() <= CommonConfig.the.getInitial3x3WildGridId()[1] + 2)){
            return true;
        }
        return false;
    }

    private rowId() :number{
        return this.y / CommonConfig.symbolHeight;
    }

    updatePosWith3x3Wild(sym: StaticWild3x3): void {
        // this.addChildren();
        if (this.symContainer.children.length) {
            this.symContainer.removeChildren();
            this.symContainer.addChild(sym);
             if(sym.name === "sym_3x3Wild"){
                this.setChildIndex(this.symContainer,this.children.length-1);
            }
        } else {
            this.symContainer.addChild(sym);
        }
        this.symName = sym.name;
    }

    updateSymbolContainerPosition(x: number, y: number): void {
        this.symContainer.position.set(x, y);
    }

    playAnimation(is3x3Wild : boolean): Promise<void> {
        this.winlineAnim.updatePosition(is3x3Wild);
        this.winlineAnim.playAnimation();
        // (this.symName !== "sym_3x3Wild" && !this.isInWild()) && this.winlineAnim.playAnimation();
        return new Promise<void>((resolve) => {
            this.alpha = 1;
            if (this.symContainer.children.length > 0) {
                const staticSymbol = this.symContainer.children[0] as StaticSymbol | StaticWild3x3;
                staticSymbol.playSpineAnimation().then(() => {
                    resolve();
                    this.winlineAnim.hideAnimation();
                });
            } else {
                resolve();
            }
        });
    }

    updateWild(sym: StaticSymbol): Promise<void> {
        return new Promise<void>((resolve) => {
            this.alpha = 1;
            gsap.delayedCall(0.2,()=>this.update(sym));
            this.replacementAnimation.visible = true;
            this.replacementAnimation.state.setAnimation(0, 'animation', false);
            this.replacementAnimation.state.addListener({
                complete: () => {
                    this.replacementAnimation.visible = false;
                    resolve();
                },
            });
        });
    }

    hideMusicalAnimation(): Promise<void> {
        return new Promise<void>((resolve) => {
            if (this.symContainer.children.length > 0) {
                this.alpha = 1;
                const staticSymbol = this.symContainer.children[0] as StaticSymbol;
                staticSymbol.hideMusical().then(() => {
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    private update(sym: StaticSymbol): void {
        if (this.symContainer.children.length) {
            this.symContainer.removeChildren();
            this.symContainer.addChild(sym);
            this.alpha = 1;
        } else {
            this.symContainer.addChild(sym);
        }
    }

    hideAnimation(): Promise<void> {
        return new Promise<void>((resolve) => {
            if (this.symContainer.children.length > 0) {
                const staticSymbol = this.symContainer.children[0];
                if (staticSymbol.name === "sym_3x3Wild") {
                    (staticSymbol as StaticWild3x3).hideSpineAnimation().then(() => {
                        resolve();
                    });
                } else {
                    this.blastAnimation.visible = true;
                    this.blastAnimation.state.setAnimation(0, 'animation', false);
                    this.blastAnimation.state.addListener({
                        complete: () => {
                            this.blastAnimation.visible = false;
                            resolve();
                        },
                    });
                }
            } else {
                resolve();
            }
        });
    }
}