import { Assets, Container, Graphics, Sprite, Spritesheet } from "pixi.js";
import { Game } from "../game";
import { CommonConfig } from "@/Common/CommonConfig";
import gsap from "gsap";
import { Spine } from "@esotericsoftware/spine-pixi-v8";

export class Intro extends Container {
    private bgGraphics !: Graphics;
    private bg !: Sprite;
    private logo !: Sprite;
    private page1 !: Container;
    private page2 !: Container;
    private continueBtn !: Sprite;
    private introContentTexture !: Spritesheet;
    private introTextBtnTexture !: Spritesheet;
    private introFrameBackground !: Sprite;
    private aspectRatio: number = 0;
    private aspectRatioMobile: number = 0;
    private maskGraphics !: Graphics;
    private pageContent !: Container;
    private pageContainer !: Container;
    private content !: Container;
    private isStopAnimaton : boolean = false;
    private introSpine !: Spine;
    private introContainer !: Container;
    private background_portrait !: Sprite;
    private pentagonalSpine !: Spine;


    constructor() {
        super();
        Game.the.app.stage.on(CommonConfig.SHOW_INTRO_PAGE, this.initializeIntro, this);
        Game.the.app.stage.on("RESIZE_THE_APP", this.setToPosition, this);

    }

    private hide(): void {
        this.visible = false;
    }

    private initializeIntro(): void {
        this.introContainer = new Container();
        this.introContainer.visible = false;
        this.init();
        this.initMask();
        this.addToStage();
        this.setToPosition();
        this.content.mask = this.maskGraphics;
        this.continueBtn.interactive = false;
        this.subscribeEvent();
        Game.the.app.stage.on("RESIZE_THE_APP", this.setToPosition, this);
    }

    private subscribeEvent() :void{
        this.continueBtn.on('pointerdown', this.onButtonDown, this)
        .on('pointerup', this.onButtonUp, this);
    }

    private onButtonDown() :void{
        this.continueBtn.texture = this.introTextBtnTexture.textures["btn_continue_pressed.png"];
    }

    private onButtonUp() :void{
        this.continueBtn.texture = this.introTextBtnTexture.textures["btn_continue_regular.png"];
        this.continueBtn.interactive = false;
        if(this.introContainer.visible){
            gsap.killTweensOf(this);
            this.isStopAnimaton = true;
            Game.the.app.stage.emit(CommonConfig.HIDE_LOADING_SCREEN);
            gsap.to(this, {
                alpha : 0,
                duration : 0.5,
                ease: 'power1.out',
                onComplete:()=>{
                    this.visible = false;
                    Game.the.app.stage.emit(CommonConfig.HIDE_INTRO_PAGE_SHOW_BASEGAME);
                }
               }
            )
        }else{
            this.hideSpineShowPages();
            this.pageAnimation();
        }
    }

    private initMask() :void{
        this.maskGraphics = new Graphics();
        this.maskGraphics.beginFill(0x000000, 0.65);
        this.maskGraphics.drawRect(0, 0, this.page1.width *  1.1, this.page1.height *  1.6);
        this.maskGraphics.endFill();
    }

    private init(): void {
        // this.addChild(this.bgGraphics);
        // this.bgGraphics.interactive = true;
        this.bg = new Sprite(Assets.get("intro_background"));
        this.addChild(this.bg);
        this.bg.y= -140;
        this.content = new Container();
        this.pageContent = new Container();
        this.pageContainer = new Container();
        this.page1 = new Container();
        this.page2 = new Container();
        this.introTextBtnTexture = Assets.get("introTextBtn");
        this.introContentTexture = Assets.get("introContent");
        this.logo = new Sprite(this.introTextBtnTexture.textures["intro_logo.png"]);
        this.continueBtn = new Sprite(this.introTextBtnTexture.textures["btn_continue_regular.png"]);
        this.background_portrait = new Sprite(Assets.get("intro_background_portrait"));
        this.background_portrait.scale.set(2.8);
        this.background_portrait.position.set(-223,-109);
        this.background_portrait.alpha = 1;
        this.background_portrait!.visible = false
        this.initPage1();
        this.initPage2();
        this.pageContent.scale.set(0.65);
        this.aspectRatio = this.pageContent.height / 919;
        this.aspectRatioMobile = this.pageContent.width / 360;
        this.continueBtn.visible = false;
        this.introSpine = Spine.from({ skeleton: "Intro_page_spine_data", atlas: "Intro_page_spine_atlas" });
        this.introSpine.state.setAnimation(0, 'intro', false);
        this.introSpine.state.addListener({
            complete: () => {
                if (this.introSpine) {
                    this.introSpine.state.clearListeners()
                    this.onButtonUp();
                }
            },
        });

        // 
    }

    private initPage1(): void {
        const introFrameBackground : Sprite = new Sprite(Assets.get("intro_frame_background"));
        const pentagonalFeature: Spine = Spine.from({skeleton :"pentagon_spine_assets",atlas:"pentagon_spine_atlas"});
        const giantBasculoWild: Spine = Spine.from({skeleton :"sym_3x3Wild_spine_data",atlas:"sym_3x3Wild_spine_atlas"});
        const symbolZwoom: Spine = Spine.from({skeleton :"gameIntroFeature_spine_data",atlas:"gameIntroFeature_spine_atlas"});
        const pentagonalFeatureText: Sprite = new Sprite(this.introTextBtnTexture.textures["intro_text_special_game.png"]);
        const giantBasculoWildText: Sprite = new Sprite(this.introTextBtnTexture.textures["intro_text_giant_basculo_wild.png"]);
        const symbolZwoomText: Sprite = new Sprite(this.introTextBtnTexture.textures["intro_text_zwoom.png"]);
        pentagonalFeature.scale.set(0.27);
        pentagonalFeature.position.set(217,270);
        giantBasculoWild.state.setAnimation(0,"loop",true);
        pentagonalFeature.state.setAnimation(0,"pentagon_fill_intro_screen",true);
        giantBasculoWild.scale.set(0.25);
        giantBasculoWild.position.set(579.5,400);

        symbolZwoom.state.setAnimation(0,"zwoom",true);
        symbolZwoom.scale.set(0.25);
        symbolZwoom.position.set(958,272.5);
        pentagonalFeatureText.position.set(102.403, 438.462);
        giantBasculoWildText.position.set(422.214, 441);
        symbolZwoomText.position.set(883.541, 441);
        this.pageContent.addChild(introFrameBackground);
        this.page1.addChild(pentagonalFeature);
        this.page1.addChild(giantBasculoWild);
        this.page1.addChild(symbolZwoom);
        this.page1.addChild(pentagonalFeatureText);
        this.page1.addChild(giantBasculoWildText);
        this.page1.addChild(symbolZwoomText);
    }

    private initPage2(): void {
        const introFrameBackground : Sprite = new Sprite(Assets.get("intro_frame_background"));
        const image1: Spine = Spine.from({skeleton :"gameIntroFeature_spine_data",atlas:"gameIntroFeature_spine_atlas"});
        const image2: Sprite = new Sprite(this.introContentTexture.textures["intro_symbol_bonus_game.png"]);
        const image3: Spine = Spine.from({skeleton :"gameIntroFeature_spine_data",atlas:"gameIntroFeature_spine_atlas"});
        const image1Text: Sprite = new Sprite(this.introTextBtnTexture.textures["intro_text_crepazione.png"]);
        const image2Text: Sprite = new Sprite(this.introTextBtnTexture.textures["intro_text_bonus_game.png"]);
        const image3Text: Sprite = new Sprite(this.introTextBtnTexture.textures["intro_text_pistole.png"]);
        image1.state.setAnimation(0,"creapazone",true);
        image1.scale.set(0.3);
        image3.state.setAnimation(0,"pistole",true);
        image3.scale.set(0.3);
        image1.position.set(211.5,248)
        image2.position.set(420.599,109.45);
        image3.position.set(956.666,248);
        image1Text.position.set(108.549, 438.9);
        image2Text.position.set(482.599, 438.9);
        image3Text.position.set(881.215, 438.9);
        // this.page2.addChild(introFrameBackground);
        this.page2.addChild(image1);
        this.page2.addChild(image2);
        this.page2.addChild(image3);
        this.page2.addChild(image1Text);
        this.page2.addChild(image2Text);
        this.page2.addChild(image3Text);
    }

    private pageAnimation() :void{
        if(this.isStopAnimaton){
            return;
        }
        this.page2.x = -(this.page2.x + (this.page2.width * 1.3))
        gsap.to(this.page1, {
            x : this.page1.x + (this.page1.width * 1.3),
            duration : 0.5,
            delay : 3,
            ease: 'power1.out',
            onComplete:()=>{
                gsap.to(this.page2, {
                    x : 0,
                    duration : 0.5,
                    delay : 0,
                    ease: 'power1.out',
                    onComplete:()=>{
                        this.page1.x = -(this.page1.x + (this.page1.width * 1.3))
                        gsap.to(this.page2, {
                            x : this.page2.x + (this.page2.width * 1.3),
                            duration : 0.5,
                            delay : 3,
                            ease: 'power1.out',
                            onComplete:()=>{
                                gsap.to(this.page1, {
                                    x : 0,
                                    duration : 0.5,
                                    delay : 0,
                                    ease: 'power1.out',
                                    onComplete:()=>{
                                       this.pageAnimation();
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })
    }

    private addToStage(): void {
        this.addChild(this.background_portrait);
        this.addChild(this.introContainer);
        this.introContainer.addChild(this.content);
        this.content.addChild(this.pageContent);
        this.content.addChild(this.pageContainer);
        this.introContainer.addChild(this.logo);
        this.pageContainer.addChild(this.page1);
        this.pageContainer.addChild(this.page2);
        this.introContainer.addChild(this.maskGraphics);
        this.addChild(this.continueBtn);
        this.addChild(this.introSpine);
    }

    private hideSpineShowPages() :void{
        gsap.to(this.introSpine,{
            duration : 0.5,
            alpha : 0,
            ease : "power4.in",
            onComplete :()=>{
                this.introContainer.visible = true;
                this.continueBtn.interactive = true;
                this.continueBtn.visible = true;
            }
        })
    }

    private setToPosition(): void {
        if((window.innerHeight > window.innerWidth)){
            this.setToPositionMobile();
            return;
        }
        this.background_portrait && (this.background_portrait!.visible = false);
        if(this.bg){
            this.bg!.visible = true;
            this.bg.scale.set(1.22);
            this.bg.position.set(-220.5,-182.5);
        }
        this.logo?.position.set((1920 - this.logo.width) / 2, 50);
        this.pageContent?.scale.set(0.8);
        this.pageContent?.scale.set(1);
        this.pageContainer?.scale.set(1);
        if(this.introContainer){
            this.introContainer?.scale.set(1);
            this.introContainer?.position.set(0,-74);
        }
        this.pageContent?.position.set((1920 - this.pageContent.width) / 2, this.logo.y + this.logo.height + 20);
        this.pageContainer?.position.set((1920 - this.pageContent.width) / 2, this.logo.y + this.logo.height + 20);
        this.maskGraphics?.scale.set(1);
        this.maskGraphics?.position.set((1920 - this.maskGraphics.width) / 2, this.logo.y + this.logo.height - 20);
        this.introSpine?.scale.set(1);
        this.continueBtn?.scale.set(1);
        this.introSpine?.position.set(969.5,439.133);
        this.continueBtn?.position.set(830.25,766.301);
        this.maskGraphics?.scale.set(1.04,1);
        this.maskGraphics?.position.set(374.408,153);
    }

    private setToPositionMobile() :void{
        // this.bg?.scale.set(0.6,1.8);
        // this.bg?.position.set(-228.5,-140);
        this.bg && (this.bg.visible = false);
        this.background_portrait && (this.background_portrait.visible = true);
        this.introSpine?.position.set(345.5,836.5);
        this.continueBtn?.position.set(211.75,1187.3);
        this.introContainer?.scale.set(1.1);
        this.introContainer?.position.set(-68,589.5);
        this.logo?.scale.set(1.4);
        this.logo?.position.set(70,-339);
        this.pageContainer?.scale.set(0.65);
        this.pageContent?.scale.set(0.65);
        this.pageContent?.position.set(0,0);
        this.pageContainer?.position.set(0,0);
        this.maskGraphics?.position.set(0,0);
        this.maskGraphics?.scale.set(0.68,1);
    }
}