import { Assets, Container, Sprite, Spritesheet, Texture } from "pixi.js";
import { Game } from "../game";
import { Spine } from "@esotericsoftware/spine-pixi-v8";
import SoundManager from "../Sound/SoundManager";
import { CommonConfig } from "@/Common/CommonConfig";

export class BackgroundView extends Container {
    private bg !: Sprite;
    private soundManager !: SoundManager;
    private bgSpine !: Spine;
    private gameplay_background_portrait !: Sprite;
    private bigWinSpine2 !: Spine;

    constructor() {
        super();
        this.soundManager = SoundManager.getInstance();
        this.intializeBg();
        this.addContainerToStage();
        this.resizeApp();
        this.playBgSound();
        Game.the.app.stage.on(CommonConfig.PLAY_BG_SOUND, this.playBgSound, this);
        Game.the.app.stage.on(CommonConfig.STOP_BG_SOUND, this.stopBgSound, this);
        Game.the.app.stage.on("RESIZE_THE_APP", this.resizeApp, this);
    }

    private intializeBg(): void {
        this.gameplay_background_portrait = new Sprite(Assets.get("gameplay_background_portrait"));
        this.bg = new Sprite(Assets.get("background"));
        this.bg.y = -60;
        this.bgSpine = Spine.from({ skeleton: "gameplay_backgrounds_vfx_spine_data", atlas: "gameplay_backgrounds_vfx_spine_atlas" });
        this.bgSpine.pivot.set(this.bgSpine.width / 2, this.bgSpine.height / 2);
        this.bgSpine.position.set(1970, 1584);
        this.gameplay_background_portrait.visible = true;
        this.gameplay_background_portrait.scale.set(2.1);
        this.gameplay_background_portrait.position.set(-93, -53.5);
    }


    private playBgSound(): void {
        this.soundManager.play('background');
    }

    private stopBgSound(): void {
        this.soundManager.stop('background');
    }

    private resizeApp(): void {
        this.scale.set(1);
        this.position.set(0, -90);
        if ((window.innerHeight > window.innerWidth)) {
            this.gameplay_background_portrait.visible = true;
            this.bg.visible = false;
            this.bgSpine.visible = false;
            // this.scale.set(0.5,1.6);
            // this.position.set(-143,29.5);
            this.scale.set(1.2);
            this.position.set(-71, -90);
        } else {
            this.gameplay_background_portrait.visible = false;
            this.bg.visible = true;
            this.bgSpine.visible = true;
            this.scale.set(1.2);
            this.position.set(-212.5, -96);
        }
    }

    private addContainerToStage() {
        this.addChild(this.bg);
        this.addChild(this.bgSpine);
        this.addChild(this.gameplay_background_portrait);
        this.bgSpine.state.setAnimation(0, 'gameplay_vfx', true);
    }

}


