import { Spine } from "@esotericsoftware/spine-pixi-v8";
import { Container } from "pixi.js";

export class SingleShootingFx extends Container {
    private shootingFx !: Spine;
    constructor(private state : string) {
        super();
        this.init();
    }

    private init(): void {
        this.shootingFx = Spine.from({ skeleton: "Shoot_animation_spine_data", atlas: "Shoot_animation_spine_atlas" });
        this.shootingFx.pivot.set(this.shootingFx.width/2,this.shootingFx.height/2);
        this.shootingFx.position.set(this.shootingFx.width/2,this.shootingFx.height/2);
        this.shootingFx.state.setAnimation(0,"animation",true);
        this.addChild(this.shootingFx);
    }

    stopAnimation() :void{
        this.shootingFx.state.clearListeners()
        this.shootingFx.state.tracks = []
    }
}