import { Assets, ColorSource, Container, Graphics, Sprite, Spritesheet, } from "pixi.js";
import { Game } from "../game";
import { CommonConfig } from "@/Common/CommonConfig";
import { PentagonalMeter } from "../Meter/PentagonalMeter";
import gsap from "gsap";
import { Spine } from "@esotericsoftware/spine-pixi-v8";


export class PentagonalUpdateFeature extends Container {

    private pentagonalBackContainer !: Container;
    private pentagonalTopContainer !: Container;
    private filledPentagonalContainer !: Container;
    private feature_Texture !: Spritesheet;
    private pentagon_fill_image !: Sprite;
    private pentagon_center_frame !: Sprite;
    private maskContainer !: Container;
    private maskGraphics !: Graphics;
    private counterMeter !: PentagonalMeter;
    private pentagon_front_frame!: Sprite;
    private currentpercemtage: number = 0;
    private randomFeatureIndicatorContainer !: Container;
    private state: string;
    private pentagonalSpine !: Spine;
    private pentagonalBackGlow !: Spine;
    private pentagonalMiddleWhiteGlow !: Spine;
    private pentagonalProgressColorChange !: Spine;
    private lastPersentage: number = 0;
    private pentagonalColorChangeContainer !: Container;
    private starAnimation !: Spine;
    private pentagonalLoopWhiteGlow !: Spine;


    constructor(state: string) {
        super();
        this.state = state;
        this.feature_Texture = Assets.get("pentagon_assets");
        this.init();
        this.addToStage();
        this.initRandomFeatureContainer();
        if (this.state === CommonConfig.BASE_GAME) {
            this.subscribeEvent();
        } else {
            this.subscribeFGEvent();
        }
        this.resetsOnSpinClick();
        this.playLoopAlphaAnimation();
        // this.reverseAnimation();
    }

    private subscribeEvent(): void {
        Game.the.app.stage.on(CommonConfig.UPDATE_PENTAGONAL_METER, this.updatePentagonalMeter, this);
        Game.the.app.stage.on(CommonConfig.START_SPIN, this.resetsOnSpinClick, this);
    }

    private subscribeFGEvent(): void {
        // Game.the.app.stage.on(CommonConfig.FG_UPDATE_PENTAGONAL_METER, this.updatePentagonalMeter, this);
        // Game.the.app.stage.on(CommonConfig.FG_START_SPIN, this.resetsOnSpinClick, this);
    }

    private resetsOnSpinClick(): void {
        if (this.state !== CommonConfig.the.getCurrentState()) {
            return;
        }
        this.pentagonalColorChangeContainer.visible = false;
        // this.pentagonalSpine.visible = false;
        this.reverseAnimation();
        this.currentpercemtage = 0;
        // this.lastPersentage = 0;
        this.counterMeter.updatePentagonalCount(0);
        // this.reverseAnimation();
        for (let i: number = 0; i < 5; i++) {
            this.randomFeatureIndicatorContainer.children[i].alpha = 0;
        }
        this.pentagonalLoopWhiteGlow.visible = false;
        this.starAnimation.visible = false;
    }

    private init(): void {
        this.pentagonalBackContainer = new Container();
        this.pentagonalTopContainer = new Container();
        this.filledPentagonalContainer = new Container();
        this.maskContainer = new Container();
        this.pentagonalColorChangeContainer = new Container();

        this.pentagonalBackGlow = Spine.from({ skeleton: "pentagon_spine_assets", atlas: "pentagon_spine_atlas" });
        this.pentagonalBackGlow.scale.set(0.32);
        this.pentagonalBackGlow.pivot.set(this.pentagonalBackGlow.width / 2, this.pentagonalBackGlow.height / 2);
        this.pentagonalBackGlow.position.set(220, 200);
        // this.pentagonalBackGlow.state.setAnimation(0, 'bg_glow_loop', true);

        let pentagon_back_black_bg: Sprite = new Sprite(this.feature_Texture.textures['pentagon_back_black.png']);

        this.pentagonalMiddleWhiteGlow = Spine.from({ skeleton: "pentagon_spine_assets", atlas: "pentagon_spine_atlas" });
        this.pentagonalMiddleWhiteGlow.scale.set(0.34);
        this.pentagonalMiddleWhiteGlow.pivot.set(this.pentagonalMiddleWhiteGlow.width / 2, this.pentagonalMiddleWhiteGlow.height / 2);
        this.pentagonalMiddleWhiteGlow.position.set(232, 215);

        this.pentagonalLoopWhiteGlow = Spine.from({ skeleton: "pentagon_spine_assets", atlas: "pentagon_spine_atlas" });
        this.pentagonalLoopWhiteGlow.scale.set(0.34);
        this.pentagonalLoopWhiteGlow.pivot.set(this.pentagonalLoopWhiteGlow.width / 2, this.pentagonalLoopWhiteGlow.height / 2);
        this.pentagonalLoopWhiteGlow.position.set(232, 215);

        this.pentagonalProgressColorChange = Spine.from({ skeleton: "pentagon_spine_assets", atlas: "pentagon_spine_atlas" });
        this.pentagonalProgressColorChange.scale.set(0.32);
        this.pentagonalProgressColorChange.pivot.set(this.pentagonalProgressColorChange.width / 2, this.pentagonalProgressColorChange.height / 2);
        this.pentagonalProgressColorChange.position.set(227, 205);
        this.pentagonalProgressColorChange.alpha = 1;
        this.pentagonalProgressColorChange.state.setAnimation(0, 'pentagon_under_progress_colour_change', true);

        let pentagon_frame: Sprite = new Sprite(this.feature_Texture.textures['pentagon_back.png']);
        this.pentagon_fill_image = new Sprite(this.feature_Texture.textures['pentagon_fill_5.png']);
        this.pentagon_front_frame = new Sprite(this.feature_Texture.textures['pentagon_front.png']);
        this.pentagon_center_frame = new Sprite(this.feature_Texture.textures['pentagon_middle_icon.png']);

        this.pentagonalBackContainer.addChild(this.pentagonalBackGlow);

        this.pentagonalBackContainer.addChild(pentagon_back_black_bg);
        this.pentagonalBackContainer.addChild(this.pentagonalMiddleWhiteGlow);
        this.pentagonalBackContainer.addChild(this.pentagonalLoopWhiteGlow);
        this.pentagonalBackContainer.addChild(this.pentagonalColorChangeContainer);
        // this.pentagonalColorChangeContainer.addChild(this.pentagonalProgressColorChange);

        // this.filledPentagonalContainer.addChild(this.pentagon_fill_image);
        this.pentagonalTopContainer.addChild(pentagon_frame);
        this.pentagonalTopContainer.addChild(this.pentagon_front_frame);
        this.pentagonalTopContainer.addChild(this.pentagon_center_frame);
        this.pentagon_center_frame.position.set((pentagon_back_black_bg.width - this.pentagon_center_frame.width) / 2, (this.pentagon_front_frame.height - this.pentagon_center_frame.height) / 2 + 5);

        this.maskGraphics = new Graphics();
        this.maskGraphics.position.set(this.pentagon_front_frame.x + this.pentagon_front_frame.width / 2, this.pentagon_front_frame.y + this.pentagon_front_frame.height / 2);

        this.counterMeter = new PentagonalMeter();
        this.counterMeter.position.set(this.pentagon_center_frame.x + (this.pentagon_center_frame.width - this.counterMeter.width) / 2, this.pentagon_center_frame.y + (this.pentagon_center_frame.height - this.counterMeter.height) / 2);
        this.maskContainer.addChild(this.maskGraphics);
        // this.pentagonalColorChangeContainer.mask = this.maskGraphics;

        this.pentagonalSpine = Spine.from({ skeleton: "pentagon_spine_assets", atlas: "pentagon_spine_atlas" });
        this.pentagonalSpine.scale.set(0.32);
        this.pentagonalSpine.pivot.set(this.pentagonalSpine.width / 2, this.pentagonalSpine.height / 2);
        this.pentagonalSpine.position.set(223, 204);
        // this.pentagonalSpine.visible = false;
        this.filledPentagonalContainer.addChild(this.pentagonalSpine);
        this.loopAlphaAnimation();
        this.pentagonalBackGlow.visible = false;
        this.pentagonalMiddleWhiteGlow.visible = false;
        this.pentagonalLoopWhiteGlow.visible = false;
        this.starAnimation = Spine.from({skeleton :"pentagon_spine_assets",atlas :"pentagon_spine_atlas"});
        this.starAnimation.scale.set(0.35);
        this.starAnimation.position.set(154.5,137);
        this.starAnimation.state.setAnimation(0,"star",true);


        // console.clear();
    }

    private playLoopAlphaAnimation(): void {

        // this.pentagonalMiddleWhiteGlow.state.setAnimation(0, 'middle_white_glow_loop', false);
        this.pentagonalLoopWhiteGlow.visible = false;
        this.pentagonalLoopWhiteGlow.state.setAnimation(0, "middle_white_glow_loop", true);
        this.pentagonalLoopWhiteGlow.state.timeScale = 0.25;
        const colorCodes : ColorSource[] = [0xE92BEC,0xE2EC2B,0x3AE7FF];
        this.pentagonalLoopWhiteGlow.tint = 0xE92BEC;
        this.pentagonalLoopWhiteGlow.alpha = 0.5;
        this.pentagonalLoopWhiteGlow.state.addListener({
            complete: () => {
                const colorCode = colorCodes[Math.floor(Math.random() * colorCodes.length)];
                this.pentagonalLoopWhiteGlow.tint = colorCode;
            }
        })
    }


    private playCompleteAnimation(): void {

        // this.pentagonalMiddleWhiteGlow.state.setAnimation(0, 'middle_white_glow_loop', false);
        this.pentagonalMiddleWhiteGlow.visible = true;
        this.pentagonalMiddleWhiteGlow.state.setAnimation(0, "middle_white_glow_loop", false);
        this.pentagonalMiddleWhiteGlow.state.timeScale = 0.25;
        this.pentagonalMiddleWhiteGlow.tint = 0xE92BEC;
        this.pentagonalMiddleWhiteGlow.alpha = 0.5;
        this.pentagonalMiddleWhiteGlow.state.addListener({
            complete: () => {
                if (this.pentagonalMiddleWhiteGlow) {
                    this.pentagonalMiddleWhiteGlow.state.clearListeners()
                    this.pentagonalMiddleWhiteGlow.state.tracks = [];
                    this.pentagonalMiddleWhiteGlow.visible = false;
                    this.pentagonalBackGlow.visible = true;
                    this.pentagonalBackGlow.state.setAnimation(0, 'bg_glow_loop', false);
                    this.pentagonalBackGlow.state.addListener({
                        complete: () => {
                            if (this.pentagonalBackGlow) {
                                this.pentagonalBackGlow.state.clearListeners()
                                this.pentagonalBackGlow.state.tracks = [];
                                this.pentagonalBackGlow.visible = false;
                            }
                        }
                    })
                }
            }
        })
    }

    // private playAnimation(): void {
    //     if (this.state !== CommonConfig.the.getCurrentState()) {
    //         return;
    //     }
    //     this.pentagonalSpine.visible = true;
    //     this.pentagonalLoopWhiteGlow.visible = true;
    //     this.starAnimation.visible = true;
    //     const animationName = "pentagon_progress";
    //     const trackEntry = this.pentagonalSpine.state.setAnimation(0, animationName, false);
    //     trackEntry.timeScale = 0.5;
    //     let animationDuration = trackEntry.animation!.duration;
    //     animationDuration = Number(animationDuration.toFixed(2));
    //     trackEntry.trackTime = animationDuration * (this.lastPersentage / 100);
    //     this.pentagonalSpine.state.addListener({
    //         event: (entry, event) => {
    //             if (event.floatValue === this.currentpercemtage) {
    //                 entry.timeScale = 0;
    //                 this.lastPersentage = this.currentpercemtage;
    //                 this.lastPersentage = Number(this.lastPersentage.toFixed(0));
    //                 this.pentagonalSpine.state.clearListeners();
    //             }
    //         },
    //     })
    // }

    private playAnimation(): void {
        if (this.state !== CommonConfig.the.getCurrentState()) {
            return;
        }
    
        this.pentagonalSpine.visible = true;
        this.pentagonalLoopWhiteGlow.visible = true;
        this.starAnimation.visible = true;
    
        const animationName = "pentagon_progress";
        const trackEntry = this.pentagonalSpine.state.setAnimation(0, animationName, false);
    
        if (!trackEntry || !trackEntry.animation) {
            console.error("Animation not found or invalid.");
            return;
        }
    
        trackEntry.timeScale = 0.5;
        let animationDuration = trackEntry.animation.duration;
        animationDuration = Number(animationDuration.toFixed(2));
    
        trackEntry.trackTime = animationDuration * (this.lastPersentage / 100);
    
        const eventListener = (entry: any, event: any) => {
            if (Math.round(event.floatValue) === Math.round(this.currentpercemtage)) {
                entry.timeScale = 0;
                this.lastPersentage = Math.round(this.currentpercemtage);
                this.pentagonalSpine.state.clearListeners(); // Remove only this listener
            }
        };
    
        this.pentagonalSpine.state.addListener({ event: eventListener });
    }
    

    private loopAlphaAnimation(): void {
        gsap.to(this.pentagonalSpine, {
            duration: 0.5,
            alpha: 0.85,
            ease: "power4.inOut",
            onComplete: () => {
                gsap.to(this.pentagonalSpine, {
                    duration: 0.5,
                    alpha: 1,
                    ease: "power4.inOut",
                    onComplete: () => {
                        this.loopAlphaAnimation();
                    }
                })
            }
        })
    }

    private reverseAnimation(): void {
        let lastPersentage = Number(this.lastPersentage.toFixed(0));
        this.lastPersentage = 0;
        if (this.state !== CommonConfig.the.getCurrentState()) {
            return;
        }
        // lastPersentage = 60;
        lastPersentage = 100 - lastPersentage;

        const animationState = this.pentagonalSpine.state;
        const animationName = "pentagon_progress_reverse";
        // this.pentagonalSpine.state.setAnimation(0, animationName, false);
        const trackEntry = this.pentagonalSpine.state.setAnimation(0, animationName, false);
        const animationDuration = trackEntry.animation!.duration;
        trackEntry.trackTime = Number((animationDuration * (lastPersentage / 100)).toFixed(2));
        // const reverseTime = 1;
        trackEntry.timeScale = 0.5; // Ensures it finishes in 0.3s
    }

    private initRandomFeatureContainer(): void {
        this.randomFeatureIndicatorContainer = new Container();
        let indicator_Texture: Spritesheet = Assets.get("feature_popup");
        this.addChild(this.randomFeatureIndicatorContainer);

        let randomFeatureIndiator5Container = new Container();
        let randomFeatureIndiator5: Sprite = new Sprite(indicator_Texture.textures['pentagone_partialglow.png']);
        this.randomFeatureIndicatorContainer.addChild(randomFeatureIndiator5Container);
        randomFeatureIndiator5Container.addChild(randomFeatureIndiator5);
        randomFeatureIndiator5.anchor.set(0.5, 0.5);
        randomFeatureIndiator5.angle = 0;
        let randomFeatureIndiator5Animation : Spine = Spine.from({skeleton : "pentagon_spine_assets",atlas :"pentagon_spine_atlas"});
        randomFeatureIndiator5Animation.state.setAnimation(0,"red_light_loop",true);
        randomFeatureIndiator5Animation.scale.set(0.3);
        randomFeatureIndiator5Animation.angle = 0;
        randomFeatureIndiator5Animation.position.set(0,103.5);
        randomFeatureIndiator5Container.addChild(randomFeatureIndiator5Animation);
       
        randomFeatureIndiator5Container.position.set(168, 42);

        let randomFeatureIndiator1Container = new Container();
        let randomFeatureIndiator1: Sprite = new Sprite(indicator_Texture.textures['pentagone_partialglow.png']);
        this.randomFeatureIndicatorContainer.addChild(randomFeatureIndiator1Container);
        randomFeatureIndiator1.anchor.set(0.5, 0.5);
        randomFeatureIndiator1.angle = 70.5;
        randomFeatureIndiator1Container.position.set(285, 119.5);
        randomFeatureIndiator1Container.addChild(randomFeatureIndiator1);
        let randomFeatureIndiator1Animation : Spine = Spine.from({skeleton : "pentagon_spine_assets",atlas :"pentagon_spine_atlas"});
        randomFeatureIndiator1Animation.state.setAnimation(0,"red_light_loop",true);
        randomFeatureIndiator1Animation.scale.set(0.3);
        randomFeatureIndiator1Animation.angle = 0;
        randomFeatureIndiator1Animation.position.set(0,108.5);
        randomFeatureIndiator1Container.addChild(randomFeatureIndiator1Animation);

        let randomFeatureIndiator2Container = new Container(); 
        let randomFeatureIndiator2: Sprite = new Sprite(indicator_Texture.textures['pentagone_partialglow.png']);
        this.randomFeatureIndicatorContainer.addChild(randomFeatureIndiator2Container);
        randomFeatureIndiator2.anchor.set(0.5, 0.5);
        randomFeatureIndiator2.angle = 152.5;
        randomFeatureIndiator2Container.position.set(240.5, 259);
        randomFeatureIndiator2Container.addChild(randomFeatureIndiator2);
        let randomFeatureIndiator2Animation : Spine = Spine.from({skeleton : "pentagon_spine_assets",atlas :"pentagon_spine_atlas"});
        randomFeatureIndiator2Animation.state.setAnimation(0,"red_light_loop",true);
        randomFeatureIndiator2Animation.scale.set(0.3);
        randomFeatureIndiator2Animation.angle = 152.5;
        randomFeatureIndiator2Animation.position.set(-51,-93);
        randomFeatureIndiator2Container.addChild(randomFeatureIndiator2Animation);

        let randomFeatureIndiator3Container = new Container();
        let randomFeatureIndiator3: Sprite = new Sprite(indicator_Texture.textures['pentagone_partialglow.png']);
        this.randomFeatureIndicatorContainer.addChild(randomFeatureIndiator3Container);
        randomFeatureIndiator3.anchor.set(0.5, 0.5);
        randomFeatureIndiator3.angle = 206.5;
        randomFeatureIndiator3Container.position.set(100, 260);
        randomFeatureIndiator3Container.addChild(randomFeatureIndiator3);
        let randomFeatureIndiator3Animation : Spine = Spine.from({skeleton : "pentagon_spine_assets",atlas :"pentagon_spine_atlas"});
        randomFeatureIndiator3Animation.state.setAnimation(0,"red_light_loop",true);
        randomFeatureIndiator3Animation.scale.set(0.3);
        randomFeatureIndiator3Animation.angle = 206.5;
        randomFeatureIndiator3Animation.position.set(45,-95);
        randomFeatureIndiator3Container.addChild(randomFeatureIndiator3Animation);

        let randomFeatureIndiator4Container = new Container();
        let randomFeatureIndiator4: Sprite = new Sprite(indicator_Texture.textures['pentagone_partialglow.png']);
        this.randomFeatureIndicatorContainer.addChild(randomFeatureIndiator4Container);
        randomFeatureIndiator4.anchor.set(0.5, 0.5);
        randomFeatureIndiator4.angle = 286;
        randomFeatureIndiator4Container.position.set(51, 127);
        randomFeatureIndiator4Container.addChild(randomFeatureIndiator4);
        let randomFeatureIndiator4Animation : Spine = Spine.from({skeleton : "pentagon_spine_assets",atlas :"pentagon_spine_atlas"});
        randomFeatureIndiator4Animation.state.setAnimation(0,"red_light_loop",true);
        randomFeatureIndiator4Animation.scale.set(0.3);
        randomFeatureIndiator4Animation.angle = 286;
        randomFeatureIndiator4Animation.position.set(99.5,28);
        randomFeatureIndiator4Container.addChild(randomFeatureIndiator4Animation);
    }

    private createMaskImages(currentPercent: number): void {
        const myObject = { percent: this.currentpercemtage };
        if (currentPercent <= 0) {
            this.maskGraphics.clear();
            return;
        }
        gsap.to(myObject, {
            percent: currentPercent,
            duration: 1,
            onUpdate: () => {
                this.updateMask(myObject.percent);
            },
            onComplete: () => {
                this.currentpercemtage = currentPercent;
            },
            ease: "power1.inOut", // Smooth animation easing
        });
    }

    private showRandomFeatureIndicator(newIndicator: Sprite, isShow: boolean): void {
        const finalAlpha = isShow ? 1 : 0
        gsap.to(newIndicator, {
            ease: 'power1.out',
            duration: 0.5,
            alpha: finalAlpha
        }
        )
    }

    updateMask(percentage: number) {
        const radius = this.pentagon_center_frame.width; // Adjust radius based on pentagon size
        const angle = (percentage / 100) * Math.PI * 2; // Convert percentage to radians
        this.maskGraphics.clear();
        this.maskGraphics.beginFill(0xffffff);
        this.maskGraphics.moveTo(0, 0);
        this.maskGraphics.arc(0, 0, radius, -Math.PI / 2, angle - Math.PI / 2); // Arc grows based on percentage
        this.maskGraphics.lineTo(0, 0); // Close the shape
        this.maskGraphics.endFill();
    }

    private returnRandomFeature(index: number): string {
        // return CommonConfig.RANDOM_FEATURES_LIST[0];
        return CommonConfig.RANDOM_FEATURES_LIST[index];
    }


    private addToStage(): void {
        this.addChild(this.pentagonalBackContainer);
        this.addChild(this.filledPentagonalContainer);
        this.addChild(this.starAnimation);
        this.addChild(this.pentagonalTopContainer);
        this.addChild(this.maskContainer);
        this.addChild(this.counterMeter);
    }



    private updatePentagonalMeter(): void {
        if (this.state !== CommonConfig.the.getCurrentState()) {
            return;
        }
        if (CommonConfig.the.getIsRandomFeatureState() || CommonConfig.the.getIsFGRandomFeatureState() || CommonConfig.the.get3x3WildGridIds().length) {
            return;
        }
        this.pushRandomFeature();
        this.currentpercemtage = this.calculateCurrentFilledPercentage();
        this.currentpercemtage = Number(this.currentpercemtage.toFixed(0));
        this.playAnimation();
        this.pentagonalColorChangeContainer.visible = true;
        // this.createMaskImages(this.currentpercemtage);
        this.counterMeter.updatePentagonalCount(CommonConfig.the.getTotalWinSymbolCount());
        if (CommonConfig.the.getTotalWinSymbolCount() >= 50 && !CommonConfig.the.getIsRandomFeatureState()) {
            CommonConfig.the.setIsBonusRewarded(true);
        }
        this.counterMeter.position.set(this.pentagon_center_frame.x + (this.pentagon_center_frame.width - this.counterMeter.width) / 2, this.pentagon_center_frame.y + (this.pentagon_center_frame.height - this.counterMeter.height) / 2);
    }

    private pushRandomFeature(): void {
        if (CommonConfig.the.getCurrentState() === CommonConfig.BASE_GAME) {
            this.pushRandomFeatureBaseGame();
        } else {
            this.pushRandomFeatureFreeGame();
        }

    }

    private pushRandomFeatureFreeGame(): void {
        // let currentTotalRandomFeature: number = Math.floor(CommonConfig.the.getTotalWinSymbolCount() / 10);
        // const newTotaleRandomFeature: number = currentTotalRandomFeature - CommonConfig.the.getCurrentFGRadomFeatureList().length;
        // if (newTotaleRandomFeature <= 0) {
        //     return;
        // }
        // for (let i: number = 0; i < newTotaleRandomFeature; i++) {
        //     CommonConfig.the.getCurrentFGRadomFeatureList().push(this.returnRandomFeature());
        // }

        // for (let i: number = 0; i < CommonConfig.the.getCurrentFGRadomFeatureList().length; i++) {
        //     if(i === 4){
        //         this.showRandomFeatureIndicator(this.randomFeatureIndicatorContainer.children[0] as Sprite, true);
        //     }else{
        //         this.showRandomFeatureIndicator(this.randomFeatureIndicatorContainer.children[i + 1] as Sprite, true);
        //     }
        // }
    }

    private pushRandomFeatureBaseGame(): void {
        let currentTotalRandomFeature: number = Math.floor(CommonConfig.the.getTotalWinSymbolCount() / 10);
        currentTotalRandomFeature = currentTotalRandomFeature > 5 ? 5 : currentTotalRandomFeature;
        let newTotaleRandomFeature: number = currentTotalRandomFeature - CommonConfig.the.getCurrentRadomFeatureList().length;
        newTotaleRandomFeature = newTotaleRandomFeature > 5 ? 5 : newTotaleRandomFeature;
        if (newTotaleRandomFeature <= 0) {
            return;
        }
        for (let i: number = 0; i < newTotaleRandomFeature; i++) {
            if (CommonConfig.the.getCurrentRadomFeatureList().length < 4) {
                CommonConfig.the.getCurrentRadomFeatureList().push(this.returnRandomFeature(CommonConfig.the.getCurrentRadomFeatureList().length));
            }

            // CommonConfig.the.getCurrentRadomFeatureList().push(CommonConfig.RANDOM_FEATURE_ZWOOM);
        }

        for (let i: number = 0; i < currentTotalRandomFeature; i++) {
            if (i === 4) {
                this.showRandomFeatureIndicator(this.randomFeatureIndicatorContainer.children[0] as Sprite, true);
                this.pentagonalLoopWhiteGlow.visible = false;
                this.playCompleteAnimation();
            } else {
                this.showRandomFeatureIndicator(this.randomFeatureIndicatorContainer.children[i + 1] as Sprite, true);
            }
        }
    }

    private calculateCurrentFilledPercentage(): number {
        let currentCount: number = CommonConfig.the.getTotalWinSymbolCount();
        let totalWinSymbolCount: number = 50;
        return (currentCount / totalWinSymbolCount) * 100;
    }

    private getFilledTextureIndex(input: number) {
        let value: number = input / 10
        return Math.ceil(value);
    }

}