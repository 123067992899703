import { Spine } from "@esotericsoftware/spine-pixi-v8";
import { Assets, Container, Graphics, Sprite, Spritesheet, Text, TextStyle, Texture } from "pixi.js";
import gsap from "gsap";
import { CommonConfig } from "@/Common/CommonConfig";
import { Game } from "../game";
import { LargeWinTitleContainer } from "./LargeWin/LargeWinTitleContainer";
import SoundManager from "../Sound/SoundManager";
import * as particles from '@barvynkoa/particle-emitter';


enum LARGE_WIN_SEQ {
    BIG_WIN = 1,
    MEGA_WIN = 2,
    TOP_WIN = 3,
    OVER_TOP_WIN = 4
}

enum LARGE_WIN_TEXT {
    WIN = 'win',
    BIG_WIN = 'bigwin',
    MEGA_WIN = 'megawin',
    TOP_WIN = 'topwin',
    OVER_TOP_WIN = 'overthetop'
}

export interface winCap {
    minCap: number,
    maxCap: number,
}

enum winThreeShold {
    win = 25,
    bigwin = 50,
    megawin = 100,
    topwin = 250,
    overthetop = 1000
}

export interface IWinThreeShold {
    win: number,
    bigwin: number,
    megawin: number,
    topwin: number,
    overthetop: number
}

enum winTiming {
    win = 7,
    bigwin = 7,
    megawin = 8,
    topwin = 14,
    overthetop = 14
}


export class BigWinMeter extends Container {

    private darkBg !: Graphics;
    private bigWinSpine !: Spine;
    private megaWinSpine !: Spine;
    private topWinSpine !: Spine;
    private overTheTopWinSpine !: Spine;
    private soundManager !: SoundManager;
    private winMeterGsapTween !: gsap.core.Tween;
    private winMeterScaleTween !: gsap.core.Tween;
    private currentvalue: number = 0;
    private lastTargetValue: number = 0;
    private winThreeShold: number[] = [25, 50, 100, 250, 1000];
    private winDurations: number[] = [7, 7, 8, 14, 14];
    private currentBet: number = 1;
    private currentTitle: string = "win";
    private winString: string[] = ["win", "bigwin", "megawin", "topwin", "overthetop"];
    private winMeterTextScale: number = 1;
    private state: string;
    private isHidden: boolean = false;
    private bigWinBg !: Spine;
    private largeWinTitleContainer !: LargeWinTitleContainer;
    private totalStates: string[] = [];
    private currentStateIndex: number = 0;
    private currentTotalWin: number = 0;
    private bigWinFontTexture !: Spritesheet;
    private newWinMeter !: Container;
    private newWinBox !: Container;
    private isHide : boolean = false;
    private emitterContainer !: Container;
    private emitter !: particles.Emitter;
    private coinShowerConfig : any;

    constructor(state: string) {
        super();
        this.state = state;        
        if(this.state === CommonConfig.FREE_Game){
           this.initAfterPostLoad();
        }
        Game.the.app.stage.on(CommonConfig.INIT_AFTER_POST_ASSETS_LOAD, this.initAfterPostLoad, this);
    }

    private initAfterPostLoad() :void{
        this.init();
        this.soundManager = SoundManager.getInstance();
        this.bigWinFontTexture = Assets.get("bigWin_font");
        this.newWinBox = new Container();
        this.newWinMeter = new Container();
        const graphics : Graphics = new Graphics().rect(0, 0, 523, 135.5).fill("#000000");
        graphics.alpha = 0;
        this.newWinBox.addChild(graphics);
        this.emitterContainer = new Container();
        this.particleAnimation();
        this.addToStage();
        this.setToPosition();
        if (this.state === CommonConfig.BASE_GAME) {
            this.subscribeEvent();
        } else {
            this.subscribeEventFreeGame();
        }
        // CommonConfig.the.setCurrentWinAmount(50);
        // this.show();
    }

    private subscribeEvent(): void {
        Game.the.app.stage.on("RESIZE_THE_APP", this.setToPosition, this);
        Game.the.app.stage.on(CommonConfig.PLAY_BIG_WIN, this.show, this);
        this.on('pointerup', this.stageClick, this);
    }

    private subscribeEventFreeGame(): void {
        Game.the.app.stage.on(CommonConfig.PLAY_FG_BIG_WIN, this.show, this);
        Game.the.app.stage.on("RESIZE_THE_APP", this.setToPosition, this);
        this.on('pointerup', this.stageClick, this);
    }

    private unsubscrbeEvents(): void {
        Game.the.app.stage.off(CommonConfig.PLAY_FG_BIG_WIN, this.show, this);
        Game.the.app.stage.off("RESIZE_THE_APP", this.setToPosition, this);
        Game.the.app.stage.off(CommonConfig.PLAY_BIG_WIN, this.show, this);
        this.off('pointerup', this.stageClick, this);
    }

    private init() {
        this.darkBg = new Graphics().rect(0, 0, 5000, 4000).fill("#000000");
        this.darkBg.alpha = 0.65;
        this.darkBg.position.set(-244.5,-195);
        this.bigWinSpine = Spine.from({ skeleton: "Bigwin_data", atlas: "Bigwin_atlas" });
        this.megaWinSpine = Spine.from({ skeleton: "MegaWin_data", atlas: "MegaWin_atlas" });
        this.topWinSpine = Spine.from({ skeleton: "Top_Win_data", atlas: "Top_Win_atlas" });
        this.overTheTopWinSpine = Spine.from({ skeleton: "OverTheTopWin_data", atlas: "OverTheTopWin_atlas" });
        this.bigWinBg = Spine.from({ skeleton: "LargeWinBg_data", atlas: "LargeWinBg_atlas" });
        this.bigWinBg.scale.set(0.6);
        this.bigWinSpine.scale.set(0.6);
        this.megaWinSpine.scale.set(0.6);
        this.topWinSpine.scale.set(0.8);
        this.overTheTopWinSpine.scale.set(0.6);
        this.largeWinTitleContainer = new LargeWinTitleContainer(this.state, this.currentTitle);
        this.alpha = 0;
        this.hideIntroAnimation();
    }

    private addToStage(): void {
        this.addChild(this.darkBg);
        this.addChild(this.bigWinBg);
        this.addChild(this.emitterContainer);
        this.addChild(this.bigWinSpine);
        this.addChild(this.topWinSpine);
        this.addChild(this.largeWinTitleContainer);
        this.addChild(this.megaWinSpine);
        this.addChild(this.overTheTopWinSpine);
        this.largeWinTitleContainer.addChild(this.newWinBox);
        this.newWinBox.addChild(this.newWinMeter);
    }

    private particleAnimation(): void {
        if (this.state !== CommonConfig.the.getCurrentState()) {
            return;
        }
        const spritesheet :Spritesheet = Assets.get('coins');
        let coins : Texture[] = [];
        for(let i : number = 0;i<24;i++){
            const coin : Texture= spritesheet.textures[`${i+1}.png`];
            coins.push(coin);
        }

        this.coinShowerConfig = {
            lifetime: { min:5, max: 5 }, // Coins last for 2-3 seconds
            frequency: 0.119, // New coin every 0.05s
            emitterLifetime: -1, // Continuous emission
            maxParticles: 1000, // Maximum number of coins
            pos: { x: 0, y: 0 }, // Start from top center
            blendMode: "normal",
            particlesPerWave:3,
            // noRotation: false,
            addAtBack: true,
            maxSpeed: 0,
            behaviors: [
                {
                    type: "alpha",
                    config: {
                        alpha: {
                            list: [
                                {
                                    time: 0,
                                    value: 1
                                },
                                {
                                    time: 1,
                                    value: 0.98
                                }
                            ]
                        }
                    }
                },
                {
                    type: "scale",
                    config: {
                        scale: { list: [{ value: 0.35, time: 0 }, { value: 1, time: 1 }] },
                        minimumScaleMultiplier: 2
                    },
                },
                {
                    type: "moveSpeed",
                    config: {
                        speed: { list: [{ value: 480, time: 0 },{ value: 300, time: 0.4 }, { value: 90, time: 1 }] },
                        minimumScaleMultiplier: 2
                    },
                }, 
                {
                    type: "moveAcceleration",
                    config: {
                        accel: {
                            x: 100,
                            y: 300
                        },
                        minStart: -200,
                        maxStart: -100,
                        rotate: true

                    }
                },                  
                {
                    type: "rotation",
                    config: { min: -360, max: 360 }, // Rotate while falling
                },
                {
                    type: "rotationStatic",
                    config: { min: -720, max: 720 }, // Rotate while falling
                },
                // {
                //     type: "spawnBurst",
                //     config: {
                //         particlesPerWave: 10,
                //         interval: 0.5,
                //         count: 5
                //     }
                // },
                {
                    type: "spawnShape",
                    config: {
                        type: "rect",
                        data: {
                            x: -50,
                            y: -20,
                            w: 400,
                            h: 50
                        }
                    }
                },
                {
                    type: "animatedRandom",
                    config: {
                        anims: [
                            {
                                framerate: 30,
                                loop: true,
                                textures: coins
                            },
                        ]
                    }
                },
            ],
        };
    }

    private playEmitter() :void{
        if (this.state !== CommonConfig.the.getCurrentState()) {
            return;
        }
        this.initEmitter();
        this.emitter?.cleanup();
        this.emitterContainer.angle = -28.5;
        this.emitterContainer.position.set(906.5,283);
        this.soundManager.play('Coin_Sfx');
        this.emitter.emit = true;
        let elapsed = performance.now();
        const update = () => {
            requestAnimationFrame(update);
            let now = performance.now();
            this.emitter.update((now - elapsed) / 1000); // Convert to seconds
            elapsed = now;
        };
        update();
    }

    private initEmitter(): void {
        if (!this.emitter) {
            this.emitter = new particles.Emitter(
                this.emitterContainer,
                this.coinShowerConfig
            );
        }
    }

    private killEmitter() :void{
        if (this.state !== CommonConfig.the.getCurrentState()) {
            return;
        }
        if (this.emitter) {
            this.emitter.emit = false; // ✅ Stop the emitter but keep it reusable
            this.emitter.cleanup(); // Clear particles
        }
        this.soundManager.stop('Coin_Sfx')
        // this.emitterContainer.removeChildren(0,this.emitterContainer.children.length);
    }

    private playAnimation(): void {
        // this.playBigWinAnimation()
        this.bigWinBg.state.setAnimation(0, 'Bg_Fx', true);
    }

    
    private setNumber(value: string) {
        this.newWinMeter.removeChildren(0,this.newWinMeter.children.length)
        const digits = value.split("");
        let xOffset = 0;
        digits.unshift('euro');
        digits.forEach((digit) => {
            if(digit === "."){
                digit = 'dot'
            }
            const digitSprite = new Sprite(this.bigWinFontTexture.textures[`${digit}.png`]);
            digitSprite.x = xOffset;
            digit === "dot" && (digitSprite.y = 30);
            this.addChild(digitSprite);
            this.newWinMeter.addChild(digitSprite);
            xOffset += digitSprite.width * 0.7; // Adjust spacing
        });   
    }

    private killDelay() :void{
        gsap.killTweensOf(this.playBigWinAnimation);
        gsap.killTweensOf(this.playMegaWinAnimation);
        gsap.killTweensOf(this.playTopWinAnimation);
        gsap.killTweensOf(this.playOverTheTopWinAnimation);

    }

    resizeTextToFit() {
        let fontSize = this.newWinMeter.scale.x;
        while (this.newWinMeter.width > 500) {  // Stop at a minimum size
            fontSize -= 0.1;
            fontSize = Number(fontSize.toFixed(1));
            this.newWinMeter.scale.set(fontSize);
        }
        this.newWinMeter.position.set((this.newWinBox.width - this.newWinMeter.width) * 0.45,(this.newWinBox.height - this.newWinMeter.height) * 0.5)
    }

    private playBigWinAnimation(): void {
        this.soundManager.play('Big_Win_Sfx');
        gsap.delayedCall(0.66, () => {this.bigWinSpine.visible = true});
        gsap.delayedCall(0.66, () => this.playTitleContainerDownAnimation(507.4));
        gsap.delayedCall(3.66, () => this.playTitleContainerUpAnimation(327.4));
        this.bigWinSpine.state.setAnimation(0, 'Monitor_smash', false);
        this.bigWinSpine.state.addListener({
            complete: () => {
                this.bigWinSpine.visible = false;
                this.soundManager.stop('Big_Win_Sfx');
                // this.playTitleContainerUpAnimation();
                this.bigWinSpine.state.clearListeners()
                this.bigWinSpine.state.tracks = []
            },
        });
    }

    private playMegaWinAnimation(): void {
        this.soundManager.play('Mega_Win_Sfx');
        gsap.delayedCall(0.66, () => {this.megaWinSpine.visible = true});
        gsap.delayedCall(0.2, () => this.playTitleContainerDownAnimation(536.9));
        gsap.delayedCall(3.66, () => this.playTitleContainerUpAnimation(286.9));
        this.megaWinSpine.state.setAnimation(0, 'motorbike', false);
        this.megaWinSpine.state.addListener({
            complete: () => {
                this.megaWinSpine.visible = false;
                this.soundManager.stop('Mega_Win_Sfx');
                // this.playTitleContainerUpAnimation();
                this.megaWinSpine.state.clearListeners()
                this.megaWinSpine.state.tracks = []
            },
        });
    }

    private playTopWinAnimation(): void {
        this.soundManager.play('Top_win_Sfx');
        gsap.delayedCall(0.66, () => {this.topWinSpine.visible = true});
        this.topWinSpine.state.setAnimation(0, 'animation', false);
        gsap.delayedCall(0.66, () => this.playTitleContainerDownAnimation(429.4));
        gsap.delayedCall(5, () => this.playTitleContainerUpAnimation(279.4));
        gsap.delayedCall(7.33, () => this.playTitleContainerDownAnimation(429.4));
        gsap.delayedCall(12, () => this.playTitleContainerUpAnimation(279.4));
        this.topWinSpine.state.addListener({
            complete: () => {
                this.topWinSpine.state.clearListeners()
                this.topWinSpine.state.tracks = [];
                gsap.delayedCall(0.76, () => {
                    this.topWinSpine.state.setAnimation(0, 'animation', false);
                    this.topWinSpine.state.addListener({
                        complete: () => {
                            this.topWinSpine.visible = false;
                            this.soundManager.stop('Top_win_Sfx');
                            // this.playTitleContainerUpAnimation();
                            this.topWinSpine.state.clearListeners()
                            this.topWinSpine.state.tracks = []
                        },
                    });
                })
            },
        });
    }

    private playOverTheTopWinAnimation(): void {
        this.soundManager.play('Over_The_Top_win_Sfx');
        gsap.delayedCall(0.66, () => {this.overTheTopWinSpine.visible = true});
        this.overTheTopWinSpine.state.setAnimation(0, 'warplane', false);
        gsap.delayedCall(0.66, () => this.playTitleContainerDownAnimation(577.4));
        gsap.delayedCall(4.5, () => this.playTitleContainerUpAnimation(327.4));
        gsap.delayedCall(7.33, () => this.playTitleContainerDownAnimation(577.4));
        gsap.delayedCall(11, () => this.playTitleContainerUpAnimation(327.4));
        this.overTheTopWinSpine.state.addListener({
            complete: () => {
                this.soundManager.stop('Over_The_Top_win_Sfx');
                this.overTheTopWinSpine.visible = false;
                // this.playTitleContainerUpAnimation();
                this.overTheTopWinSpine.state.clearListeners()
                this.overTheTopWinSpine.state.tracks = []
            },
        });
    }

    private setToPosition(): void {
        this.bigWinBg.position.set(973.912, 384.12);
        this.updateTitleContainerPosition();
    }

    private resizeBigWinWithReels(): void {
        if(this.isHide){
            return;
        }
        if(this.currentStateIndex === 0){
            this.newWinBox.position.set(526, 553);
        }else if(this.currentStateIndex === 1){
            this.newWinBox.position.set(596, 540.5);
        }else if(this.currentStateIndex === 2){
            this.newWinBox.position.set(590, 584.5);
        }else if(this.currentStateIndex === 3){
            this.newWinBox.position.set(637, 573.5);
        }else if(this.currentStateIndex === 4){
            this.newWinBox.position.set(767.5, 567);
        }
    }

    private updateTitleContainerPosition(): void {
        if(this.currentStateIndex === 0){
            this.largeWinTitleContainer.position.set(840, 315.4);
        }else if(this.currentStateIndex === 1){
            this.largeWinTitleContainer.position.set(797, 327.4);
        }else if(this.currentStateIndex === 2){
            this.largeWinTitleContainer.position.set(816, 286.9);
        }else if(this.currentStateIndex === 3){
            this.largeWinTitleContainer.position.set(788.5, 279.4);
        }else if(this.currentStateIndex === 4){
            this.largeWinTitleContainer.position.set(738.5, 327.4);
        }
        this.bigWinSpine.position.set(962, 429);
        this.megaWinSpine.position.set(962, 429);
        this.topWinSpine.position.set(962, 429);
        this.overTheTopWinSpine.position.set(962, 429);
    }

    playIncrementAnimation(targetValue: number, duration: number) {
        this.updateTitleContainerPosition();
        this.lastTargetValue = targetValue;

        this.winMeterGsapTween = gsap.to(this, {
            ease: 'power1.out',
            currentvalue: targetValue,
            duration: duration,
            onUpdate: () => {
                this.currentvalue = Number(this.currentvalue.toFixed(2));
                this.setWinMetertext(this.currentvalue.toFixed(2));
                // this.checkStopTween(); 
            },
            onComplete: () => {
                this.currentStateIndex += 1;
                if (this.currentStateIndex >= this.totalStates.length) {
                    this.interactive = false;
                    this.isHide = true;
                    const winAmt = this.state === CommonConfig.FREE_Game ? CommonConfig.the.getCurrentWinAmountInFreeGame():CommonConfig.the.getCurrentWinAmount();
                    this.setWinMetertext(winAmt.toFixed(2));
                    this.scaleTween();
                    this.winMeterGsapTween.kill();
                    gsap.delayedCall(2, () => this.hide());
                } else {
                    if(this.currentStateIndex < 5){
                        this.playTitleContainerScaleDownAnimation(this.transitionAnim.bind(this));
                    }
                }
            }
        });
        this.winMeterGsapTween.play();
    }

    private setWinMetertext(text : string) :void{
        this.setNumber(text);
        this.newWinMeter.scale.set(1.8);
        this.newWinBox.pivot.set(this.newWinBox.width/2,this.newWinBox.height/2);
        this.resizeTextToFit();
        this.resizeBigWinWithReels();
    }

    private transitionAnim(): void {
        this.updateWinSequence();
        const targetValueNew = this.returnTargetValue();
        const winDurations: number = this.winDurations[this.currentStateIndex];
        this.playIncrementAnimation(targetValueNew, winDurations);
    }

    private returnTargetValue() : number{
        const targetValueNew: number = this.currentStateIndex >= this.totalStates.length - 1 ?
            (this.currentTotalWin - (this.winThreeShold[this.currentStateIndex] * this.currentBet)) + this.lastTargetValue
            : (this.winThreeShold[this.currentStateIndex + 1] - this.winThreeShold[this.currentStateIndex]) * this.currentBet + this.lastTargetValue;
        return targetValueNew;
    }


    private playTitleContainerDownAnimation(downPosition : number) {
        gsap.to(this.largeWinTitleContainer, {
            duration: 0.2,
            delay: 0,
            x: this.largeWinTitleContainer.x,
            y: downPosition,
            ease: "power4.in"
        })
    }

    private playTitleContainerScaleUpAnimation(callback: any) {
        callback();
        // this.largeWinTitleContainer.scale.set(0);
        // gsap.to(this.largeWinTitleContainer.scale, {
        //     duration: 0.5,
        //     x: 0.55,
        //     y: 0.55,
        //     ease: "power4.in",
        //     onComplete : () =>{
        //         gsap.to(this.largeWinTitleContainer.scale, {
        //             duration: 0.2,
        //             x: 0.5,
        //             y: 0.5,
        //             ease: "power4.in", 
        //             onComplete : ()=>{
        //                 callback();
        //             }
        //         })
        //     }
        // })
    }

    private playTitleContainerScaleDownAnimation(callback: any) {
        callback();
        // this.largeWinTitleContainer.scale.set(0.5);
        // gsap.to(this.largeWinTitleContainer.scale, {
        //     duration: 0.5,
        //     x: 0.55,
        //     y: 0.55,
        //     ease: "power4.in",
        //     onComplete : () =>{
        //         gsap.to(this.largeWinTitleContainer.scale, {
        //             duration: 0.2,
        //             x: 0,
        //             y: 0,
        //             ease: "power4.in", 
        //             onComplete : ()=>{
        //                 callback();
        //             }
        //         })
        //     }
        // })
    }

    private playTitleContainerUpAnimation(upPosition :number) {
        gsap.to(this.largeWinTitleContainer, {
            duration: 0.1,
            x: this.largeWinTitleContainer.x,
            y: upPosition,
            ease: "power4.in"
        })
    }

    private checkStopTween(): void {
        const winAmt = this.state === CommonConfig.FREE_Game ? CommonConfig.the.getCurrentWinAmountInFreeGame():CommonConfig.the.getCurrentWinAmount();
        if (this.currentvalue >= winAmt) {
            this.setWinMetertext(winAmt.toFixed(2));
            this.winMeterGsapTween.kill();
            gsap.delayedCall(0.75, () => this.hide());
        }
    }

    private stageClick(): void {
        if (this.state !== CommonConfig.the.getCurrentState()) {
            return;
        }
        this.hideIntroAnimation();
        this.killDelay();                    
        this.winMeterGsapTween.kill();
        this.currentStateIndex += 1;
        this.updateTitleContainerPosition();
        if (this.currentStateIndex >= this.totalStates.length) {
            this.isHide = true;
            this.interactive = false;
            const winAmt = this.state === CommonConfig.FREE_Game ? CommonConfig.the.getCurrentWinAmountInFreeGame():CommonConfig.the.getCurrentWinAmount();
            this.setWinMetertext(winAmt.toFixed(2));
            this.scaleTween();
            this.winMeterGsapTween.kill();
            gsap.delayedCall(2, () => this.hide());
        } else {
            if(this.currentStateIndex < 5){
                this.currentvalue = this.returnTargetValue();
                this.currentvalue = Number(this.currentvalue.toFixed(2));
                this.setWinMetertext(this.currentvalue.toFixed(2));
                this.playTitleContainerScaleDownAnimation(this.transitionAnim.bind(this));
            }
        }
    }

    private scaleTween(): void {
        let finalScale: number = this.newWinBox.scale.x + 0.2;
        this.winMeterScaleTween = gsap.to(this.newWinBox.scale, {
            ease: 'power1.out',
            repeat: -1,
            yoyo: true,
            x: finalScale,
            y: finalScale
        });
        this.winMeterScaleTween.play()
    }

    private show(): void {
        if(this.state !== CommonConfig.the.getCurrentState()){
            return;
        }
        Game.the.app.stage.emit(CommonConfig.STOP_BG_SOUND);
        Game.the.app.stage.emit(CommonConfig.STOP_FREEGAME_BG_SOUND);
        this.isHide = false;
        this.currentStateIndex = 0;
        this.isHidden = false;
        this.lastTargetValue = 0;
        this.initializeStates();
        this.currentBet = CommonConfig.the.getBet();
        this.currentTitle = this.winString[0];
        this.largeWinTitleContainer.updateTextureState(this.currentTitle);
        this.setWinMetertext('0');
        this.currentvalue = 0;
        this.playAnimation();
        this.currentStateIndex = 0;
        const targetValueNew: number = this.currentStateIndex >= this.totalStates.length - 1 ?
            (this.currentTotalWin - (this.winThreeShold[this.currentStateIndex] * this.currentBet))
            : (this.winThreeShold[this.currentStateIndex + 1]) * this.currentBet;
        const duration: number = this.winDurations[this.currentStateIndex];
        // this.scaleTween();
        this.interactive = false;
        this.soundManager.play('allWinBgSound');
        gsap.to(this, {
            duration: 0.45,
            alpha: 1,
            ease: "power4.in",
            onComplete: () => this.playIncrementAnimation(targetValueNew, duration)
        });
        // this.playEmitter();
    }

    private initializeStates(): void {
        const winAmt = this.state === CommonConfig.FREE_Game ? CommonConfig.the.getCurrentWinAmountInFreeGame():CommonConfig.the.getCurrentWinAmount();
        this.currentTotalWin = Number(winAmt.toFixed(2));
        let winAmount: number = Number(winAmt.toFixed(2));
        this.currentBet = CommonConfig.the.getBet();
        const maxWinSeqId: number = Math.round(winAmount / (this.currentBet));
        let maxSeq: string = this.returnMaxWinSeqId(maxWinSeqId);
        const maxWinIndex: number = this.winString.indexOf(maxSeq);
        this.createStates(maxWinIndex);
    }

    private createStates(maxIndex: number) {
        this.totalStates = [];
        for (let i: number = 0; i <= maxIndex; i++) {
            this.totalStates.push(this.winString[i]);
        }
    }

    private returnMaxWinSeqId(maxWinSeqId: number): string {
        let currentKey: string = "";
        Object.entries(winThreeShold).forEach(([key, value]) => {
            if (maxWinSeqId < Number(value)) {
                return currentKey;
            }
            currentKey = key;
        });
        return currentKey;
    }

    private hide(): void {
        if (this.isHidden) return;
        this.isHidden = true;
        this.winMeterScaleTween!.kill();
        gsap.to(this, {
            duration: 0.45,
            alpha: 0,
            onComplete: () => {
                this.largeWinTitleContainer.updateTextureState(this.winString[0]);
                this.setWinMetertext('0');
                this.hideIntroAnimation();
                this.soundManager.stop('allWinBgSound');
                // this.killEmitter();
                if(this.state === CommonConfig.FREE_Game){
                    Game.the.app.stage.emit(CommonConfig.PLAY_FREEGAME_BG_SOUND);
                }else{
                    Game.the.app.stage.emit(CommonConfig.PLAY_BG_SOUND);
                }
                if (CommonConfig.the.getCurrentState() == CommonConfig.BASE_GAME) {
                    Game.the.app.stage.emit(CommonConfig.ON_SHOW_NEXT_WIN_PRESENTAION);
                } else {
                    Game.the.app.stage.emit(CommonConfig.FG_ON_SHOW_NEXT_WIN_PRESENTAION);
                }
                this.interactive = false;
                this.killTweens();
                this.bigWinSpine.state.clearListeners()
                this.bigWinSpine.state.tracks = [];
            }
        })
    }

    private killTweens(): void {
        gsap.killTweensOf(this);
        gsap.killTweensOf(this.largeWinTitleContainer);
        gsap.killTweensOf(this.newWinBox);
    }


    private WinIntroAnimation(): void {
        if (this.totalStates[this.currentStateIndex] === 'bigwin') {
            this.playBigWinAnimation();
            // this.playTitleContainerScaleUpAnimation(this.playBigWinAnimation)
        } else if (this.totalStates[this.currentStateIndex] === 'megawin') {
            this.playMegaWinAnimation();
            // this.playTitleContainerScaleUpAnimation(this.playMegaWinAnimation);
        } else if (this.totalStates[this.currentStateIndex] === 'topwin') {
            this.playTopWinAnimation();
            // this.playTitleContainerScaleUpAnimation(this.playTopWinAnimation);
        } else if (this.totalStates[this.currentStateIndex] === 'overthetop') {
            this.playOverTheTopWinAnimation();
            // this.playTitleContainerScaleUpAnimation(this.playOverTheTopWinAnimation);
        }
    }

    private hideIntroAnimation(): void {
        this.bigWinSpine.visible = false;
        this.bigWinSpine.state.clearListeners();
        this.bigWinSpine.state.tracks = [];
        this.megaWinSpine.visible = false;
        this.megaWinSpine.state.clearListeners();
        this.megaWinSpine.state.tracks = [];
        this.topWinSpine.visible = false;
        this.topWinSpine.state.clearListeners();
        this.topWinSpine.state.tracks = [];
        this.overTheTopWinSpine.visible = false;
        this.overTheTopWinSpine.state.clearListeners();
        this.overTheTopWinSpine.state.tracks = [];
    }

    private updateWinSequence(): void {
        this.currentTitle = this.totalStates[this.currentStateIndex];
        gsap.to(this.largeWinTitleContainer, {
            duration: 0.1,
            alpha: 0,
            onComplete: () => {
                this.largeWinTitleContainer.updateTextureState(this.totalStates[this.currentStateIndex]);
                this.updateTitleContainerPosition();
                this.WinIntroAnimation();
                gsap.to(this.largeWinTitleContainer, {
                    duration: 0.45,
                    alpha: 1
                })
            }
        })
    }

}