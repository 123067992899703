import { Container } from "pixi.js";
import { SingleShootingFx } from "./SingleShootingFx";
import gsap from "gsap";
import { CommonConfig } from "@/Common/CommonConfig";
import { Game } from "../game";
import SoundManager from "../Sound/SoundManager";

export class ShootingFxAnimation extends Container {
    private leftShootingFxContainer !: Container;
    private rightShootingFxConatiner !: Container;
    private midPoint: number = 0
    private shootingFxPool: SingleShootingFx[] = [];
    private soundManager !: SoundManager;



    constructor(private state: string) {
        super();
        this.leftShootingFxContainer = new Container();
        this.rightShootingFxConatiner = new Container();
        if(this.state === CommonConfig.FREE_Game){
            this.init();
         }
        Game.the.app.stage.on(CommonConfig.INIT_AFTER_POST_ASSETS_LOAD, this.init, this); 
    }

    private init(): void {
        this.soundManager = SoundManager.getInstance();
        this.addChild(this.leftShootingFxContainer);
        this.addChild(this.rightShootingFxConatiner);
        Game.the.app.stage.on(CommonConfig.SPIN_STOPPED, this.resetsOnSpinClick, this);
        Game.the.app.stage.on(CommonConfig.PLAY_SHOOTING_ANIMATION, this.playShootingAnimation, this);
    }

    private resetsOnSpinClick(): void {
        this.leftShootingFxContainer.removeChildren(0,this.leftShootingFxContainer.children.length)
        this.rightShootingFxConatiner.removeChildren(0,this.rightShootingFxConatiner.children.length)
    }

    private getShootingFx(): SingleShootingFx {
        if (this.shootingFxPool.length > 0) {
            return this.shootingFxPool.pop()!;
        }
        return new SingleShootingFx(this.state); // Create a new one if none are available
    }

    private releaseShootingFx(fx: SingleShootingFx) {
        fx.visible = false;
        this.shootingFxPool.push(fx);
    }

    private playShootingAnimation(leftOrRight: string): void {
        this.midPoint = CommonConfig.the.getZwoomWildList().length / 2;
        let starti = 0;
        let endi = 0;
        if (leftOrRight === "left") {
            starti = 0;
            endi = this.midPoint;
        } else {
            starti = this.midPoint;
            endi = CommonConfig.the.getZwoomWildList().length;
        }
        for (let i: number = starti; i < endi; i++) {
            setTimeout(() => {
                let shootingFx = this.getShootingFx();
                shootingFx.visible = true;
                let ij = [CommonConfig.the.getZwoomWildList()[i][0], CommonConfig.the.getZwoomWildList()[i][1]];
                if (window.innerWidth < window.innerHeight) {
                    if (leftOrRight === "left") {
                        this.leftShootingFxContainer.addChild(shootingFx)
                        this.shootTo((CommonConfig.reelWidth * CommonConfig.the.getZwoomWildList()[i][0] + (CommonConfig.reelWidth)/2),
                            (CommonConfig.symbolHeight * CommonConfig.the.getZwoomWildList()[i][1] - (CommonConfig.symbolHeight)/2 + 270), shootingFx, ij);
                    } else {
                        this.rightShootingFxConatiner.addChild(shootingFx);
                        this.shootTo((CommonConfig.reelWidth * (5 - CommonConfig.the.getZwoomWildList()[i][0])- (CommonConfig.reelWidth * 0.5)),
                            ((CommonConfig.symbolHeight * (CommonConfig.the.getZwoomWildList()[i][1])) - (CommonConfig.symbolHeight)/2 + 270), shootingFx, ij);
                    }
                }else{
                    if (leftOrRight === "left") {
                        this.leftShootingFxContainer.addChild(shootingFx);
                        if(CommonConfig.the.getZwoomWildList()[i][1] === 0){
                            this.shootTo((CommonConfig.reelWidth * CommonConfig.the.getZwoomWildList()[i][0] + (CommonConfig.reelWidth)/2),
                            ((CommonConfig.symbolHeight * CommonConfig.the.getZwoomWildList()[i][1]) - (CommonConfig.symbolHeight)/2 + 50), shootingFx, ij);
                        }else{
                            this.shootTo((CommonConfig.reelWidth * CommonConfig.the.getZwoomWildList()[i][0] + (CommonConfig.reelWidth)/2),
                            ((CommonConfig.symbolHeight * CommonConfig.the.getZwoomWildList()[i][1]) - (CommonConfig.symbolHeight)/2), shootingFx, ij);
                        }
                        
                    } else {
                        this.rightShootingFxConatiner.addChild(shootingFx);
                        if(CommonConfig.the.getZwoomWildList()[i][1] === 0){
                            this.shootTo((CommonConfig.reelWidth * (5 - CommonConfig.the.getZwoomWildList()[i][0])- (CommonConfig.reelWidth * 0.5)),
                            ((CommonConfig.symbolHeight * (CommonConfig.the.getZwoomWildList()[i][1])) - (CommonConfig.symbolHeight)/2 ), shootingFx, ij);
                        }else{
                            this.shootTo((CommonConfig.reelWidth * (5 - CommonConfig.the.getZwoomWildList()[i][0])- (CommonConfig.reelWidth * 0.5)),
                            ((CommonConfig.symbolHeight * (CommonConfig.the.getZwoomWildList()[i][1])) - (CommonConfig.symbolHeight)/2 + 50 ), shootingFx, ij);
                        }
                    }
                }
                
            }, 0.1 * i)
        }
    }

    updateLeftRightContainerPosition(scale: number, reelXPos: number, reelWidth: number) {
        this.leftShootingFxContainer.scale.set(scale);
        this.rightShootingFxConatiner.scale.set(-scale, scale);
        this.leftShootingFxContainer.position.set(reelXPos, 200);
        this.rightShootingFxConatiner.position.set(reelXPos + reelWidth, 200);
        if (window.innerWidth < window.innerHeight) {
            this.leftShootingFxContainer.position.set(reelXPos, 226);
            this.rightShootingFxConatiner.position.set(reelXPos + reelWidth, 226);
        }
    }

    private shootTo(x: number, y: number, shootingFx: SingleShootingFx, ij: number[]) {
        // x = x + (CommonConfig.reelWidth/2);
        // y = y + (CommonConfig.symbolHeight/2);
        const angle = Math.atan2(y - shootingFx.y, x - shootingFx.x);
        const angleDeg = angle * 180 / Math.PI;
        shootingFx.angle = angleDeg;
        const shootTweem = gsap.to(shootingFx, {
            x: x, 
            y: y,
            duration: 0.5,
            onComplete : () =>{
                gsap.killTweensOf(this);
                gsap.killTweensOf(shootTweem);
                Game.the.app.stage.emit(CommonConfig.REPLACE_PATICULAR_SYMBOL,ij);
                shootingFx.stopAnimation();
                shootingFx.visible= false;
                shootingFx.position.set(0,0);
                this.releaseShootingFx(shootingFx);
                this.soundManager.play("SymbolReplace");
            }
        });
        shootTweem.play();
    }
}