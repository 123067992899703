import { Assets, Container, Graphics, Sprite, Spritesheet } from "pixi.js";
import { Game } from "../game";
import { CommonConfig } from "@/Common/CommonConfig";
import gsap from "gsap";
import { Spine } from "@esotericsoftware/spine-pixi-v8";
import SoundManager from "../Sound/SoundManager";

export class RandomFeaturePopup extends Container {
    private state: string;
    private bg !: Graphics; 
    private pistole !: Spine;
    private zwoom !: Spine;
    private crapazaone !: Spine;
    private soundManager !: SoundManager;
    featureContainer !: Container;
    constructor(state: string) {
        super();
        this.state = state;
        if(this.state === CommonConfig.FREE_Game){
            this.initAfterPostLoad();
         }
        Game.the.app.stage.on(CommonConfig.INIT_AFTER_POST_ASSETS_LOAD, this.initAfterPostLoad, this); 
    }

    private initAfterPostLoad() : void{
        this.soundManager = SoundManager.getInstance();
        this.featureContainer = new Container();
        this.bg = new Graphics().rect(0,0,4000,4000).fill(0x000000);
        this.bg.alpha = 0.75;             
        this.initAnimations();
        this.addToStage();
        this.visible = false;
        // this.pistole.visible = true;
        if (this.state === CommonConfig.BASE_GAME) {
            this.subscribeEvent();
        } else {
            this.subscribeFGEvent();
        }
        this.setToPosition();
        Game.the.app.stage.on("RESIZE_THE_APP", this.setToPosition, this);
    }

    private setToPosition() :void{
        this.bg.position.set(-220.5,-195);
        if(window.innerHeight>window.innerWidth){
            this.bg.position.set(-182.5,-195);
        }
    }

    private initAnimations() :void{
        this.pistole = Spine.from({ skeleton: "Pistol_Animation_spine_data", atlas: "Pistol_Animation_spine_atlas" });
        this.zwoom = Spine.from({ skeleton: "Zwoom_Animation_spine_data", atlas: "Zwoom_Animation_spine_atlas" });
        this.crapazaone = Spine.from({ skeleton: "creapazione_spine_data", atlas: "creapazione_spine_atlas" });
        this.zwoom.scale.set(1.2);
        this.pistole.scale.set(1.2);
        // this.pistole.pivot.set(this.pistole.width/2, this.pistole.height/2);
        // this.zwoom.pivot.set(this.zwoom.width/2, this.zwoom.height/2);
        this.zwoom.position.set(15,-22.5);
        this.pistole.position.set(15.5,-29.5);
        this.crapazaone.scale.set(1);
        // this.crapazaone.pivot.set(this.crapazaone.width/2, this.crapazaone.height/2);
        this.crapazaone.position.set(0,-50);
    }

    private addToStage(): void {
        this.addChild(this.bg);
        this.addChild(this.featureContainer);
        this.featureContainer.addChild(this.pistole);
        this.featureContainer.addChild(this.zwoom);
        this.featureContainer.addChild(this.crapazaone);
    }

    private subscribeEvent(): void {
        Game.the.app.stage.on(CommonConfig.SHOW_RANDOM_FEATURE_POPUP, this.onStartZwoomFeature, this);
    }

    private subscribeFGEvent(): void {
        Game.the.app.stage.on(CommonConfig.FG_SHOW_RANDOM_FEATURE_POPUP, this.onStartZwoomFeature, this);
    }

    private onStartZwoomFeature(feature: string, callback: any): void {
        if(this.state !== CommonConfig.the.getCurrentState()){
            return;
        }
        this.hideAnimation();
        this.visible = true;
        if (feature === CommonConfig.RANDOM_FEATURE_ZWOOM) {
            this.playZwoom(callback);
            this.soundManager.play("ZWOOM_popup");
        } else if (feature === CommonConfig.RANDOM_FEATURE_PISTOLE) {
            this.playPistole(callback);
            this.soundManager.play("Pistole_popup");
        } else {
            this.playCripizione(callback);
            this.soundManager.play("Crepazione_popup");
        }
    }

    private playPistole(callback: any) :void{
        this.pistole.visible = true;
        this.pistole.state.setAnimation(0, 'animation', false).listener = {
            complete : () =>{
                this.visible = false;
                this.pistole.visible = false;
                callback();
                this.pistole.state.clearListeners();
                // this.soundManager.stop("Pistole_popup");
            }
        }
    }

    private playZwoom(callback: any) :void{
        this.zwoom.visible = true;
        this.zwoom.state.setAnimation(0, 'animation', false).listener = {
            complete : () =>{
                this.zwoom.visible = false;
                this.visible = false;
                Game.the.app.stage.emit(CommonConfig.PLAY_PENTAGONAL_HEAD_ANIMATION,callback);
                this.zwoom.state.clearListeners();
                // this.soundManager.stop("ZWOOM_popup");
            }
        }
    }

    private playCripizione(callback: any) :void{
        this.crapazaone.visible = true;
        this.crapazaone.state.setAnimation(0, 'animation', false).listener = {
            complete : () =>{
                this.crapazaone.visible = false;
                this.visible = false;
                callback();
                this.crapazaone.state.clearListeners();
                // this.soundManager.stop("Crepazione_popup");
            }
        }
    }

    private hideAnimation() :void{
        this.pistole.visible = false;
        this.zwoom.visible = false;
        this.crapazaone.visible = false;
    }
}