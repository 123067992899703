import { Container, Graphics } from "pixi.js";
import { Game } from "../game";
import { BottomPanel } from "../BottomPanel/BottomPanel";
import { BigWinMeter } from "../Meter/BigWinMeter";
import { LineMeter } from "../Meter/LineMeter";
import { Character } from "../Character/Character";
import { WinpresentationControllerFG } from "../FreeGame/WinpresentationControllerFG";
import { ReelManagerFG } from "../FreeGame/ReelManagerFG";
import { BackgroundViewFG } from "../Background/BackgroundViewFG";
import { ReelViewFG } from "../Background/ReelViewFG";
import { FreeGameLeftMeter } from "../Meter/FreeGameLeftMeter";
import { CommonConfig } from "@/Common/CommonConfig";
import { ReelFrameFG } from "../Background/ReelFrameFG";
import { MusicalNoteAnimation } from "../FeatureComponent/MusicalNoteAnimation";


export class FreeGame extends Container {
    private backgroundView !: BackgroundViewFG;
    private reelView !: ReelViewFG;
    private reelManager !: ReelManagerFG;
    private reelContainer !: Container;
    private bottomPanelButton !: Container;
    private winpresentationController !: WinpresentationControllerFG;
    private normalRation: number = 1920 / 919;
    private bottomPanel !: BottomPanel;
    private bgWinMeter !: BigWinMeter;
    private aspectRatio: number = 0;
    private lineMeter !: LineMeter;
    private character !: Character;
    private freeGameLeftMeter !: FreeGameLeftMeter;
    private aspectRatioMobile: number = 0;
    private reelFrame !: ReelFrameFG;
    private musicalNoteAnimation !: MusicalNoteAnimation;



    constructor() {
        super();
        this.init();
        this.addContainerToStage();
        this.setPosition();
        this.resizeApp();
        this.subscribeEvent();
        Game.the.app.stage.on("RESIZE_THE_APP", this.resizeApp, this);
    }

    private subscribeEvent(): void {
        // window.addEventListener("keydown", (event) => {
        //     if (event.key === "c") { // Press 'C' to toggle the cheat panel
        //         this.cheatPanel.visible = !this.cheatPanel.visible;
        //     }
        // });
    }

    private init() {
        this.initBackground();
        this.parentContainer();
        this.initReelView();
        this.initReelManager();
        this.initWinpresentationController();
        this.initBottomPanel();
        this.initBigWinMeter();
        this.initLineMeter();
        this.initCharacter();
        this.freeGameLeftMeter = new FreeGameLeftMeter();
        this.initMusicalNote();
    }

    private initCharacter(): void {
        this.character = new Character(CommonConfig.FREE_Game);
    }

    private parentContainer(): void {
        this.reelContainer = new Container();
        this.bottomPanelButton = new Container();
    }

   

    private initBackground() {
        this.backgroundView = new BackgroundViewFG();
    }

    private initReelView() {
        this.reelView = new ReelViewFG();
        this.reelFrame = new ReelFrameFG();
    }

    private initReelManager() {
        this.reelManager = new ReelManagerFG();
    }

    private initWinpresentationController(): void {
        this.winpresentationController = new WinpresentationControllerFG();
    }

    private initMusicalNote() :void{
        this.musicalNoteAnimation = new MusicalNoteAnimation(CommonConfig.BASE_GAME);
    }

    private initBigWinMeter(): void {
        this.bgWinMeter = new BigWinMeter(CommonConfig.FREE_Game);
    }

    private initLineMeter(): void {
        this.lineMeter = new LineMeter(CommonConfig.FREE_Game);
    }

    private initBottomPanel(): void {
        this.bottomPanel = new BottomPanel(CommonConfig.FREE_Game);
    }

    private addContainerToStage() {
        this.addChild(this.backgroundView);
        this.addChild(this.reelContainer);
        this.reelContainer.addChild(this.reelView);
        this.reelContainer.addChild(this.reelManager);
        this.reelContainer.addChild(this.reelFrame);
        this.reelContainer.addChild(this.lineMeter);
        this.addChild(this.character);
        this.addChild(this.freeGameLeftMeter);
        this.addChild(this.bottomPanelButton);
        this.addChild(this.bottomPanel);
        this.addChild(this.bgWinMeter);
        this.addChild(this.musicalNoteAnimation);
    }

    private setPosition() {
         this.reelContainer.position.set((1920 - this.reelView.width) / 2, (913 - this.reelView.height) / 2 - 100);
         
        this.reelManager.position.set(154, 166);
        this.lineMeter.position.set(154,  166);
        this.reelContainer.scale.set(1);
        this.aspectRatio = this.reelContainer.height / 919;
        this.aspectRatioMobile = this.reelContainer.width / 360;
        // this.bottomPanelButton.position.set(0, (913 - this.bottomPanelButton.height));
    }

    private resizeApp(): void {
        this.reelContainer.scale.set(0.82);
        this.reelContainer.position.set(505, -11.5);
        this.freeGameLeftMeter.scale.set(1);
        this.freeGameLeftMeter.position.set(316.58,697.319);
        this.musicalNoteAnimation.updateLeftRightContainerPosition(0.8,558, 63.126);
        this.bgWinMeter.position.set(0,0);
        if (window.innerWidth < window.innerHeight) {
            this.reelContainer.scale.set(0.85);
            this.reelContainer.position.set(-115, 138.881);
            this.bgWinMeter.position.set(-630,76.5);
            this.freeGameLeftMeter.position.set(74.08,1258.81);
            this.musicalNoteAnimation.updateLeftRightContainerPosition(0.8,-40, 269.5);
        }
        this.resizeCharacter();
    }

    private resizeCharacter(): void {
        this.character.characterBodyContainer.scale.set(0.9);
        this.character.keyContainer.scale.set(0.9);
        this.character.characterBodyContainer.position.set(1441,52);
        this.character.keyContainer.position.set(this.character.characterBodyContainer.x - this.character.keyContainer.width - 52, -2);

        if (window.innerWidth < window.innerHeight) {
            this.character.characterBodyContainer.scale.set(0.8);
            this.character.characterBodyContainer.position.set(506, 892);
        }
    }
}