import { Container, Graphics, Sprite } from "pixi.js";
import { Game } from "../game";
import gsap from "gsap";
import { Spine } from "@esotericsoftware/spine-pixi-v8";
import { CommonConfig } from "@/Common/CommonConfig";

export class LoadingScreen extends Container {
    private bgGraphics !: Graphics;
    private loadingFillContainer !: Container;
    private maskContainer !: Graphics;
    private loaderBg !: Graphics;
    private loaderfilled !: Graphics;
    private logoSpine !: Spine;
    private totalDistance : number = 540;   
    private lastData : number = 0;


    constructor() {
        super();
        this.init();
        this.resize();
        Game.the.app.stage.on(CommonConfig.HIDE_LOADING_SCREEN, this.hide, this);
        Game.the.app.stage.on("RESIZE_THE_APP", this.resize, this);
    }

    private init(): void {
        this.bgGraphics = new Graphics();
        this.bgGraphics.beginFill(0x000000);
        this.bgGraphics.drawRect(0, 0, 4000, 4000);
        this.bgGraphics.endFill();
        this.addChild(this.bgGraphics)  ;

        this.loadingFillContainer = new Container();
        this.addChild(this.loadingFillContainer);
        
        this.loaderBg = new Graphics()
                        .roundRect(0,0,500,35,5)
                        .fill(0xffffff);
        this.loadingFillContainer.addChild(this.loaderBg);

        this.loaderfilled = new Graphics()
                        .roundRect(0,0,490,25,5)
                        .fill(0x0c60c7);
        this.loadingFillContainer.addChild(this.loaderfilled);

        this.loaderfilled.position.set((this.loaderBg.width - this.loaderfilled.width)/2,(this.loaderBg.height - this.loaderfilled.height)/2)

        this.maskContainer = new Graphics()
                             .rect(this.loaderfilled.x, this.loaderfilled.y, this.loaderfilled.width, this.loaderfilled.height)
                             .fill(0x000000);
        this.loadingFillContainer.addChild(this.maskContainer);

        this.loaderfilled.mask = this.maskContainer;
        this.maskContainer.x = -540;
        // this.loadingAnimation();

    }

    playLogoSpine() :void{
        this.logoSpine = Spine.from({ skeleton: "TopGamingLogo_spine_data", atlas: "TopGamingLogo_spine_atlas" });
        this.logoSpine.state.setAnimation(0, 'anim', false);
        if((window.innerHeight < window.innerWidth)){
            this.logoSpine.pivot.set(-this.logoSpine.width / 2, -this.logoSpine.height / 2);
            this.logoSpine.scale.set(0.5);
            this.logoSpine.position.set((1920 - this.logoSpine.width)/2, (913 - this.logoSpine.height)/2);
        }else{
            this.logoSpine.scale.set(0.75);
            this.logoSpine.position.set(350, 849);
        }
        this.addChild(this.logoSpine);
    }

    private resize() :void{
        if((window.innerHeight > window.innerWidth)){
           this.resizeMobile();
           
        }else{
            this.loadingFillContainer.scale.set(1);
            this.loadingFillContainer.position.set((1920 - this.loadingFillContainer.width)/2,(913 - this.loadingFillContainer.height)*0.7);

            this.logoSpine?.pivot.set(-this.logoSpine.width / 2, -this.logoSpine.height / 2);
            this.logoSpine?.scale.set(0.5);
            this.logoSpine?.position.set((1920 - this.logoSpine.width)/2, (913 - this.logoSpine.height)/2);
        }
    }

    private resizeMobile() :void{
        this.logoSpine?.pivot.set(0,0);
        this.logoSpine?.scale.set(0.75);
        this.logoSpine?.position.set(350, 849);
        this.loadingFillContainer.scale.set(1.1);
        this.loadingFillContainer.position.set(69,1082);
    }


    public loadingAnimation(data:number) :void{        
        if(data === 1){
            this.interactive = true;
        }else{
            data = Number((data).toFixed(1));
        }
        const difference = Number((data - this.lastData).toFixed(1));
        this.lastData = data;
        const factor : number = (difference/1) * this.totalDistance ;      
        this.maskContainer.x += factor;
    }

    private hide() :void{
        this.logoSpine.visible = false;
        this.visible = false;
    }

    // public hideLoadingScreen() :void{

    // }
}