import { Container } from "pixi.js";
import gsap from "gsap";
import { CommonConfig } from "@/Common/CommonConfig";
import { Game } from "../game";
import { SingleMusicalNote } from "./SingleMusicalNote";
import SoundManager from "../Sound/SoundManager";

export class MusicalNoteAnimation extends Container {
    private musicalNoteContainer !: Container;
    private midPoint: number = 0
    private shootingFxPool: SingleMusicalNote[] = [];
    private soundManager !: SoundManager;
    private keyPos : number[][] = [[1203.5,440],[1400,536.5],[1291.5,592]];
    private keyPosPort : number[][] = [[765,1209],[930.5,1263],[844.5,1332.5]];

    constructor(private state: string) {
        super();
        this.init();
    }

    private init(): void {
        this.musicalNoteContainer = new Container();
        this.addChild(this.musicalNoteContainer);
        this.soundManager = SoundManager.getInstance();
        Game.the.app.stage.on(CommonConfig.SPIN_STOPPED, this.resetsOnSpinClick, this);
        Game.the.app.stage.on(CommonConfig.PLAY_MUSICAL_NOTE_ANIMATION, this.initMusicalNotes, this);
        // this.initMusicalNotes();
    }

    private resetsOnSpinClick(): void {
        this.musicalNoteContainer.removeChildren(0,this.musicalNoteContainer.children.length)
    }

    private initMusicalNotes() :void{
        // CommonConfig.the.setMusicalNoteGridId([[0,0,1],[2,4,2],[4,4,3]]);
        let musicalNoteIds : number[][] = CommonConfig.the.getMusicalNoteGridId();
        this.soundManager.play('lockermusic1');
        for(let i : number = 0;i<musicalNoteIds.length;i++){
            let musicalNote = this.getShootingFx();
            musicalNote.visible = true;
            musicalNote.init(musicalNoteIds[i][2]);
            musicalNote.position.set(musicalNoteIds[i][0] * CommonConfig.reelWidth + (CommonConfig.reelWidth)/2,musicalNoteIds[i][1] * CommonConfig.symbolHeight + (CommonConfig.symbolHeight)/2);
            this.musicalNoteContainer.addChild(musicalNote);
            CommonConfig.the.setUnlockedLockCounter(CommonConfig.the.getUnlockedLockCounter() + 1);
            this.shootTo(musicalNoteIds[i][2],musicalNote);
        }
    }

    private getShootingFx(): SingleMusicalNote {
        if (this.shootingFxPool.length > 0) {
            return this.shootingFxPool.pop()!;
        }
        return new SingleMusicalNote(); // Create a new one if none are available
    }

    private releaseShootingFx(fx: SingleMusicalNote) {
        fx.visible = false;
        this.shootingFxPool.push(fx);
    }

    private playShootingAnimation(leftOrRight: string): void {
       
    }

    updateLeftRightContainerPosition(scale: number, reelXPos: number, reelWidth: number) {
        this.musicalNoteContainer.scale.set(scale);
        this.musicalNoteContainer.position.set(reelXPos, reelWidth);
    }

    private shootTo(musicalNoteType : number, shootingFx: SingleMusicalNote) {
        const x : number = window.innerHeight < window.innerWidth ? this.keyPos[musicalNoteType - 1][0] : this.keyPosPort[musicalNoteType - 1][0];
        const y : number = window.innerHeight < window.innerWidth ? this.keyPos[musicalNoteType - 1][1] : this.keyPosPort[musicalNoteType - 1][1];
        const angle = Math.atan2(y - shootingFx.y, x - shootingFx.x);
        const angleDeg = angle * 180 / Math.PI;
        shootingFx.angle = angleDeg;
        const shootTweem = gsap.to(shootingFx, {
            x: x, 
            y: y,
            duration: 0.5,
            onComplete : () =>{
                gsap.killTweensOf(this);
                gsap.killTweensOf(shootTweem);
                Game.the.app.stage.emit(CommonConfig.PLAY_LOCKER_ANIMATION,musicalNoteType);
                shootingFx.stopAnimation();
                shootingFx.visible= false;
                shootingFx.position.set(0,0);
                this.releaseShootingFx(shootingFx);
            }
        });
        shootTweem.play();
    }
}