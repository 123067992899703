import { Spine } from "@esotericsoftware/spine-pixi-v8";
import { Container } from "pixi.js";
import { Game } from "../game";

export class Logo extends Container{
    private logo !: Spine;
    constructor(private state : string) {
        super();
        this.init();
        this.setToPosition();
        Game.the.app.stage.on("RESIZE_THE_APP", this.setToPosition, this);
    }

    private init() :void{
       this.logo = Spine.from({skeleton : "logo_animation_spine_data",atlas : "logo_animation_spine_atlas"});
       this.addChild(this.logo);
       this.logo.state.setAnimation(0,"intro",true);
    }

    private setToPosition() :void{
        if((window.innerHeight > window.innerWidth)){
            this.logo.scale.set(0.6);
            this.logo.position.set(367.5,109.5);
        }else{
            this.logo.scale.set(0.6);
            this.logo.position.set(218,164);
        }
    }
}