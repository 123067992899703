import { Assets, Container, Sprite } from "pixi.js";
import { Game } from "../game";
import { SpinButton } from "../Button/SpinButton";
import { ThunderButton } from "../Button/ThunderButton";
import { SettingButton } from "../Button/SettingButton";
import { WinMeter } from "../Meter/WinMeter";
import { Spine } from "@esotericsoftware/spine-pixi-v8";
import { BetMeter } from "../Meter/BetMeter";
import { PlusButton } from "../Button/PlusButton";
import { MinusButton } from "../Button/MinusButton";
import { CommonConfig } from "@/Common/CommonConfig";
import { BalanceMeter } from "../Meter/BalanceMeter";
import { AutoplayBtn } from "../Button/AutoplayBtn";
import { AutoplayController } from "../Symbol/AutoplayController";
import { AutoplayMeter } from "../Meter/AutoplayMeter";
import { SoundButton } from "../Button/SoundButton";
import { FreeGameLeftMeter } from "../Meter/FreeGameLeftMeter";
import { SettingPage } from "../Button/SettingPage";


export class BottomPanel extends Container {
    private bg !: Sprite;
    private spinBtn !: SpinButton;
    private thunderButton !: ThunderButton;
    private settingButton !: SettingButton;
    private soundButton !: SoundButton;
    private winMeter !: WinMeter;
    private betMeter !: BetMeter;
    private aspectRatio : number =1;
    private minusButton !: MinusButton;
    private plusButton !: PlusButton;
    private balanceMeter !: BalanceMeter;
    private autoplayBtn !: AutoplayBtn;
    private autoplayMeter !: AutoplayMeter;
    private gap : number = 30;
    private state : string;
    private settingPage !: SettingPage;


    constructor(state : string) {
        super();
        this.state = state;
        this.init();
        this.initButton();
        this.addContainerToStage();
        this.resizeApp();
        Game.the.app.stage.emit(CommonConfig.CHECK_ENABLE_DISABLE_PLUS_MINUS_BTN);
        Game.the.app.stage.on("RESIZE_THE_APP", this.resizeApp, this);
        // Game.the.app.stage.emit(CommonConfig.UPDATE_BALANCE,0);
    }

    private initButton(): void {
        this.spinBtn = new SpinButton(this.state);
        this.settingButton = new SettingButton(this.state);
        this.thunderButton = new ThunderButton(this.state);
        this.winMeter = new WinMeter(this.state);
        this.betMeter = new BetMeter(this.state);
        this.minusButton = new MinusButton(this.state);
        this.plusButton = new PlusButton(this.state);
        this.balanceMeter = new BalanceMeter(this.state);
        this.autoplayBtn = new AutoplayBtn(this.state);
        this.autoplayMeter = new AutoplayMeter();
        this.soundButton = new SoundButton(this.state);
        if(this.state === CommonConfig.BASE_GAME){
            new AutoplayController();
            this.initSettingPageObj();
        }
    }

    private addContainerToStage() {
        this.addChild(this.spinBtn);
        this.addChild(this.settingButton);
        this.addChild(this.thunderButton);
        this.addChild(this.winMeter);
        this.addChild(this.betMeter);
        this.addChild(this.minusButton);
        this.addChild(this.plusButton);
        this.addChild(this.balanceMeter);
        this.addChild(this.autoplayBtn);
        this.addChild(this.autoplayMeter);
        this.addChild(this.soundButton);
    }

    private initSettingPageObj() :void{
        this.settingPage = new SettingPage();
        this.addChild(this.settingPage);
    }


    private init(): void {
        this.bg = new Sprite(Assets.get('bottomPanelBg'));
        // 
    }

    private resizeApp() :void{
        this.spinBtn.scale.set(1);
        let height : number = this.spinBtn.height;
        let currentHeightPanel = height/953 * 953 ;
        let scale : number = currentHeightPanel / height;
        this.bg.position.set(0,913 - this.bg.height);
        this.winMeter.position.set(896.335,873.5);
        this.spinBtn.scale.set(scale);
        this.settingButton.scale.set(scale);
        this.thunderButton.scale.set(scale);
        this.minusButton.scale.set(scale);
        this.plusButton.scale.set(scale);
        this.soundButton.scale.set(scale);
        this.autoplayBtn.scale.set(scale * 1.2);
        this.thunderButton.position.set(1920 - (this.thunderButton.width *  2), 913 - (this.thunderButton.height  *  1.2));
        this.settingButton.position.set(this.settingButton.width, 913 -  (this.settingButton.height * 1.2));
        this.soundButton.position.set(this.settingButton.x + this.settingButton.width + (this.soundButton.width/2), 913 -  (this.settingButton.height * 1.2));
        this.balanceMeter.position.set(247.35, 867.8);
        this.betMeter.position.set(276.833,840.8);
        this.autoplayBtn.position.set(this.thunderButton.x - (this.autoplayBtn.width * 1.5), 913 - (this.autoplayBtn.height * 1.2));
        this.autoplayMeter.position.set(this.autoplayBtn.x + (this.autoplayBtn.width - this.autoplayMeter.width)/2, this.autoplayBtn.y - this.autoplayMeter.height);
        this.spinBtn.position.set(this.autoplayMeter.x - (this.spinBtn.width * 1.5), 913 - this.spinBtn.height);
        this.minusButton.position.set(this.spinBtn.x - this.minusButton.width, 913 - this.minusButton.height);
        this.plusButton.position.set(this.minusButton.x,this.minusButton.y - this.plusButton.height);
        this.settingPage && (this.settingPage.scale.set(scale));
        this.settingPage && (this.settingPage.position.set(this.settingButton.x + (this.settingButton.width - this.settingPage.width)/2,  this.settingButton.y - this.settingPage.height))

        if(window.innerWidth < window.innerHeight){
            this.resizeInMoble();
        }
    }

    private resizeInMoble():void{
        this.spinBtn.scale.set(1.5);
        this.bg.position.set(0,913 - this.bg.height);
        this.winMeter.scale.set(1.4);
        this.balanceMeter.scale.set(1.4);
        this.betMeter.scale.set(1.4);
        this.winMeter.position.set(242.5,1606);
        this.spinBtn.scale.set(1.5);
        this.settingButton.scale.set(1.6);
        this.thunderButton.scale.set(1.6);
        this.minusButton.scale.set(1.4);
        this.plusButton.scale.set(1.4);
        this.soundButton.scale.set(1.6);
        this.autoplayBtn.scale.set(1.6);
        this.balanceMeter.position.set(10, 1681.4);
        this.betMeter.position.set(417,1681.4);
        this.settingButton.position.set(-21, -34);
        this.spinBtn.position.set(236.5,1240.5);
        this.minusButton.position.set(this.spinBtn.x - this.minusButton.width, this.spinBtn.y + (this.spinBtn.height * 0.7));
        this.plusButton.position.set(this.spinBtn.x + this.spinBtn.width, this.minusButton.y);
        this.thunderButton.position.set(-23.5, 1400);
        this.soundButton.position.set(-23.5, 1514);
        this.autoplayBtn.position.set(591, 1514);
        this.autoplayMeter.position.set(this.autoplayBtn.x + (this.autoplayBtn.width - this.autoplayMeter.width)/2, this.autoplayBtn.y - this.autoplayMeter.height);
        this.settingPage && (this.settingPage.scale.set(1));
        this.settingPage && (this.settingPage.position.set(-10.85,64.06));
    }
}