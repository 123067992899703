import { Assets, Container, Loader, Sprite, Spritesheet } from "pixi.js";
import { Game } from "../game";
import { CommonConfig } from "@/Common/CommonConfig";
import { Spine } from "@esotericsoftware/spine-pixi-v8";

export class StaticWild3x3 extends Container{
    private symbol: Spine;
    private symbolName !: string;
    private blastAnimation !: Spine;

    constructor(texture: string) {
        super();
        this.symbol = Spine.from({ skeleton: `${texture}_spine_data`, atlas: `${texture}_spine_atlas` });
        this.blastAnimation = Spine.from({ skeleton: "Symbol_Replace_FX_spine_data", atlas: "Symbol_Replace_FX_spine_atlas" });
        this.symbol.name = texture;
        this.addChild(this.symbol);
        this.addChild(this.blastAnimation);
        this.symbolName = texture;
        this.name = texture;
        this.symbol.scale.set(0.52);
        // this.symbol.pivot.set(this.symbol.width/2,this.symbol.height/2);
        this.symbol.position.set(CommonConfig.reelWidth,CommonConfig.symbolHeight * 2.6);
        this.blastAnimation.scale.set(1.2);
        this.blastAnimation.visible = false;
        this.symbol.state.setAnimation(0, 'loop', false);
    }

    public setPosition(x: number, y: number): void {
        this.position.set(x, y);
    }

    public clone(): StaticWild3x3 {
        const cloneSym = new StaticWild3x3(this.symbolName);
        return cloneSym;
    }

    playLandingAnimation(): Promise<void> {
        return new Promise<void>((resolve) => {
            if(this.symbol.state.data.skeletonData.findAnimation("start")){
                this.symbol.state.setAnimation(0, 'start', false); 
                this.symbol.state.addListener({
                    complete: () => {
                        resolve();
                        if (this.symbol) {
                            this.symbol.state.clearListeners();
                            resolve();
                            this.symbol.state.setAnimation(0, 'loop', true);
                        }
                    },
                });
            }else {
                resolve();
            }
        });
    }

    playSpineAnimation(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.symbol.state.setAnimation(0, 'loop', false);
            this.symbol.state.addListener({
                complete: () => {
                    resolve();
                    if (this.symbol) {
                        this.symbol.state.clearListeners();
                        this.symbol.state.setAnimation(0, 'loop', true);
                        // this.symbol.state.tracks = [];
                        // this.symbol.state.setAnimation(0, 'loop', true);
                    }
                },
            });
        });
    }

    hideSpineAnimation(): Promise<void> {
        return new Promise<void>((resolve) => {
            resolve();
            this.symbol.state.setAnimation(0, 'off', false);
        });
    }

    replaceSpineAnimation():Promise<void> {
        return new Promise<void>((resolve) => {
            resolve();
            // this.symbol.state.setAnimation(0, 'loop', true);
            // this.blastAnimation.visible = true;
            // this.blastAnimation.state.setAnimation(0, 'animation', false);
            // this.blastAnimation.state.addListener({
            //     complete: () => {
            //         this.blastAnimation.visible = false;
            //         this.blastAnimation.state.clearListeners()
            //         this.blastAnimation.state.tracks = []
            //         resolve();
            //     },
            // });
        });
    }
    
}