import { Spine } from "@esotericsoftware/spine-pixi-v8";
import { Assets, Container, Graphics, Sprite, Spritesheet, Text, TextStyle } from "pixi.js";
import gsap from "gsap";
import { CommonConfig } from "@/Common/CommonConfig";
import { Game } from "../game";

export class FreeGameIntro extends Container {
    // private popBg !: Sprite;
    private spine !: Spine;
    private graphics !: Graphics;
    private youHaveOneText !: Text;
    private freeGameLeftText !: Text;
    private freeSpinText !: Text;
    private continueBtnText !: Text;
    private popup_text_congratulation !: Sprite;
    private isIntro: boolean = false;
    private bigWinFontTexture !: Spritesheet;
    private newWinMeter !: Container;
    private newWinBox !: Container;

    constructor() {
        super();
        this.init();
        Game.the.app.stage.on(CommonConfig.SHOW_FREE_GAME_INTRO, this.showIntro, this);
        Game.the.app.stage.on(CommonConfig.SHOW_FREE_GAME_OUTRO, this.showOutro, this);
        this.addChild(this.newWinBox);
        this.newWinBox.addChild(this.newWinMeter);
        this.setToPosition();
        Game.the.app.stage.on("RESIZE_THE_APP", this.setToPosition, this);
    }

    private init(): void {
        this.graphics = new Graphics().rect(0, 0, 5000, 4000).fill("#000000");
        this.graphics.alpha = 0.8;
        this.addChild(this.graphics);
        // this.popBg = new Sprite(Assets.get("popup_congratulation"));
        this.spine = Spine.from({ skeleton: "popup_congratulation_spine_data", atlas: "popup_congratulation_spine_atlas" });
        // this.addChild(this.popBg);
        this.addChild(this.spine);
        this.spine.position.set(790, 412);
        this.popup_text_congratulation = new Sprite(Assets.get("popup_text_congratulation_english"));
        this.addChild(this.popup_text_congratulation);
        this.popup_text_congratulation.position.set(364.5, -19.5);
        this.bigWinFontTexture = Assets.get("bigWin_font");
        this.newWinBox = new Container();
        this.newWinMeter = new Container();
        const graphics : Graphics = new Graphics().rect(0, 0, 523, 135.5).fill("#000000");
        graphics.alpha = 0;
        this.newWinBox.addChild(graphics);
        this.initText();
        this.on('pointerup', this.hideIntro, this);
    }

    private setToPosition(): void {
        if (window.innerWidth < window.innerHeight) {
            this.graphics.position.set(-262, -841);
        } else {
            this.graphics.position.set(-934, -424.5);
        }
    }

    private updateText(text : string, isCurrency: boolean) :void{
        this.setNumber(text,isCurrency);
        this.newWinMeter.position.set((this.newWinBox.width - this.newWinMeter.width) * 0.45,(this.newWinBox.height - this.newWinMeter.height) * 0.5)
    }

    private initText(): void {
        const youHaveOneTextStyle = new TextStyle({
            fill: "#3c3333",
            fontSize: 48,
            fontWeight: "bold"
        });
        const freeGameLeftTextStyle = new TextStyle({
            fill: "#ffffff",
            fontSize: 72,
            fontWeight: "bold"
        });
        const freeSpinTextStyle = new TextStyle({
            fill: "#3c3333",
            fontSize: 42,
            fontWeight: "bold"
        });
        const continueBtnTextStyle = new TextStyle({
            fill: "#ffffff",
            fontSize: 36,
            fontWeight: "bold"
        });
        this.youHaveOneText = new Text({
            text: `YOU HAVE WON`,
            style: youHaveOneTextStyle
        });
        this.freeGameLeftText = new Text({
            text: `10`,
            style: freeGameLeftTextStyle
        });
        this.freeSpinText = new Text({
            text: `FREE SPINS`,
            style: freeSpinTextStyle
        });
        this.continueBtnText = new Text({
            text: `Press anywhere to continue`,
            style: continueBtnTextStyle
        });

        this.addChild(this.youHaveOneText);
        this.youHaveOneText.position.set(606, 224.5);
        this.freeGameLeftText.position.set(749.5, 369.5);
        this.freeSpinText.position.set(672.5, 554.5);
        this.continueBtnText.position.set(557, 813);

        // this.addChild(this.freeGameLeftText);
        this.addChild(this.freeSpinText);
        this.addChild(this.continueBtnText);
        this.visible = false;
        this.alpha = 0;
        // this.updateText();
    }

    private showIntro(): void {
        this.updateIntroText()
        this.isIntro = true;
        this.visible = true;
        this.alpha = 0;
        gsap.to(this, {
            alpha: 1,
            duration: 1,
            ease: "power4.in",
        })
        this.spine.visible = true;
        this.spine.state.setAnimation(0, "enter_congratulation_intro", false);
        this.spine.state.addListener({
            complete: () => {
                this.spine.state.clearListeners();
                this.spine.state.setAnimation(0, "enter_congratulation_loop", true);
                this.interactive = true;
            },
        });
    }

    private showOutro(): void {
        this.updateOutroText();
        this.isIntro = false;
        this.visible = true;
        this.alpha = 0;
        gsap.to(this, {
            alpha: 1,
            duration: 1,
            ease: "power4.in",
        })
        this.spine.visible = true;
        this.spine.state.setAnimation(0, "end_congratulation_intro", false);
        this.spine.state.addListener({
            complete: () => {
                this.spine.state.clearListeners();
                this.spine.state.setAnimation(0, "end_congratulation_loop", true);
                this.interactive = true;
            },
        });
    }

    private setNumber(value: string, isCurrency : boolean) {
        this.newWinMeter.removeChildren(0, this.newWinMeter.children.length)
        const digits = value.split("");
        let xOffset = 0;
        isCurrency && digits.unshift('euro');
        digits.forEach((digit) => {
            if (digit === ".") {
                digit = 'dot'
            }
            const digitSprite = new Sprite(this.bigWinFontTexture.textures[`${digit}.png`]);
            digitSprite.x = xOffset;
            digit === "dot" && (digitSprite.y = 30);
            this.addChild(digitSprite);
            this.newWinMeter.addChild(digitSprite);
            xOffset += digitSprite.width * 0.7; // Adjust spacing
        });
    }

    private updateOutroText(): void {
        this.freeSpinText.text = 'IN 10 FREE SPIN';
        this.freeGameLeftText.text = `€ ${CommonConfig.the.getTotalWinAmountInFreeGame().toFixed(2)}`;
        this.updateText(CommonConfig.the.getTotalWinAmountInFreeGame().toFixed(2),true);
        this.newWinBox.scale.set(1.5);
        this.newWinBox.position.set(420,309.5);
        this.freeSpinText.position.set(629, 554.5);
        this.freeGameLeftText.position.set(651, 369.5)
    }

    private updateIntroText(): void {
        this.freeSpinText.text = 'FREE SPIN';
        this.freeGameLeftText.text = `10`;
        this.updateText('10',false);
        this.newWinBox.scale.set(1);
        this.newWinBox.position.set(549.5,341);
        this.freeGameLeftText.position.set(749.5, 369.5);
        this.freeSpinText.position.set(672.5, 554.5);
    }

    private hideOutro(): void {
        this.spine.state.clearListeners();
        this.spine.state.setAnimation(0, "end_congratulation_outro", false);
        this.spine.state.addListener({
            complete: () => {
                this.spine.state.clearListeners();
                this.spine.visible = false;
            },
        });
        gsap.to(this, {
            alpha: 0,
            duration: 1,
            ease: "power4.in",
            onComplete: () => {
                this.visible = false;
                this.interactive = false;
                Game.the.app.stage.emit(CommonConfig.UPDATE_BALANCE, CommonConfig.the.getCurrentWinAmount());
                Game.the.app.stage.emit(CommonConfig.HIDE_BONUS);
            }
        })
    }

    private hideIntro(): void {
        if (!this.isIntro) {
            this.hideOutro();
            return;
        }
        this.spine.state.clearListeners();
        this.spine.state.setAnimation(0, "enter_congratulation_outro", false);
        this.spine.state.addListener({
            complete: () => {
                this.spine.state.clearListeners();
                this.spine.visible = false;
            },
        });
        Game.the.app.stage.emit(CommonConfig.START_BONUS);
        gsap.to(this, {
            alpha: 0,
            duration: 1,
            ease: "power4.in",
            onComplete: () => {
                this.visible = false;
                this.interactive = false;
                Game.the.app.stage.emit(CommonConfig.AFTER_INTRO_HIDE);
                Game.the.app.stage.emit(CommonConfig.UPDATE_BALANCE, CommonConfig.the.getCurrentWinAmount());
                Game.the.app.stage.emit(CommonConfig.UPDATE_FREEGAME_LEFT_METER);
                Game.the.app.stage.emit(CommonConfig.FG_START_SPIN);
                Game.the.app.stage.emit(CommonConfig.FG_DISABLE_ALL_BUTTON);
            }
        })
    }
}