import { Assets, Container, Loader, Sprite, Spritesheet } from "pixi.js";
import { Game } from "../game";
import { Spine } from "@esotericsoftware/spine-pixi-v8";

export class StaticSymbol extends Container {
    private symbol: Spine;
    private symbolName !: string;

    constructor(texture: string) {
        super();
        this.symbol = Spine.from({ skeleton: `${texture}_spine_data`, atlas: `${texture}_spine_atlas`});
        this.symbol.name = texture;
        this.addChild(this.symbol);
        this.symbolName = texture;
        this.name = texture;
        this.symbol.scale.set(0.25);
        this.setSymbolOffset();
    }

    private setSymbolOffset(): void {
        switch (this.symbolName) {
            case 'sym_slotmachine':
                this.symbol.scale.set(0.45);
                this.symbol.y = this.symbol.height / 2;
                this.symbol.state.setAnimation(0, 'static', false);
                break;
            case 'sym_gate':
                this.symbol.scale.set(0.75);
                this.symbol.y = this.symbol.height / 2;
                this.symbol.state.setAnimation(0, 'static', false);
                break;
            case 'sym_dirtypan':
                this.symbol.scale.set(0.9);
                this.symbol.y = this.symbol.height / 2;
                this.symbol.state.setAnimation(0, 'static', false);
                break;
            case 'sym_wild':
                this.symbol.scale.set(0.85);
                this.symbol.y = this.symbol.height / 2;
                this.symbol.state.setAnimation(0, 'regular', true);
                break;
            case 'sym_basculohead':
                this.symbol.scale.set(0.8);
                this.symbol.y = 94.5;
                this.symbol.state.setAnimation(0, 'static', false);
                break;
            case 'sym_bike':
                this.symbol.scale.set(0.76);
                this.symbol.state.setAnimation(0, 'static', false);
                break;
            case 'sym_musicalnotes1':
                this.symbol.state.setAnimation(0, 'regular', false);
                break;
            case 'sym_plane':
                this.symbol.scale.set(0.9);
                this.symbol.state.setAnimation(0, 'static', false);
                break;
            case 'sym_mop':
                this.symbol.scale.set(0.9);
                this.symbol.state.setAnimation(0, 'static', false);
                break;
            case 'sym_monitor':
                this.symbol.scale.set(0.75);
                this.symbol.state.setAnimation(0, 'static', false);
                break;
            case 'Sym_L5':

                break;
            default:

                break;
        }
    }

    public setPosition(x: number, y: number): void {
        this.position.set(x, y);
    }

    public clone(): StaticSymbol {
        const cloneSym = new StaticSymbol(this.symbolName);
        return cloneSym;
    }

    playLandingAnimation(): Promise<void> {
        return new Promise<void>((resolve) => {
            resolve();
            if(this.symbolName === "sym_wild"){
                this.symbol.state.setAnimation(0, 'regular', true);
            }
            // if(this.symbol.state.data.skeletonData.findAnimation("regular")){
            //     this.symbol.state.setAnimation(0, 'regular', false);
            //     this.symbol.state.addListener({
            //         complete: () => {
            //             resolve();
            //             if (this.symbol) {
            //                 this.symbol.state.clearListeners();
            //                 if(this.symbol.state.data.skeletonData.findAnimation("static")){
            //                     this.symbol.state.setAnimation(0, 'static', false);
            //                     resolve();
            //                 }else{
            //                     resolve();
            //                 }
            //             }
            //         },
            //     });
            // }else{
            //     resolve();
            // }
        });
    }

    playSpineAnimation(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.symbol.state.setAnimation(0, 'on', false);
            this.symbol.state.addListener({
                complete: () => {
                    if (this.symbol) {
                        this.symbol.state.clearListeners()
                        if(this.symbolName === "sym_wild"){
                            this.symbol.state.setAnimation(0, 'regular', true);
                        }else{
                            this.symbol.state.setAnimation(0, 'static', false);
                        }
                        resolve();
                    }
                },
            });
        });
    }

    hideMusical(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.symbol.state.setAnimation(0, 'off', false);
            this.symbol.state.addListener({
                complete: () => {
                    resolve();
                },
            });
        });
    }

    // replaceSpineAnimation():Promise<void> {
    //     return new Promise<void>((resolve) => {
    //         this.blastAnimation.visible = true;
    //         this.blastAnimation.state.setAnimation(0, 'animation', false);
    //         this.blastAnimation.state.addListener({
    //             complete: () => {
    //                 this.blastAnimation.visible = false;
    //                 this.blastAnimation.state.clearListeners()
    //                 this.blastAnimation.state.tracks = []
    //                 resolve();
    //             },
    //         });
    //     });
    // }

}