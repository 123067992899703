import { Container, Graphics } from "pixi.js";
import { CommonConfig } from "../../Common/CommonConfig";
import { SymbolPool } from "../Symbol/SymbolPool";
import { Game } from "../game";
import gsap from "gsap";
import { FreegameReel } from "./FreegameReel";
import { FreeGamePos } from "./FreeGamePos";
import { ISingleWinDetails } from "../Interface/GameInterface";
import { WinframeReelContainerFG } from "../WinframeFreeGame/WinframeReelContainerFG";
import { WinframeContainerFG } from "../WinframeFreeGame/WinframeContainerFG";
import { StaticWild3x3 } from "../Symbol/StaticWild3x3";
import { StaticSymbol } from "../Symbol/StaticSymbol";
import SoundManager from "../Sound/SoundManager";

interface winframeData {
  reelId: number;
  rowId: number;
  direction: number[];
}
export class ReelManagerFG extends Container {
  private maskContainer!: Graphics;
  private reelsContainer!: Container;
  private winframeContainer!: Container;
  private symboldWinIds: number[] = [];
  private currentIndexSymbolWinIds: number = 0;
  private winframeData: winframeData[] = [];
  private musicalNoteContainer!: Container;
  private reelManagerContainer !: Container;
  private soundManager !: SoundManager;


  constructor() {
    super();
    this.reelManagerContainer = new Container();
    this.soundManager = SoundManager.getInstance();
    this.addChild(this.reelManagerContainer);
    this.initializeReelContainer();
    this.initializeWinframeContainer();
    this.initGraphics();
    this.subscribeEventFG();
    let randomWild: number[][] = [
      [4, 4, 5, 6, 3],
      [7, 6, 4, 7, 3],
      [4, 7, 6, 3, 2],
      [6, 3, 3, 4, 5],
      [6, 2, 6, 3, 4],
    ];
    this.updateView(randomWild);
  }

  private subscribeEventFG(): void {
    Game.the.app.stage.on(CommonConfig.FG_SET_RESPONSE_AT_REEL, this.setSymbolAtReel, this);
    Game.the.app.stage.on(CommonConfig.FG_START_SPIN, this.spinTheReels, this);
    Game.the.app.stage.on(CommonConfig.FG_PLAY_ANIMATED_WIN_SYMBOL, this.onPlayWinSymbol, this);
    Game.the.app.stage.on(CommonConfig.FG_UPDATE_VIEW_ON_REEL, this.updateView, this);
    Game.the.app.stage.on(CommonConfig.PLAY_KEY_ANIMATION, this.playKeyAnimation, this);
    Game.the.app.stage.on(CommonConfig.UPDATE_WILD_AFTER_KEY_ANIMATION, this.updateWildOnKey, this);
    Game.the.app.stage.on(CommonConfig.FG_SPIN_STOPPED, this.playLandingAnimation, this);
  }


  private playKeyAnimation(): Promise<void> {
    const promises: Promise<void>[] = [];
    for (let i: number = 0; i < CommonConfig.the.getMusicalNoteGridId().length; i++) {
      this.soundManager.play('sfxsweep');
      const position: string = `${CommonConfig.the.getMusicalNoteGridId()[i][0]},${CommonConfig.the.getMusicalNoteGridId()[i][1]}`;
      const reelRow: string[] = position.split(",");
      const reel = this.reelsContainer.children[Number(reelRow[0])] as FreegameReel;
      promises.push(reel.hideMusicalAnim(Number(reelRow[1])));
    }
    return Promise.all(promises).then(() => {
      Game.the.app.stage.emit(CommonConfig.PLAY_MUSICAL_NOTE_ANIMATION);
    });
  }

  private updateWildOnKey(): Promise<void> {
    const promises: Promise<void>[] = [];
    for (let i: number = 0; i < CommonConfig.the.getMusicalNoteGridId().length; i++) {
      const reelPos: number[] = [CommonConfig.the.getMusicalNoteGridId()[i][0], CommonConfig.the.getMusicalNoteGridId()[i][1]]
      const reel: FreegameReel = this.reelsContainer.children[reelPos[0]] as FreegameReel;
      let posContainer: FreeGamePos = (reel as FreegameReel).children[reelPos[1]] as FreeGamePos;
      let symbol = SymbolPool.the.getSymbol(
        CommonConfig.symbolIds[CommonConfig.the.getViewFreeGame()[reelPos[0]][reelPos[1]]]);
      promises.push((posContainer as FreeGamePos).updateWild(symbol as StaticSymbol));
    }
    return Promise.all(promises).then(() => {
      Game.the.app.stage.emit(CommonConfig.NEXT_ACTION_AFTER_KEY_ANIMATION);
    });
  }

  private initGraphics(): void {
    this.maskContainer = new Graphics();
    this.maskContainer.beginFill(0xffa500);
    this.maskContainer.drawRect(-85, -120, 1000, 1000);
    this.maskContainer.endFill();
    this.maskContainer.position.set(-26.5, -42);
    // this.maskContainer.alpha = 0.5;
    this.reelManagerContainer.addChild(this.maskContainer);
    this.mask = this.maskContainer;
  }

  private playLandingAnimation() :void{
      const promises : Promise<void>[] = [];
      this.reelsContainer.children.forEach((value, index) => {
        (value as FreegameReel).children.forEach((pos, posindex) => {
          const reelPromise = (pos as FreeGamePos).playLanding();
          promises.push(reelPromise);
        });
      });
      Promise.all(promises).then(() => {
        Game.the.app.stage.emit(CommonConfig.FG_PRESENTATION_AFTER_LANDING_ANIMATON);
      });
    }
  

  private sortArray(arr: Set<string>): Set<string> {
    const newArr = Array.from(arr).sort((a, b) => {
      const [reelA, rowA] = a.split(",").map(Number);
      const [reelB, rowB] = b.split(",").map(Number);

      if (reelA !== reelB) {
        return reelA - reelB; // Sort by reel first
      } else {
        return rowA - rowB; // If reel is the same, sort by row
      }
    });
    const sortedSet = new Set(newArr);
    return sortedSet;
  }

  private async onPlayWinSymbol(): Promise<void> {
    let winGrid: Map<number, ISingleWinDetails> =
      CommonConfig.the.getWinGridFreeGame();
    this.symboldWinIds = [];
    this.currentIndexSymbolWinIds = 0;
    for (const symbol of winGrid.keys()) {
      this.symboldWinIds.push(winGrid.get(symbol)!.id);
    }
    await this.playAnimations();
  }

  private async playAnimations(): Promise<void> {
    let winGrid: Map<number, ISingleWinDetails> = CommonConfig.the.getWinGridFreeGame();
    let winReelData: { [key: number]: number[] } = {};
    let winData: Set<string> = winGrid.get(this.symboldWinIds[this.currentIndexSymbolWinIds])!.index_set;
    this.createWinFrame(winData);
    this.playWinframeAnimation();
    gsap.delayedCall(0.5, () => {
      Game.the.app.stage.emit(CommonConfig.FG_HIDE_WINFRAME_ANIMATION);
    });
    winData.forEach(position => {
      let reelRow: string[] = position.split(",");
      // console.log(reelRow);
      if (winReelData.hasOwnProperty(Number(reelRow[0]))) {
        winReelData[Number(reelRow[0])].push(Number(reelRow[1]));
      } else {
        winReelData[Number(reelRow[0])] = [Number(reelRow[1])];
      }
    });
    CommonConfig.the.setWinReelIdsFreeGame([]);
    CommonConfig.the.setLineWinAmount(0);
    const reelPromises: Promise<void>[] = [];
    Object.keys(winReelData).forEach(key => {
      const reelKey = parseInt(key, 10);
      const reelPromise = (this.reelsContainer.children[reelKey] as FreegameReel).playWinAnim(
        winReelData[reelKey].sort())
      reelPromises.push(reelPromise);
    });

    // Wait for all reel animations to complete
    await Promise.all(reelPromises);
    let reelX: number = Number(Object.keys(winReelData).sort()[0]);
    let rowY: number = winReelData[reelX].sort()[0];
    CommonConfig.the.setTotalWinSymbolCount(CommonConfig.the.getTotalWinSymbolCount() + winData.size);
    let cascadeWinAmount: number = CommonConfig.the.getWinAmount(this.symboldWinIds[this.currentIndexSymbolWinIds], winData.size);
    cascadeWinAmount = Number(cascadeWinAmount.toFixed(2));
    CommonConfig.the.setCurrentWinAmount(CommonConfig.the.getCurrentWinAmount() + cascadeWinAmount);
    CommonConfig.the.setCurrentWinAmountInFreeGame(CommonConfig.the.getCurrentWinAmountInFreeGame() + cascadeWinAmount);
    // console.log("Update --------"+ CommonConfig.the.getTotalWinSymbolCount());
    CommonConfig.the.setLineWinAmount(cascadeWinAmount);
    Game.the.app.stage.emit(CommonConfig.FG_UPDATE_LINE_WIN_METER, [reelX, rowY]);
    Game.the.app.stage.emit(CommonConfig.FG_UPDATE_WIN_METER);
    // Game.the.app.stage.emit(CommonConfig.FG_UPDATE_PENTAGONAL_METER);
    this.currentIndexSymbolWinIds++;
    if (this.currentIndexSymbolWinIds >= this.symboldWinIds.length) {
      this.hideSymbol();
    } else {
      await this.playAnimations();
    }
  }

  private playWinframeAnimation(): void {
    // for (let i: number = 0; i < this.winframeData.length; i++) {
    //   let singleWinframedata: winframeData = this.winframeData[i];
    //   let direction: number[] = singleWinframedata.direction;
    //   for (let i = 0; i < direction.length; i++) {
    //     let winlineContainer = (
    //       (
    //         this.winframeContainer.children[
    //         singleWinframedata.reelId
    //         ] as WinframeReelContainerFG
    //       ).children[singleWinframedata.rowId] as WinframeContainerFG
    //     ).winLineContainer;
    //     if (direction[i] > 0) {
    //       winlineContainer.children[i].visible = true;
    //     } else {
    //       winlineContainer.children[i].visible = false;
    //     }
    //   }
    // }
  }

  private createWinFrame(winData: Set<string>): void {
    this.winframeData = [];
    winData = this.sortArray(winData);
    winData.forEach((position) => {
      let reelRow: string[] = position.split(",");
      let direction: number[] = [];
      let leftN: string = [Number(reelRow[0]) - 1, Number(reelRow[1])].join(
        ","
      );
      winData.has(leftN) ? direction.push(-1) : direction.push(1);
      let rightN: string = [Number(reelRow[0]) + 1, Number(reelRow[1])].join(
        ","
      );
      winData.has(rightN) ? direction.push(-1) : direction.push(1);
      let topN: string = [Number(reelRow[0]), Number(reelRow[1]) - 1].join(",");
      winData.has(topN) ? direction.push(-1) : direction.push(1);
      let botttomN: string = [Number(reelRow[0]), Number(reelRow[1]) + 1].join(
        ","
      );
      winData.has(botttomN) ? direction.push(-1) : direction.push(1);
      let winFrameData: winframeData = {
        reelId: Number(reelRow[0]),
        rowId: Number(reelRow[1]),
        direction: direction,
      };
      this.winframeData.push(winFrameData);
    });
  }

  private hideSymbol(): Promise<void> {
    const promises: Promise<void>[] = [];
    let winGrid: Map<number, ISingleWinDetails> = CommonConfig.the.getWinGridFreeGame();
    for (const [key, value] of winGrid) {
      let winData: Set<string> = (value as ISingleWinDetails).index_set;
      winData.forEach((position) => {
        let reelRow: string[] = position.split(",");
        const reel = this.reelsContainer.children[Number(reelRow[0])] as FreegameReel;
        promises.push(reel.hideSymbolAnim(Number(reelRow[1])));
      });
    }
    return Promise.all(promises).then(() => {
      this.shuffleAndCascadeReel();
    });
  }

  private async shuffleAndCascadeReel(): Promise<void> {
    let winGrid: Map<number, ISingleWinDetails> = CommonConfig.the.getWinGridFreeGame();
    let winGridSet: Set<string> = new Set();
    let winReelData: { [key: number]: number[] } = {};
    for (let i: number = 0; i < this.symboldWinIds.length; i++) {
      let winData: Set<string> = winGrid.get(this.symboldWinIds[i])!.index_set;
      winData.forEach(position => {
        let reelRow: string[] = position.split(",");
        winGridSet.add(position);
        // console.log(reelRow);
        if (winReelData.hasOwnProperty(Number(reelRow[0]))) {
          winReelData[Number(reelRow[0])].push(Number(reelRow[1]));
        } else {
          winReelData[Number(reelRow[0])] = [Number(reelRow[1])];
        }
      });
    }
    let winReelids: number[] = [];
    const hidePromises: Promise<void>[] = [];
    Object.keys(winReelData).forEach(key => {
      const reelKey = parseInt(key, 10);
      winReelids.push(reelKey);
      const hidePromise = (this.reelsContainer.children[reelKey] as FreegameReel).playAfterHideCurrentSymbol(winReelData[reelKey].sort());
      hidePromises.push(hidePromise);
    });
    await Promise.all(hidePromises);
    CommonConfig.the.setWinReelIdsFreeGame(winReelids);
    let response: number[][] = CommonConfig.the.cascade(CommonConfig.the.getViewFreeGame(), winGridSet);
    this.updateView(response);
    const dropReelsPromise : Promise<void>[] = [];
        this.reelsContainer.children.forEach((reel, index) => {
          const dropReelPromise = (reel as FreegameReel).dropWinReel();
          dropReelsPromise.push(dropReelPromise)
        });
    await Promise.all(dropReelsPromise);
    Game.the.app.stage.emit(CommonConfig.FG_ON_SHOW_NEXT_WIN_PRESENTAION);
  }

  private initializeReelContainer(): void {
    this.reelsContainer = new Container();
    this.addChild(this.reelsContainer);
    for (let i: number = 0; i < CommonConfig.totalReel; i++) {
      const reel: FreegameReel = new FreegameReel(i);
      reel.position.set(CommonConfig.reelWidth * i, 0);
      this.reelsContainer.addChild(reel);
    }
  }

  private initializeWinframeContainer(): void {
    // this.winframeContainer = new Container();
    // this.addChild(this.winframeContainer);
    // for (let i: number = 0; i < CommonConfig.totalReel; i++) {
    //   const winframeReel: WinframeReelContainerFG = new WinframeReelContainerFG(i);
    //   winframeReel.position.set(CommonConfig.reelWidth * i, 0);
    //   this.winframeContainer.addChild(winframeReel);
    // }
  }

  private updateView(response: number[][]): void {
    CommonConfig.the.setViewFreeGame(response);
    this.reelsContainer.children.forEach((value, index) => {
      (value as FreegameReel).children.forEach((pos, posindex) => {
        let symbol = SymbolPool.the.getSymbol(
          CommonConfig.symbolIds[Number(response[index][posindex])]
        );
        (pos as FreeGamePos).getSymContainer().removeChildren();
        (pos as FreeGamePos).updatePosWithSym(symbol as StaticSymbol);
        (pos as FreeGamePos).zIndex = 1;
      });
    });
  }

  private setSymbolAtReel(): void {
    let response: number[][] = CommonConfig.the.generateRandomView();
    CommonConfig.the.setViewFreeGame(response);
    this.reelsContainer.children.forEach((reel, index) => {
      (reel as FreegameReel).children.forEach((pos, posindex) => {
        (pos as FreeGamePos).getSymContainer().removeChildren();
        if (CommonConfig.the.getInitial3x3WildGridId()[0] === index && CommonConfig.the.getInitial3x3WildGridId()[1] === posindex) {
          let symbol: StaticWild3x3 = SymbolPool.the.getSymbol(
            CommonConfig.symbolIds[10]
          ) as StaticWild3x3;
          (pos as FreeGamePos).updatePosWith3x3Wild(symbol);
          (pos as FreeGamePos).zIndex = 1;
        } else {
          if (!this.checkIsIndexInExpandingWild(index, posindex)) {
            let symbol = SymbolPool.the.getSymbol(
              CommonConfig.symbolIds[Number(response[index][posindex])]
            );
            (pos as FreeGamePos).updatePosWithSym(symbol as StaticSymbol);
            (pos as FreeGamePos).zIndex = 1;
          }
        }

      });
    });
  }

  private checkIsIndexInExpandingWild(reelId: number, posindex: number): boolean {
    let symbol3x3WildIds: number[][] = CommonConfig.the.get3x3WildGridIds()
    for (let i: number = 0; i < symbol3x3WildIds.length; i++) {
      if (symbol3x3WildIds[i][0] === reelId && symbol3x3WildIds[i][1] === posindex) {
        return true
      }
    }
    return false;
  }

  async spinTheReels(): Promise<void> {
    const spinPromises : Promise<void>[] = [];
    this.reelsContainer.children.forEach((reel, index) => {
      const spinPromise =  (reel as FreegameReel).spinTheReel();
      spinPromises.push(spinPromise);
    });
    await Promise.all(spinPromises);
    Game.the.app.stage.emit(CommonConfig.FG_SET_RESPONSE_AT_REEL);
    this.soundManager.play("SymbolDrop");
    const stopPromises : Promise<void>[] = [];
    this.reelsContainer.children.forEach((reel, index) => {
      const stopPromise =  (reel as FreegameReel).stopTheReel();
      stopPromises.push(stopPromise);
    });
    await Promise.all(stopPromises);
    Game.the.app.stage.emit(CommonConfig.FG_SPIN_STOPPED);
  }
}
