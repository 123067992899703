import { Assets, Container, Matrix, Point, Sprite, Spritesheet } from "pixi.js";
import { Game } from "../game";
import { Spine } from "@esotericsoftware/spine-pixi-v8";
import { CommonConfig } from "@/Common/CommonConfig";
import gsap from "gsap";
import SoundManager from "../Sound/SoundManager";

export class Character extends Container {
    characterBodyContainer!: Container;
    keyContainer !: Container;
    private imageBase !: Sprite;
    private imageBaseTexture !: Spritesheet;
    private characterSpine !: Spine;
    private lock1 !: Spine;
    private lock2 !: Spine;
    private lock3 !: Spine;
    private soundManager !: SoundManager;

    constructor(private state: string) {
        super();
        this.state = state;
        this.soundManager = SoundManager.getInstance();
        this.imageBaseTexture = Assets.get("feature_popup");
        this.init();
        Game.the.app.stage.on(CommonConfig.CHARACTER_HEAD_DISAPPEAR_ANIMATION, this.headDisappearAnimation, this);
        Game.the.app.stage.on(CommonConfig.CHARACTER_HEAD_APPEAR_ANIMATION, this.headAppearAnimation, this);
        Game.the.app.stage.on(CommonConfig.PLAY_LOCKER_ANIMATION, this.playLockerAnimation, this);
        Game.the.app.stage.on(CommonConfig.RESET_CHARACTER, this.resetCharacter, this);
        // this.resizeApp();
    }

    private init(): void {
        this.characterBodyContainer = new Container();
        this.addChild(this.characterBodyContainer);
        this.keyContainer = new Container();
        this.addChild(this.keyContainer);
        this.imageBase = new Sprite(this.imageBaseTexture.textures['characterPlatform.png']);
        this.characterBodyContainer.addChild(this.imageBase);
        this.characterSpine = Spine.from({ skeleton: "CharacterAnim_spine_data", atlas: "CharacterAnim_spine_atlas" });
        this.characterBodyContainer.addChild(this.characterSpine);
        this.lock1 = Spine.from({ skeleton: "Lock_key_spine_data", atlas: "Lock_key_spine_atlas" });
        this.characterBodyContainer.addChild(this.lock1);
        this.lock1.state.setAnimation(0, 'lock_static', false);
        this.lock2 = Spine.from({ skeleton: "Lock_key_spine_data", atlas: "Lock_key_spine_atlas" });
        this.characterBodyContainer.addChild(this.lock2);
        this.lock2.state.setAnimation(0, 'lock_static', false);
        this.lock3 = Spine.from({ skeleton: "Lock_key_spine_data", atlas: "Lock_key_spine_atlas" });
        this.characterBodyContainer.addChild(this.lock3);
        this.lock3.state.setAnimation(0, 'lock_static', false);

        this.lock1.scale.set(0.44);
        this.lock1.position.set(82.5, 416);
        this.lock2.scale.set(-0.44, 0.44);
        this.lock2.position.set(255, 490.5);
        this.lock3.scale.set(0.44);
        this.lock3.position.set(154.5, 545);
        this.characterSpine.scale.set(0.6);
        this.characterSpine.position.set(165, 518);
        this.characterSpine.state.setAnimation(0, 'idle', true);
        this.imageBase.position.set(-10, this.characterSpine.height * 0.9 - 10);
    }

    getLockGlobalPosition(): number[][] {
        let globalPosition: number[][] = [];
        // console.clear();

        console.log(this.lock1.globalDisplayStatus);
        globalPosition.push([this.lock1.worldTransform.tx, this.lock1.worldTransform.ty]);
        globalPosition.push([this.lock2.worldTransform.tx, this.lock2.worldTransform.ty]);
        globalPosition.push([this.lock3.worldTransform.tx, this.lock3.worldTransform.ty]);
        return globalPosition;
    }

    private headDisappearAnimation(): void {
        if (this.state !== CommonConfig.the.getCurrentState()) {
            return;
        }
        this.characterSpine.state.setAnimation(0, "Head_disappear_On", false);
        this.characterSpine.state.addListener({
            complete: () => {
                this.characterSpine.state.clearListeners()
                this.characterSpine.state.tracks = [];
                this.characterSpine.state.setAnimation(0, "Head_disappear_Loop", true);
            },
        });
    }

    private headAppearAnimation(): void {
        if (this.state !== CommonConfig.the.getCurrentState()) {
            return;
        }
        this.characterSpine.state.setAnimation(0, "Head_disappear_Off", false);
        this.characterSpine.state.addListener({
            complete: () => {
                this.characterSpine.state.clearListeners()
                this.characterSpine.state.tracks = [];
                this.characterSpine.state.setAnimation(0, "idle", true);
            },
        });
    }

    private playLockerAnimation(musicalNoteType: number): void {
        if (this.state !== CommonConfig.the.getCurrentState()) {
            return;
        }
        this.soundManager.stop('lockermusic1');
        this.soundManager.play('lockersfx');
        if (musicalNoteType === 1) {
            this.lock1.state.setAnimation(0, "lock_key_1", false);
        } else if (musicalNoteType === 2) {
            this.lock2.state.setAnimation(0, "lock_key_2", false);
        } else if (musicalNoteType === 3) {
            this.lock3.state.setAnimation(0, "lock_key_3", false);
        }
        if(CommonConfig.the.getUnlockedLockCounter() >= 3){
            gsap.delayedCall(0.6, () => {
                this.characterSpine.state.setAnimation(0, "shaking", false);
                this.characterSpine.state.addListener({
                    complete: () => {
                        this.lock1.visible = false;
                        this.lock2.visible = false;
                        this.lock3.visible = false;
                        this.characterSpine.state.clearListeners()
                        this.characterSpine.state.tracks = [];
                        this.characterSpine.state.setAnimation(0, "chain_break_and_roar_updated", false);
                        this.characterSpine.state.addListener({
                            complete: () => {
                                this.characterSpine.state.clearListeners()
                                this.characterSpine.state.tracks = [];
                                this.characterSpine.visible = false;
                                Game.the.app.stage.emit(CommonConfig.UPDATE_WILD_AFTER_KEY_ANIMATION);
                            },
                        });
                    },
                });
            });
        }else{
            Game.the.app.stage.emit(CommonConfig.UPDATE_WILD_AFTER_KEY_ANIMATION);
        }
    }

    private resetCharacter() :void{
        if (this.state !== CommonConfig.the.getCurrentState()) {
            return;
        }
        this.characterSpine.visible = true;
        this.lock1.visible = true;
        this.lock2.visible = true;
        this.lock3.visible = true;
        this.lock1.state.setAnimation(0, 'lock_static', false);
        this.lock2.state.setAnimation(0, 'lock_static', false);
        this.lock3.state.setAnimation(0, 'lock_static', false);
        this.characterSpine.state.setAnimation(0, 'idle', true);
    }



}