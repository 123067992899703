import { Container } from "pixi.js";
import { BaseGame } from "./Basegame";
import { FreeGame } from "./Freegame";
import { Game } from "../game";
import { CommonConfig } from "@/Common/CommonConfig";
import { FreeGameIntro } from "../FreegameIntro/FreeGameIntro";

export class StateManagement extends Container{
    private baseGame !: BaseGame;
    private freeGame !: FreeGame;
    private freeGameContainer !: Container;
    private freeGameIntro !: FreeGameIntro;

    constructor(){
        super();
        this.init();
        this.addContainerToStage();
        this.hideShowState(true);
        Game.the.app.stage.on(CommonConfig.SHOW_HIDE_BASEGAME, this.hideShowState, this);
        Game.the.app.stage.on(CommonConfig.START_BONUS, this.startBonus, this);
        Game.the.app.stage.on(CommonConfig.HIDE_BONUS, this.hideBonus, this);
        Game.the.app.stage.on(CommonConfig.AFTER_INTRO_HIDE, this.afterIntroHidden, this);
        this.resize();
        Game.the.app.stage.on("RESIZE_THE_APP", this.resize, this);
    }

    private afterIntroHidden() :void{
       
    }

    private hideBonus() :void{
        Game.the.app.stage.emit(CommonConfig.STOP_FREEGAME_BG_SOUND);
        this.freeGameContainer.removeChildren(0,this.freeGameContainer.children.length);
        CommonConfig.the.setIsRandomFeatureState(false);
        CommonConfig.the.setIsFGRandomFeatureState(false);
        Game.the.app.stage.emit(CommonConfig.RESET_CHEAT_PANEL);
        this.hideShowState(true);
        Game.the.app.stage.emit(CommonConfig.PLAY_BG_SOUND);
        Game.the.app.stage.emit(CommonConfig.UPDATE_WIN_METER);
        Game.the.app.stage.emit(CommonConfig.ON_SHOW_NEXT_WIN_PRESENTAION);
    }

    private initFreeGameIntro() :void{
        this.freeGameIntro = new FreeGameIntro();
    }

    private startBonus() :void{
        CommonConfig.the.setUnlockedLockCounter(0);
        Game.the.app.stage.emit(CommonConfig.STOP_BG_SOUND);
        CommonConfig.the.setCurrentState(CommonConfig.FREE_Game);
        this.freeGame = new FreeGame();
        this.freeGameContainer.addChild(this.freeGame);
        Game.the.app.stage.emit(CommonConfig.FG_UPDATE_BALANCE_TEXT);
        CommonConfig.the.setIsRandomFeatureState(false);
        Game.the.app.stage.emit(CommonConfig.RESET_CHEAT_PANEL);
        this.hideShowState(false);
    }

    private init(){
        this.baseGame = new BaseGame();
        this.freeGameContainer = new Container();
        this.initFreeGameIntro();
    }

    private addContainerToStage(){
        this.addChild(this.baseGame);
        this.addChild(this.freeGameContainer);
        this.addChild(this.freeGameIntro);
    }

    private hideShowState(isBaseGame : boolean) :void{
        isBaseGame ? this.showBaseGame() : this.showFreeGame();
    }

    private showBaseGame() :void{
        this.baseGame.visible = true;
        this.freeGameContainer.visible = false;
        CommonConfig.the.setCurrentState(CommonConfig.BASE_GAME);
        Game.the.app.stage.emit(CommonConfig.UPDATE_BALANCE_TEXT);
        Game.the.app.stage.emit(CommonConfig.INIT_BASEGAME);
    }

    private showFreeGame() :void{
        this.baseGame.visible = false;
        this.freeGameContainer.visible = true;
        CommonConfig.the.setCurrentState(CommonConfig.FREE_Game);
        Game.the.app.stage.emit(CommonConfig.INIT_FREEGAME);
        // this.freeGame = new FreeGame();
        // this.freeGameContainer.addChild(this.freeGame);
    }

    private resize() :void{
        this.freeGameIntro.scale.set(0.7);
        this.freeGameIntro.position.set(395,158);
        if (window.innerWidth < window.innerHeight) {
            this.freeGameIntro.scale.set(0.6);
            this.freeGameIntro.position.set(-131,395.5);
        }
    }


}