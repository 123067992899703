import { Container } from "pixi.js";
import { Spine } from "@esotericsoftware/spine-pixi-v8";

export class SingleWinLine extends Container {
    private lineSpineInstance!: Spine;
    constructor() {
        super();
        this.initializeLine();
        this.addLineToStage();
    }

    private initializeLine(): void {
        this.lineSpineInstance = Spine.from({ skeleton: "paylineMatchAnimation_data", atlas: "paylineMatchAnimation_atlas" });
        this.lineSpineInstance.scale.set(0.3);
        this.lineSpineInstance.position.set(0,5.5);
        this.lineSpineInstance.visible = false;
        // this.lineSpineInstance.state.setAnimation(0, 'animation', true);
        // this.lineSpineInstance.position.set(-(this.lineSpineInstance.width) / 2, -(this.lineSpineInstance.height) / 2);
    }

    private addLineToStage(): void {
        this.addChild(this.lineSpineInstance);
    }

    updatePosition(is3x3Wild : boolean){
        if(is3x3Wild){
            this.lineSpineInstance.scale.set(0.9);
            this.lineSpineInstance.position.set(193,179);
        }else{
            this.lineSpineInstance.scale.set(0.3);
            this.lineSpineInstance.position.set(0,5.5);
        }
    }

    playAnimation(): void {
        this.lineSpineInstance.visible = true;
        this.lineSpineInstance.state.setAnimation(0, 'payline_match', false);
        this.lineSpineInstance.state.addListener({
            complete: () => {
                this.lineSpineInstance.visible = false;
                this.lineSpineInstance.state.clearListeners();
            },
        });
    }

    hideAnimation() :void{
        this.lineSpineInstance.visible = false;
        this.lineSpineInstance.state.clearListeners();
    }
}