import { Container } from "pixi.js";
import { BackgroundView } from "../Background/BackgroundView";
import { ReelView } from "../Background/ReelView";
import { ReelManager } from "../BaseGame/ReelManager";
import { SpinButton } from "../Button/SpinButton";
import { Game } from "../game";
import { WinpresentationController } from "../BaseGame/WinpresentationController";
import { CheatPanel } from "../CheatPanel/CheatPanel";
import { BottomPanel } from "../BottomPanel/BottomPanel";
import { BigWinMeter } from "../Meter/BigWinMeter";
import { LineMeter } from "../Meter/LineMeter";
import { PentagonalUpdateFeature } from "../FeatureComponent/PentagonalUpdateFeature";
import { Character } from "../Character/Character";
import { CommonConfig } from "@/Common/CommonConfig";
import { RandomFeaturePopup } from "../FeatureComponent/RandomFeatureZwoom";
import { ReelFrame } from "../Background/ReelFrame";
import { ZwoomFeatureContainer } from "../FeatureComponent/ZwoomFeatureContainer";
import { ShootingFxAnimation } from "../FeatureComponent/ShootingFxAnimation";
import { Logo } from "../Logo/Logo";

export class BaseGame extends Container {
    private backgroundView !: BackgroundView;
    private reelView !: ReelView; 
    private reelManager !: ReelManager;
    private reelContainer !: Container;
    private bottomPanelButton !: Container;
    private winpresentationController !: WinpresentationController;
    private cheatPanel !: CheatPanel;
    private normalRation: number = 1920 / 919;
    private aspectRatioMobile: number = 0;
    private bottomPanel !: BottomPanel;
    private bgWinMeter !: BigWinMeter;
    private aspectRatio: number = 0;
    private lineMeter !: LineMeter;
    private pentagonalUpdateFeature !: PentagonalUpdateFeature;
    private character !: Character;
    private randomFeaturePopup !: RandomFeaturePopup;
    private reelFrame !: ReelFrame;
    private zwoomFeatureContainer !: Container;
    private shootingFx !: ShootingFxAnimation;
    private logo !: Logo; 


    constructor() {
        super();
        this.init();
        this.addContainerToStage();
        this.setPosition();
        this.resizeApp();
        this.subscribeEvent();
        Game.the.app.stage.on("RESIZE_THE_APP", this.resizeApp, this);
    }

    private subscribeEvent(): void {
        // window.addEventListener("keydown", (event) => {
        //     if (event.key === "c") { // Press 'C' to toggle the cheat panel
        //         this.cheatPanel.visible = !this.cheatPanel.visible;
        //     }
        // });
    }

    private init() {
        this.initBackground();
        this.parentContainer();
        this.initReelView();
        this.initReelManager();
        this.initWinpresentationController();
        this.initializeCheatPanel();
        this.initBottomPanel();
        this.initBigWinMeter();
        this.initLineMeter();
        this.initpentagonalUpdateFeature();
        this.initCharacter();
        this.initRandomFeaturePopup();
        this.initZwoomFeature();
        this.initShootingFx();
        this.initLogo();
    }

    private initLogo() :void{
        this.logo = new Logo(CommonConfig.BASE_GAME);
    }

    private initCharacter(): void {
        this.character = new Character(CommonConfig.BASE_GAME);
    }

    private parentContainer(): void {
        this.reelContainer = new Container();
        this.bottomPanelButton = new Container();
    }

    private initShootingFx() :void{
        this.shootingFx = new ShootingFxAnimation(CommonConfig.BASE_GAME);
    }

    private initBackground() {
        this.backgroundView = new BackgroundView();
    }

    private initRandomFeaturePopup() {
        this.randomFeaturePopup = new RandomFeaturePopup(CommonConfig.BASE_GAME);
    }

    private initReelView() {
        this.reelView = new ReelView();
        this.reelFrame = new ReelFrame();
    }

    private initReelManager() {
        this.reelManager = new ReelManager();
    }

    private initZwoomFeature() {
        this.zwoomFeatureContainer = new ZwoomFeatureContainer(CommonConfig.BASE_GAME);
    }

    private initWinpresentationController(): void {
        this.winpresentationController = new WinpresentationController();
    }

    private initBigWinMeter(): void {
        this.bgWinMeter = new BigWinMeter(CommonConfig.BASE_GAME);
    }

    private initLineMeter(): void {
        this.lineMeter = new LineMeter(CommonConfig.BASE_GAME);
    }

    private initializeCheatPanel(): void {
        this.cheatPanel = new CheatPanel();
        this.cheatPanel.position.set(50, 50); // Position the panel in the top-left corner
    }

    private initBottomPanel(): void {
        this.bottomPanel = new BottomPanel(CommonConfig.BASE_GAME);
    }

    private initpentagonalUpdateFeature(): void {
        this.pentagonalUpdateFeature = new PentagonalUpdateFeature(CommonConfig.BASE_GAME);
    }

    private addContainerToStage() {
        this.addChild(this.backgroundView);
        this.addChild(this.reelContainer);
        this.reelContainer.addChild(this.reelView);
        this.reelContainer.addChild(this.reelManager);
        this.reelContainer.addChild(this.reelFrame);
        this.reelContainer.addChild(this.lineMeter);
        this.addChild(this.character);
        this.addChild(this.logo);
        this.addChild(this.bottomPanelButton);
        this.addChild(this.cheatPanel);
        this.addChild(this.bottomPanel);
        this.addChild(this.pentagonalUpdateFeature);
        this.addChild(this.bgWinMeter);
        this.addChild(this.randomFeaturePopup);
        this.addChild(this.shootingFx);
        this.addChild(this.zwoomFeatureContainer);
    }

    private setPosition() {
        this.reelContainer.position.set((1920 - this.reelView.width) / 2, (913 - this.reelView.height) / 2 - 100);
        // this.reelManager.scale.set(0.25);
        this.reelManager.position.set(154, 172.5);
        this.lineMeter.position.set(154,  172.5);
        this.reelContainer.scale.set(1);
        this.aspectRatio = this.reelContainer.height / 913;
        this.aspectRatioMobile = this.reelContainer.width / 360;
        // this.bottomPanelButton.position.set(0, (913 - this.bottomPanelButton.height));
    }

    private resizeApp(): void {
        this.reelContainer.scale.set(0.85);
        this.reelContainer.position.set(506.5, -36);
        this.randomFeaturePopup.featureContainer?.scale.set(1);
        this.randomFeaturePopup.featureContainer?.position.set(945.654, 505.963);
        this.zwoomFeatureContainer.scale.set(0.77);
        this.zwoomFeatureContainer.position.set(1604.45, 252.5);
        this.shootingFx?.updateLeftRightContainerPosition(0.8,this.reelContainer.x, this.reelContainer.height);
        this.bgWinMeter.position.set(0,0);
        if (window.innerWidth < window.innerHeight) {
            this.reelContainer.scale.set(0.85);
            this.reelContainer.position.set(-115, 138.881);
            this.bgWinMeter.position.set(-630,76.5);
            this.zwoomFeatureContainer.position.set(640, 1003);
            this.randomFeaturePopup.featureContainer?.position.set(333.654, 746.463);
            this.shootingFx?.updateLeftRightContainerPosition(0.8,-85.5, this.reelContainer.height);
        }
        this.resizePentagonal();
        this.resizeCharacter();
    }

    private resizePentagonal(): void {
        this.pentagonalUpdateFeature.scale.set(1);
        this.pentagonalUpdateFeature.position.set(38.5, 303);
        if (window.innerWidth < window.innerHeight) {
            this.pentagonalUpdateFeature.scale.set(0.9);
            this.pentagonalUpdateFeature.position.set(-92.5, 1048.25);
        }
    }

    private resizeCharacter(): void {
        this.character.characterBodyContainer.scale.set(0.9);
        this.character.keyContainer.scale.set(0.9);
        this.character.characterBodyContainer.position.set(1441,52);
        this.character.keyContainer.position.set(this.character.characterBodyContainer.x - this.character.keyContainer.width - 52, -2);
        if (window.innerWidth < window.innerHeight) {
            this.character.characterBodyContainer.scale.set(0.8);
            this.character.characterBodyContainer.position.set(506, 892);
        }
    }
}