import { Container } from "pixi.js";
import { StaticSymbol } from "../Symbol/StaticSymbol";
import { CommonConfig } from "@/Common/CommonConfig";
import { Game } from "../game";
import { Spine } from "@esotericsoftware/spine-pixi-v8";
import gsap from "gsap";
import { SingleWinLine } from "../Winframe/SingleWinLine";


export class Pos extends Container {
    symContainer !: Container;
    private blastAnimation !: Spine;
    private replacementAnimation !: Spine;
    private winlineAnim !: SingleWinLine;
    private isToReplacedSymbol: boolean = false;

    constructor() {
        super();
        this.symContainer = new Container();
        this.addChildren();
        this.blastAnimation = Spine.from({ skeleton: "Symbol_Replace_FX_spine_data", atlas: "Symbol_Replace_FX_spine_atlas" });
        this.addChild(this.blastAnimation);
        this.replacementAnimation = Spine.from({ skeleton: "Symbol_Replace_V2_spine_data", atlas: "Symbol_Replace_V2_spine_atlas" });
        this.addChild(this.replacementAnimation);
        this.winlineAnim = new SingleWinLine();
        this.addChild(this.winlineAnim);
        this.blastAnimation.scale.set(1.2);
        this.blastAnimation.visible = false;
        this.replacementAnimation.scale.set(0.5);
        this.replacementAnimation.visible = false;
        Game.the.app.stage.on(CommonConfig.SPIN_STOPPED, this.onSpinStopped, this);
        Game.the.app.stage.on(CommonConfig.START_SPIN, this.removeDark, this);
        Game.the.app.stage.on(CommonConfig.DARKEN_SYMBOL, this.darkenSymbol, this);
    }

    private darkenSymbol(): void {
        this.alpha = 0.35;
    }

    private removeDark(): void {
        this.alpha = 1;
        this.symContainer.alpha = 1;
    }


    private onSpinStopped(): void {
        this.alpha = 1;
        this.symContainer.alpha = 1;
    }

    private addChildren(): void {
        this.addChild(this.symContainer);
    }

    public getSymContainer(): Container {
        return this.symContainer;
    }

    updatePosWithSym(sym: StaticSymbol): void {
        // this.addChildren();
        if (this.symContainer.children.length) {
            this.symContainer.removeChildren();
            this.symContainer.addChild(sym);
        } else {
            this.symContainer.addChild(sym);
        }
    }

    replacePosWithSym(sym: StaticSymbol): void {
        
        gsap.delayedCall(0.2,()=>this.replace(sym));
        this.replacementAnimation.visible = true;
        this.replacementAnimation.state.setAnimation(0, 'animation', false);
        this.replacementAnimation.state.addListener({
            complete: () => {
                this.replacementAnimation.visible = false;
                this.replacementAnimation.state.clearListeners();
            },
        });
    }

    private replace(sym: StaticSymbol): void {
        if (this.symContainer.children.length) {
            this.symContainer.removeChildren();
            this.symContainer.addChild(sym);
        } else {
            this.symContainer.addChild(sym);
        }
    }

    updateIsToReplacedSymbol(isBool: boolean): void {
        this.isToReplacedSymbol = isBool;
    }

    updateSymbolContainerPosition(x: number, y: number): void {
        this.symContainer.position.set(x, y);
    }

    playAnimation(): Promise<void> {
        this.winlineAnim.playAnimation();
        return new Promise<void>((resolve) => {
            this.alpha = 1;
            this.symContainer.alpha = 1;
            if (this.symContainer.children.length > 0) {
                const staticSymbol = this.symContainer.children[0] as StaticSymbol;
                staticSymbol.playSpineAnimation().then(() => {
                    resolve();
                    this.winlineAnim.hideAnimation();
                });
            } else {
                resolve();
            }
        });
    }

    playLanding(): Promise<void> {
        return new Promise<void>((resolve) => {
            if (this.symContainer.children.length > 0) {
                const staticSymbol = this.symContainer.children[0] as StaticSymbol;
                staticSymbol.playLandingAnimation().then(() => {
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    hideAnimation(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.blastAnimation.visible = true;
            this.blastAnimation.state.setAnimation(0, 'animation', false);
            this.blastAnimation.state.addListener({
                complete: () => {
                    this.blastAnimation.visible = false;
                    resolve();
                },
            });
        });
    }

}