import { Howl } from 'howler';

class SoundManager {
  private static instance: SoundManager;
  private sounds: Record<string, Howl>;
  private isMuted: boolean= false;

  private constructor() {
    const bigWinWavSoundPath : string = "./audio/bigWinSound/wav/";
    const bigWinM4aSoundPath : string = "./audio/bigWinSound/m4a/";
    const randomwFeatureSoundPath : string = "./audio/RandomFeature/";
    const zwoomSoundPath : string = "./audio/zwoom/";
    const audioPath : string = "./audio/";
    const lockerSoundPath : string = "./audio/locker/";

    this.sounds = {
      background: new Howl({
        src: ['./audio/Bonusmusic.mp3'],
        volume: 0.5,
        loop: true,
      }),
      bonusmusic: new Howl({
        src: ['./audio/bonusmusic.wav', './audio/bonusmusic.m4a'],
        volume: 0.5,
        loop: true,
      }),
      allWinBgSound: new Howl({
        src: [`${bigWinWavSoundPath}All_win_music.wav`, `${bigWinM4aSoundPath}All_win_music.m4a`],
        volume: 1,
        loop: false,
      }),
      Big_Win_Sfx: new Howl({
        src: [`${bigWinWavSoundPath}Big_Win_Sfx.wav`, `${bigWinM4aSoundPath}Big_Win_Sfx.m4a`],
        volume: 1,
        loop: false,
      }),
      Coin_Sfx: new Howl({
        src: [`${bigWinWavSoundPath}Coin_Sfx.wav`, `${bigWinM4aSoundPath}Coin_Sfx.m4a`],
        volume: 1,
        loop: true,
      }),
      Mega_Win_Sfx: new Howl({
        src: [`${bigWinWavSoundPath}Mega_Win_Sfx.wav`, `${bigWinM4aSoundPath}Mega_Win_Sfx.m4a`],
        volume: 1,
        loop: false,
      }),
      Over_The_Top_win_Sfx: new Howl({
        src: [`${bigWinWavSoundPath}Over_The_Top_win_Sfx.wav`, `${bigWinM4aSoundPath}Over_The_Top_win_Sfx.m4a`],
        volume: 1,
        loop: false,
      }),
      Top_win_Sfx: new Howl({
        src: [`${bigWinWavSoundPath}Top_win_Sfx.wav`, `${bigWinM4aSoundPath}Top_win_Sfx.m4a`],
        volume: 1,
        loop: false,
      }),
      Win_Sfx: new Howl({
        src: [`${bigWinWavSoundPath}Win_Sfx.wav`, `${bigWinM4aSoundPath}Win_Sfx.m4a`],
        volume: 1,
        loop: false,
      }),
      Crepazione_popup: new Howl({
        src: [`${randomwFeatureSoundPath}Crepazione.wav`, `${randomwFeatureSoundPath}Crepazione.m4a`],
        volume: 1,
        loop: false,
      }),
      Pistole_popup: new Howl({
        src: [`${randomwFeatureSoundPath}Pistole.wav`, `${randomwFeatureSoundPath}Pistole.m4a`],
        volume: 1,
        loop: false,
      }),
      ZWOOM_popup: new Howl({
        src: [`${randomwFeatureSoundPath}ZWOOM.wav`, `${randomwFeatureSoundPath}ZWOOM.m4a`],
        volume: 1,
        loop: false,
      }),
      SpinVariation: new Howl({
        src: [`${audioPath}SpinVariation.wav`, `${audioPath}SpinVariation.m4a`],
        volume: 1,
        loop: false,
      }),
      Symbolmatch: new Howl({
        src: [`${audioPath}Symbolmatch.wav`, `${audioPath}Symbolmatch.m4a`],
        volume: 1,
        loop: false,
      }),
      SymbolCascade: new Howl({
        src: [`${audioPath}SymbolCascade.wav`, `${audioPath}SymbolCascade.m4a`],
        volume: 1,
        loop: false,
      }),
      SymbolDrop: new Howl({
        src: [`${audioPath}SymbolDrop.wav`, `${audioPath}SymbolDrop.m4a`],
        volume: 1,
        loop: false,
      }),
      SymbolReplace: new Howl({
        src: [`${audioPath}SymbolReplace.wav`, `${audioPath}SymbolReplace.m4a`],
        volume: 1,
        loop: false,
      }),
      firingsound: new Howl({
        src: [`${zwoomSoundPath}firingsound.wav`, `${zwoomSoundPath}firingsound.m4a`],
        volume: 1,
        loop: false,
      }),
      headattach: new Howl({
        src: [`${zwoomSoundPath}headattach.wav`, `${zwoomSoundPath}headattach.m4a`],
        volume: 1,
        loop: false,
      }),
      headdetach: new Howl({
        src: [`${zwoomSoundPath}headdetach.wav`, `${zwoomSoundPath}headdetach.m4a`],
        volume: 1,
        loop: false,
      }),
      headspin: new Howl({
        src: [`${zwoomSoundPath}headspin.wav`, `${zwoomSoundPath}headspin.m4a`],
        volume: 1,
        loop: false,
      }),
      lockermusic1: new Howl({
        src: [`${lockerSoundPath}lockermusic1.wav`, `${lockerSoundPath}lockermusic1.m4a`],
        volume: 1,
        loop: false,
      }),
      lockermusic2: new Howl({
        src: [`${lockerSoundPath}lockermusic2.wav`, `${lockerSoundPath}lockermusic2.m4a`],
        volume: 1,
        loop: false,
      }),
      lockermusic3: new Howl({
        src: [`${lockerSoundPath}lockermusic3.wav`, `${lockerSoundPath}lockermusic3.m4a`],
        volume: 1,
        loop: false,
      }),
      lockersfx: new Howl({
        src: [`${lockerSoundPath}lockersfx.wav`, `${lockerSoundPath}lockersfx.m4a`],
        volume: 1,
        loop: false,
      }),
      sfxsweep: new Howl({
        src: [`${lockerSoundPath}sfxsweep.wav`, `${lockerSoundPath}sfxsweep.m4a`],
        volume: 1,
        loop: false,
      }),
    };
  }

  public static getInstance(): SoundManager {
    if (!SoundManager.instance) {
      SoundManager.instance = new SoundManager();
    }
    return SoundManager.instance;
  }

  public play(soundName: string): void {
    if (this.sounds[soundName]) {
      this.sounds[soundName].play();
    } else {
      console.error(`Sound "${soundName}" not found.`);
    }
  }

  public stop(soundName: string): void {
    if (this.sounds[soundName]) {
      this.sounds[soundName].stop();
    } else {
      console.error(`Sound "${soundName}" not found.`);
    }
  }

  public getSound(soundName: string): Howl | undefined {
    return this.sounds[soundName];
  }

  public muteAll(): void {
    this.isMuted = true;
    Howler.mute(true);
  }

  public unmuteAll(): void {
    this.isMuted = false;
    Howler.mute(false);
  }

  public toggleMute(): void {
    this.isMuted = !this.isMuted;
    Howler.mute(this.isMuted);
  }

  public isSoundMuted(): boolean {
    return this.isMuted;
  }
}

export default SoundManager;
