import { Assets, Container, Sprite } from "pixi.js";

export class ReelView extends Container{
    private reelBg! : Sprite;
    private logo !: Sprite;

    constructor(){
        super();
        this.initialiZeReelView();
        this.addContainerToStage();
        this.setPosition();
    }

    private initialiZeReelView() : void{
       this.reelBg = new Sprite(Assets.get("reelBgImage")); 
       this.reelBg.scale.set(1.15);
       this.reelBg.position.set(34.5,36);
    }

    private setPosition() :void{
    }

    private addContainerToStage() {
        this.addChild(this.reelBg);
    }
}