import { Assets, Container, Graphics, Sprite, Spritesheet, Text, TextStyle } from "pixi.js";
import { Game } from "../game";
import { CommonConfig } from "@/Common/CommonConfig";
import { Spine } from "@esotericsoftware/spine-pixi-v8";

export class FreeGameLeftMeter extends Container {
    private freeSpinsLeftValue: number = 0;
    private freeGameLeftMeterSpine !: Spine;
    private starSpine !: Spine;
    private pentagonalFontTexture !: Spritesheet;
    private pentagonalMeter !: Container;
    private newBox !: Container;

    constructor() {
        super();
        this.init();
        this.updateWinAmount();
        this.addToStage();
        Game.the.app.stage.on(CommonConfig.UPDATE_FREEGAME_LEFT_METER, this.updateWinAmount, this);
    }

    private init(): void {
        this.freeGameLeftMeterSpine = Spine.from({ skeleton: "freespinscount_spine_data", atlas: "freespinscount_spine_atlas" });
        this.addChild(this.freeGameLeftMeterSpine);
        this.freeGameLeftMeterSpine.state.setAnimation(0, 'free_spins', true);

        this.starSpine = Spine.from({ skeleton: "freespinscount_spine_data", atlas: "freespinscount_spine_atlas" });
        this.starSpine.state.setAnimation(0, 'star', true);
        this.pentagonalFontTexture = Assets.get("freespinleftfont");
        this.pentagonalMeter = new Container();
        this.newBox = new Container();
        const graphics: Graphics = new Graphics().rect(0, 0, 100, 65.5).fill("#000000");
        graphics.alpha = 0;
        this.newBox.addChild(graphics);
        this.pentagonalMeter.scale.set(0.95);
        this.setNumber('3');
        this.updatePentagonalMeterPosition();
        this.newBox.position.set(-53,-35);
    }

    private updatePentagonalMeterPosition() :void{
            this.pentagonalMeter.position.set(((this.newBox.width - this.pentagonalMeter.width) * 0.5),0);
        }
    
        private setNumber(value: string) {
            this.pentagonalMeter.removeChildren(0, this.pentagonalMeter.children.length)
            const digits = value.split("");
            let xOffset = 0;
            digits.forEach((digit) => {
                const digitSprite = new Sprite(this.pentagonalFontTexture.textures[`${digit}.png`]);
                digitSprite.x = xOffset;
                this.addChild(digitSprite);
                this.pentagonalMeter.addChild(digitSprite);
                xOffset += digitSprite.width * 0.7; // Adjust spacing
            });
        }

    private addToStage(): void {
        this.addChild(this.newBox);
        this.newBox.addChild(this.pentagonalMeter);
        this.addChild(this.starSpine);
    }

    private updateWinAmount(): void {
        this.freeSpinsLeftValue = CommonConfig.the.getFreeSpinsLeftValue();
        // this.freeSpinsLeftValue = 10 - this.freeSpinsLeftValue;
        this.setNumber(this.freeSpinsLeftValue.toString());
        this.updatePentagonalMeterPosition();
        this.newBox.y = -52;
    }
}