import { Assets, Container, Sprite, Spritesheet } from "pixi.js";
import { CommonConfig } from "../../Common/CommonConfig";
import gsap from "gsap";
import { Game } from "../game";
import { StaticSymbol } from "../Symbol/StaticSymbol";
import { Pos } from "./Pos";

export class Reel extends Container {
    private pos_00 !: Pos;
    private pos_01 !: Pos;
    private pos_02 !: Pos;
    private pos_03 !: Pos;
    private pos_04 !: Pos;
    private reelId: number = 0;
    private delayStart: number = 0.05;
    private spinClicked: boolean = false;
    private maxPosition = CommonConfig.symbolHeight * 6;
    private speed: number = this.maxPosition / 0.4;
    private minPosition = -CommonConfig.symbolHeight;
    private positions: number[] = [CommonConfig.symbolHeight * 0, CommonConfig.symbolHeight * 1,
    CommonConfig.symbolHeight * 2, CommonConfig.symbolHeight * 3, CommonConfig.symbolHeight * 4];
    private delays: number[] = [0.05, 0.025, 0.015, 0.03, 0.022, 0.01];
    private noOfTimeWinCount: number = 0;
    private notAffectedContainer: string[] = [];
    private isFastReel : boolean = true;
    


    constructor(reelId: number) {
        super();
        this.reelId = reelId;
        this.init();
        Game.the.app.stage.on(CommonConfig.PLAY_STOP_SPIN, this.stopTheReel, this);
        Game.the.app.stage.on(CommonConfig.PLAY_SHUFFLE_REEL, this.playAfterHideCurrentSymbol, this);
    }

    

    private init(): void {
        this.pos_00 = new Pos();
        this.pos_00.position.set(0, CommonConfig.symbolHeight * 0);
        this.pos_00.name = 'pos_00';
        this.addChild(this.pos_00);

        this.pos_01 = new Pos();
        this.pos_01.position.set(0, CommonConfig.symbolHeight * 1);
        this.pos_01.name = 'pos_01';
        this.addChild(this.pos_01);

        this.pos_02 = new Pos();
        this.pos_02.position.set(0, CommonConfig.symbolHeight * 2);
        this.pos_02.name = 'pos_02';
        this.addChild(this.pos_02);

        this.pos_03 = new Pos();
        this.pos_03.position.set(0, CommonConfig.symbolHeight * 3);
        this.pos_03.name = 'pos_03';
        this.addChild(this.pos_03);

        this.pos_04 = new Pos();
        this.pos_04.position.set(0, CommonConfig.symbolHeight * 4);
        this.pos_04.name = 'pos_04';
        this.addChild(this.pos_04);
    }

    private playPosGsap(pos: Container, i: number): Promise<void> {
        return new Promise((resolve) => {
            let distance = this.maxPosition - pos.y;
            let time = this.calculateTime(distance);
    
            gsap.to(pos, {
                duration: time,
                y: this.maxPosition,
                delay: (CommonConfig.symbolsPerReel - i) * this.delayStart,
                ease: "power1.in",
                onComplete: () => {
                    this.resetPositions(pos, i).then(resolve);
                }
            });
        });
    }



    private resetPositions(pos: Container, i: number): Promise<void> {
        return new Promise((resolve) => {
            let y_pos: number = this.minPosition - ((CommonConfig.symbolsPerReel - i) * CommonConfig.symbolHeight);
            pos.position.set(pos.x, y_pos);
    
            if (i === 0 && this.reelId === CommonConfig.totalReel - 1) {
                resolve();
            } else {
                resolve();
            }
        });
    }

    private calculateTime(distance: number): number {
        if(this.isFastReel){
            this.speed = this.maxPosition / 0.2;
        }else{
            this.speed = this.maxPosition / 0.4;
        }
        return distance / this.speed;
    }

    stopTheReel(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this.isFastReel) {
                let promises: Promise<void>[] = [];
                this.children.forEach((value, index) => {
                    promises.push(this.playStopPosGsap(value, index));
                });
    
                Promise.all(promises)
                    .then(() => resolve()) // Explicitly calling resolve() without arguments
                    .catch(reject);
            } else {
                let delay = this.delays[Math.floor(Math.random() * this.delays.length)];
                gsap.delayedCall(delay, () => {
                    let promises: Promise<void>[] = [];
                    this.children.forEach((value, index) => {
                        promises.push(this.playStopPosGsap(value, index));
                    });
    
                    Promise.all(promises)
                        .then(() => resolve()) // Explicitly calling resolve() without arguments
                        .catch(reject);
                });
            }
        });
    }

    private resetAfterStop(pos: Container, i: number): Promise<void> {
        let duration : number = 0.25;
        return new Promise<void>((resolve, reject) => {
            gsap.to(pos, {
                duration: 0.1,
                y: this.positions[i] - 20,
                ease: "power1.out"
            }).then(() => {
                return gsap.to(pos, {
                    duration: duration,
                    y: this.positions[i],
                    ease: "power1.out"
                });
            }).then(() => {
                if (this.reelId === CommonConfig.totalReel - 1 && i === 0) {
                    this.killTweens();
                }
                resolve();
            }).catch(reject);
        });
    }

    private killTweens(): void {
        gsap.killTweensOf(this);
        gsap.killTweensOf(this.pos_00);
        gsap.killTweensOf(this.pos_01);
        gsap.killTweensOf(this.pos_02);
        gsap.killTweensOf(this.pos_03);
        gsap.killTweensOf(this.pos_04);
        gsap.killTweensOf(this.stopTheReel);
        gsap.killTweensOf(this.spinTheReel);
        gsap.killTweensOf(this.dropWinReel);
    }

    private resetAfterDropStop(pos: Container, i: number): Promise<void> {
        let duration : number = 0.25;
        return new Promise((resolve) => {
            gsap.to(pos, {
                duration: 0.1,
                y: this.positions[i] - 20,
                ease: "power1.out",
                onComplete: () => {
                    gsap.to(pos, {
                        duration: duration,
                        y: this.positions[i],
                        ease: "power1.out",
                        onComplete: resolve
                    });
                }
            });
        });
    }

    private playStopPosGsap(pos: Container, i: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let distance = this.positions[i] - pos.y;
            let time = this.calculateTime(distance);
            
            gsap.to(pos, {
                duration: time,
                y: this.positions[i] + 20,
                delay: (CommonConfig.symbolsPerReel - i) * this.delayStart,
                ease: "power1.inOut",
                onComplete: () => {
                    this.resetAfterStop(pos, i).then(resolve).catch(reject);
                }
            });
        });
    }

    private playStopForDropPosGsap(pos: Container, i: number): Promise<void> {
        return new Promise((resolve) => {
            let distance = this.positions[i] - pos.y;
            let time = this.calculateTime(distance);
    
            gsap.to(pos, {
                duration: time,
                y: this.positions[i] + 20,
                delay: (CommonConfig.symbolsPerReel - i) * this.delayStart,
                ease: "power1.inOut",
                onComplete: () => {
                    this.resetAfterDropStop(pos, i).then(resolve);
                }
            });
        });
    }

    spinTheReel(): Promise<void> {
        this.isFastReel = CommonConfig.the.getIsFastReel();
        return new Promise((resolve) => {
            this.spinClicked = true;
            let delay = this.delays[Math.floor(Math.random() * this.delays.length)];
            if(this.isFastReel){
                let promises: Promise<void>[] = this.children.map((child, index) => 
                    this.playPosGsap(child, index)
                );
                Promise.all(promises).then(() => resolve()); // Ensure resolve() gets no arguments
            }else{
                gsap.delayedCall(delay * this.reelId, () => {
                    let promises: Promise<void>[] = this.children.map((child, index) => 
                        this.playPosGsap(child, index)
                    );
        
                    Promise.all(promises).then(() => resolve()); // Ensure resolve() gets no arguments
                });
            }
            
        });
    }

    public playWinAnim(posId: number[]): Promise<void> {
        const promises: Promise<void>[] = [];
    
        for (let i = 0; i < posId.length; i++) {
            const promise = new Promise<void>((resolve) => {
                const pos = this.children[posId[i]] as Pos;
                pos.playAnimation().then(() => {
                    resolve();
                });
            });
    
            promises.push(promise);
        }
    
        // Use `.then` to transform `Promise<void[]>` into `Promise<void>`
        return Promise.all(promises).then(() => {});
    }

    public hideSymbolAnim(posId: number): Promise<void> {
        const gsapPromise = new Promise<void>((resolve) => {
            gsap.to((this.children[posId] as Pos).symContainer, {
                duration: 0.3,
                alpha: 0,
                ease: "power1.inOut",
                onComplete: () => resolve(), // Resolve when GSAP animation completes
            });
        });
    
        const hideAnimationPromise = (this.children[posId] as Pos).hideAnimation();
    
        // Wait for both animations to complete
        return Promise.all([gsapPromise,hideAnimationPromise]).then(() => {
            this.children[posId].alpha = 0;
            (this.children[posId] as Pos).symContainer.alpha = 1;
        });
    }

    playAfterHideCurrentSymbol(posId: number[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let maxY: number = -Infinity;
            for (let i: number = 0; i < this.children.length; i++) {
                if (posId.includes(i) && this.children[i].y > maxY) {
                    maxY = this.children[i].y;
                }
            }
            for (let i: number = 0; i < this.children.length; i++) {
                if (this.children[i].y > maxY) {
                    this.notAffectedContainer.push(this.children[i].name);
                }
            }
            let minPos = Math.min(...this.children.map(c => c.position.y));
            let lastIndex: number = posId[0] + posId.length - 1;
            for (let i: number = 0; i < posId.length; i++) {
                this.children[posId[i]].position.set(this.children[posId[i]].x, minPos - CommonConfig.symbolHeight);
                minPos = minPos - CommonConfig.symbolHeight;
            }
            this.reshuffleChildrenInReel(posId[0], posId.length);
            
            // Resolve the Promise once all operations are done
            resolve();
        });
    }

    dropWinReel(): Promise<void> {
        return new Promise(async (resolve) => {
            this.resetSymbolAlpha();
    
            if (CommonConfig.the.getWinReelIds().includes(this.reelId)) {
                if(this.isFastReel){
                    const promises: Promise<void>[] = [];
                    this.children.forEach((value, index) => {
                        if (!this.notAffectedContainer.includes(value.name)) {
                            promises.push(this.playStopForDropPosGsap(value, index));
                        }
                    });
    
                    await Promise.all(promises);
                    this.notAffectedContainer = [];
                    resolve();
                }else{
                    let delay = this.delays[Math.floor(Math.random() * this.delays.length)];
                    gsap.delayedCall(delay, async () => {
                        const promises: Promise<void>[] = [];
                        this.children.forEach((value, index) => {
                            if (!this.notAffectedContainer.includes(value.name)) {
                                promises.push(this.playStopForDropPosGsap(value, index));
                            }
                        });
        
                        await Promise.all(promises);
                        this.notAffectedContainer = [];
                        resolve();
                    });
                }
                
            } else {
                resolve();
            }
        });
    }

    private reshuffleChildrenInReel(startingIndex: number, countOfWinSym: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let fromToBeReshuffledChildrens: Container[] = [];
            let pushedToLastChildrens: Container[] = [];
            let firstIndexToBeReshuffledChildren: number = startingIndex + countOfWinSym;
            for (let i: number = 0; i < this.children.length; i++) {
                if (this.children[i].alpha === 1) {
                    fromToBeReshuffledChildrens.push(this.children[i]);
                }
            }
            for (let i: number = 0; i < this.children.length; i++) {
                if (this.children[i].alpha === 0) {
                    pushedToLastChildrens.push(this.children[i]);
                }
            }
            pushedToLastChildrens.sort((a, b) => a.y - b.y);
            let currentIndex: number = 0;
            for (let i = 0; i < pushedToLastChildrens.length; i++) {
                this.setChildIndex(pushedToLastChildrens[i], currentIndex);
                currentIndex++;
            }
            for (let i = 0; i < fromToBeReshuffledChildrens.length; i++) {
                this.setChildIndex(fromToBeReshuffledChildrens[i], currentIndex);
                currentIndex++;
            }
    
            // Resolve the Promise once all operations are done
            resolve();
        });
    } 

    private resetSymbolAlpha(): void {
        this.children.forEach((value) => {
            value.alpha = 1;
        })
    }
}