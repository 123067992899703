import { Assets, Container, Graphics, Sprite, Spritesheet } from "pixi.js";
import { CommonConfig } from "../../Common/CommonConfig";
import { SymbolPool } from "../Symbol/SymbolPool";
import { Game } from "../game";
import gsap from "gsap";
import { Reel } from "./Reel";
import { Pos } from "./Pos";
import { WinframeReelContainer } from "../Winframe/WinframeReelContainer";
import { WinframeContainer } from "../Winframe/WinframeContainer";
import { ISingleWinDetails } from "../Interface/GameInterface";
import { StaticSymbol } from "../Symbol/StaticSymbol";
import SoundManager from "../Sound/SoundManager";

interface winframeData {
  reelId: number;
  rowId: number;
  direction: number[];
}
export class ReelManager extends Container {
  private maskContainer!: Graphics;
  private reelsContainer!: Container;
  private winframeContainer!: Container;
  private symboldWinIds: number[] = [];
  private currentIndexSymbolWinIds: number = 0;
  private winframeData: winframeData[] = [];
  private reelManagerContainer !: Container;
  private soundManager !: SoundManager;


  constructor() {
    super();
    this.soundManager = SoundManager.getInstance();
    this.reelManagerContainer = new Container();
    this.addChild(this.reelManagerContainer);
    this.initializeReelContainer();
    this.initializeWinframeContainer();
    this.initGraphics();
    this.subscribeEvent();
    let randomWild: number[][] = [
      [1, 4, 8, 6, 5],
      [1, 6, 7, 9, 3],
      [4, 9, 3, 3, 6],
      [3, 3, 3, 4, 2],
      [7, 6, 5, 3, 1],
    ];
    this.updateView(randomWild);
  }

  private subscribeEvent(): void {
    Game.the.app.stage.on(CommonConfig.SET_RESPONSE_AT_REEL, this.setSymbolAtReel, this);
    Game.the.app.stage.on(CommonConfig.START_SPIN, this.spinTheReels, this);
    Game.the.app.stage.on(CommonConfig.PLAY_ANIMATED_WIN_SYMBOL, this.onPlayWinSymbol, this);
    Game.the.app.stage.on(CommonConfig.UPDATE_VIEW_ON_REEL, this.updateView, this);
    Game.the.app.stage.on(CommonConfig.PLAY_CASCADE_DROP_ANIMATION, this.shuffleAndCascadeReel, this);
    Game.the.app.stage.on(CommonConfig.REPLACE_PATICULAR_SYMBOL, this.replaceParticularSymbol, this);
    Game.the.app.stage.on(CommonConfig.SPIN_STOPPED, this.playLandingAnimation, this);
  }

  private playLandingAnimation() :void{
    const promises : Promise<void>[] = [];
    this.reelsContainer.children.forEach((value, index) => {
      (value as Reel).children.forEach((pos, posindex) => {
        const reelPromise = (pos as Pos).playLanding();
        promises.push(reelPromise);
      });
    });
    Promise.all(promises).then(() => {
      Game.the.app.stage.emit(CommonConfig.PRESENTATION_AFTER_LANDING_ANIMATON);
    });
  }

  private initGraphics(): void {
    this.maskContainer = new Graphics();
    this.maskContainer.beginFill(0xffa500);
    this.maskContainer.drawRect(-85, -120, 1000, 1000);
    this.maskContainer.endFill();
    this.maskContainer.position.set(-26.5, -42);
    this.reelManagerContainer.addChild(this.maskContainer);
    this.mask = this.maskContainer;
  }

  private async onPlayWinSymbol(): Promise<void> {
    let winGrid: Map<number, ISingleWinDetails> = CommonConfig.the.getWinGrid();
    this.symboldWinIds = [];
    this.currentIndexSymbolWinIds = 0;
    for (const symbol of winGrid.keys()) {
      this.symboldWinIds.push(winGrid.get(symbol)!.id);
    }
    await this.playAnimations();
  }

  private playWinframeAnimation(): void {
    this.soundManager.play("Symbolmatch");
    // for (let i: number = 0; i < this.winframeData.length; i++) {
    //   let singleWinframedata: winframeData = this.winframeData[i];
    //   let direction: number[] = singleWinframedata.direction;
    //   for (let i = 0; i < direction.length; i++) {
    //     let winlineContainer = (
    //       (
    //         this.winframeContainer.children[
    //         singleWinframedata.reelId
    //         ] as WinframeReelContainer
    //       ).children[singleWinframedata.rowId] as WinframeContainer
    //     ).winLineContainer;
    //     if (direction[i] > 0) {
    //       winlineContainer.children[i].visible = true;
    //     } else {
    //       winlineContainer.children[i].visible = false;
    //     }
    //   }
    // }
  }

  private checkIfNumberIsLessthan(elm: number, arr: number[]): boolean {
    for (let i: number = 0; i < arr.length; i++) {
      if (arr[i] < elm) {
        return true;
      }
    }
    return false;
  }

  private checkIfNumberIsGreaterthan(elm: number, arr: number[]): boolean {
    for (let i: number = 0; i < arr.length; i++) {
      if (arr[i] > elm) {
        return true;
      }
    }
    return false;
  }

  private sortArray(arr: Set<string>): Set<string> {
    const newArr = Array.from(arr).sort((a, b) => {
      const [reelA, rowA] = a.split(",").map(Number);
      const [reelB, rowB] = b.split(",").map(Number);

      if (reelA !== reelB) {
        return reelA - reelB; // Sort by reel first
      } else {
        return rowA - rowB; // If reel is the same, sort by row
      }
    });
    const sortedSet = new Set(newArr);
    return sortedSet;
  }

  private async playAnimations(): Promise<void> {
    let winGrid: Map<number, ISingleWinDetails> = CommonConfig.the.getWinGrid();
    let winReelData: { [key: number]: number[] } = {};
    let winData: Set<string> = winGrid.get(
      this.symboldWinIds[this.currentIndexSymbolWinIds]
    )!.index_set;

    this.createWinFrame(winData);
    this.playWinframeAnimation();
    gsap.delayedCall(0.5, () => {
      Game.the.app.stage.emit(CommonConfig.HIDE_WINFRAME_ANIMATION);
    });

    winData.forEach((position) => {
      let reelRow: string[] = position.split(",");
      if (winReelData.hasOwnProperty(Number(reelRow[0]))) {
        winReelData[Number(reelRow[0])].push(Number(reelRow[1]));
      } else {
        winReelData[Number(reelRow[0])] = [Number(reelRow[1])];
      }
    });

    CommonConfig.the.setWinReelIds([]);
    CommonConfig.the.setLineWinAmount(0);

    // Collect all promises for reel animations
    const reelPromises: Promise<void>[] = [];
    Game.the.app.stage.emit(CommonConfig.DARKEN_SYMBOL);
    Object.keys(winReelData).forEach((key) => {
      const reelKey = parseInt(key, 10);
      const reelPromise = (this.reelsContainer.children[reelKey] as Reel).playWinAnim(
        winReelData[reelKey].sort()
      );
      reelPromises.push(reelPromise);
    });

    // Wait for all reel animations to complete
    await Promise.all(reelPromises);

    let reelX: number = Number(Object.keys(winReelData).sort()[0]);
    let rowY: number = winReelData[reelX].sort()[0];
    CommonConfig.the.setTotalWinSymbolCount(
      CommonConfig.the.getTotalWinSymbolCount() + winData.size
    );
    let cascadeWinAmount: number = CommonConfig.the.getWinAmount(
      this.symboldWinIds[this.currentIndexSymbolWinIds],
      winData.size
    );
    cascadeWinAmount = Number(cascadeWinAmount.toFixed(2));
    CommonConfig.the.setCurrentWinAmount(
      CommonConfig.the.getCurrentWinAmount() + cascadeWinAmount
    );
    CommonConfig.the.setLineWinAmount(cascadeWinAmount);
    Game.the.app.stage.emit(CommonConfig.UPDATE_LINE_WIN_METER, [reelX, rowY]);
    Game.the.app.stage.emit(CommonConfig.UPDATE_WIN_METER);
    Game.the.app.stage.emit(CommonConfig.UPDATE_PENTAGONAL_METER);
    this.currentIndexSymbolWinIds++;
    if (this.currentIndexSymbolWinIds >= this.symboldWinIds.length) {
      this.hideSymbol();
      // Game.the.app.stage.emit(CommonConfig.ON_SHOW_NEXT_WIN_PRESENTATION);
    } else {
      await this.playAnimations(); // Recursively play animations for the next symbol
    }
  }

  private hideSymbol(): Promise<void> {
    this.soundManager.play("SymbolCascade");
    const promises: Promise<void>[] = [];
    const winGrid: Map<number, ISingleWinDetails> = CommonConfig.the.getWinGrid();

    for (const [key, value] of winGrid) {
      const winData: Set<string> = (value as ISingleWinDetails).index_set;
      winData.forEach((position) => {
        const reelRow: string[] = position.split(",");
        const reel = this.reelsContainer.children[Number(reelRow[0])] as Reel;
        promises.push(reel.hideSymbolAnim(Number(reelRow[1])));
      });
    }

    // Wait for all hideSymbolAnim promises to complete, then shuffle and cascade
    return Promise.all(promises).then(() => {
      this.soundManager.play("SymbolDrop");
      this.shuffleAndCascadeReel();
    });
  }

  private createWinFrame(winData: Set<string>): void {
    this.winframeData = [];
    winData = this.sortArray(winData);
    winData.forEach((position) => {
      let reelRow: string[] = position.split(",");
      let direction: number[] = [];
      let leftN: string = [Number(reelRow[0]) - 1, Number(reelRow[1])].join(
        ","
      );
      winData.has(leftN) ? direction.push(-1) : direction.push(1);
      let rightN: string = [Number(reelRow[0]) + 1, Number(reelRow[1])].join(
        ","
      );
      winData.has(rightN) ? direction.push(-1) : direction.push(1);
      let topN: string = [Number(reelRow[0]), Number(reelRow[1]) - 1].join(",");
      winData.has(topN) ? direction.push(-1) : direction.push(1);
      let botttomN: string = [Number(reelRow[0]), Number(reelRow[1]) + 1].join(
        ","
      );
      winData.has(botttomN) ? direction.push(-1) : direction.push(1);
      let winFrameData: winframeData = {
        reelId: Number(reelRow[0]),
        rowId: Number(reelRow[1]),
        direction: direction,
      };
      this.winframeData.push(winFrameData);
    });
  }

  private async shuffleAndCascadeReel(): Promise<void> {
    let winGrid: Map<number, ISingleWinDetails> = CommonConfig.the.getWinGrid();
    let winGridSet: Set<string> = new Set();
    let winReelData: { [key: number]: number[] } = {};
    for (let i: number = 0; i < this.symboldWinIds.length; i++) {
      let winData: Set<string> = winGrid.get(this.symboldWinIds[i])!.index_set;
      winData.forEach((position) => {
        let reelRow: string[] = position.split(",");
        winGridSet.add(position);
        // console.log(reelRow);
        if (winReelData.hasOwnProperty(Number(reelRow[0]))) {
          winReelData[Number(reelRow[0])].push(Number(reelRow[1]));
        } else {
          winReelData[Number(reelRow[0])] = [Number(reelRow[1])];
        }
      });
    }
    let winReelids: number[] = [];
    const hidePromises: Promise<void>[] = [];
    Object.keys(winReelData).forEach((key) => {
      const reelKey = parseInt(key, 10);
      winReelids.push(reelKey);
      const hidePromise = (
        this.reelsContainer.children[reelKey] as Reel
      ).playAfterHideCurrentSymbol(winReelData[reelKey].sort());
      hidePromises.push(hidePromise);
    });

    await Promise.all(hidePromises);
    CommonConfig.the.setWinReelIds(winReelids);
    let response: number[][] = CommonConfig.the.cascade(
      CommonConfig.the.getView(),
      winGridSet
    );
    this.updateView(response);
    const dropReelsPromise : Promise<void>[] = [];
    this.reelsContainer.children.forEach((reel, index) => {
      const dropReelPromise = (reel as Reel).dropWinReel();
      dropReelsPromise.push(dropReelPromise)
    });
    await Promise.all(dropReelsPromise);
    Game.the.app.stage.emit(CommonConfig.ON_SHOW_NEXT_WIN_PRESENTAION);
  }

  private initializeReelContainer(): void {
    this.reelsContainer = new Container();
    this.reelManagerContainer.addChild(this.reelsContainer);
    for (let i: number = 0; i < CommonConfig.totalReel; i++) {
      const reel: Reel = new Reel(i);
      reel.position.set(CommonConfig.reelWidth * i, 0);
      this.reelsContainer.addChild(reel);
    }
  }

  private initializeWinframeContainer(): void {
    this.winframeContainer = new Container();
    // this.reelManagerContainer.addChild(this.winframeContainer);
    // for (let i: number = 0; i < CommonConfig.totalReel; i++) {
    //   const winframeReel: WinframeReelContainer = new WinframeReelContainer(i);
    //   winframeReel.position.set(CommonConfig.reelWidth * i, 0);
    //   this.winframeContainer.addChild(winframeReel);
    // }
  }

  private updateView(response: number[][]): void {
    CommonConfig.the.setView(response);
    this.reelsContainer.children.forEach((value, index) => {
      (value as Reel).children.forEach((pos, posindex) => {
        let symbol = SymbolPool.the.getSymbol(
          CommonConfig.symbolIds[Number(response[index][posindex])]
        );
        (pos as Pos).getSymContainer().removeChildren();
        (pos as Pos).updatePosWithSym(symbol as StaticSymbol);
      });
    });
  }

  private replaceParticularSymbol(reelPos: number[]): void {
    // CommonConfig.the.setView(response);
    const reel: Reel = this.reelsContainer.children[reelPos[0]] as Reel;
    let posContainer: Pos = (reel as Reel).children[reelPos[1]] as Pos;
    let symbol = SymbolPool.the.getSymbol(
      CommonConfig.symbolIds[CommonConfig.the.getView()[reelPos[0]][reelPos[1]]]);
    // (posContainer as Pos).getSymContainer().removeChildren();
    (posContainer as Pos).replacePosWithSym(symbol as StaticSymbol);
  }

  private setSymbolAtReel(): void {
    let response: number[][] = CommonConfig.the.generateRandomView();
    CommonConfig.the.setView(response);
    this.reelsContainer.children.forEach((reel, index) => {
      (reel as Reel).children.forEach((pos, posindex) => {
        let symbol = SymbolPool.the.getSymbol(
          CommonConfig.symbolIds[Number(response[index][posindex])]
        );
        (pos as Pos).getSymContainer().removeChildren();
        (pos as Pos).updatePosWithSym(symbol as StaticSymbol);
      });
    });
  }

  async spinTheReels(): Promise<void> {
    this.soundManager.play("SpinVariation");
    const spinPromises : Promise<void>[] = [];
    this.reelsContainer.children.forEach((reel, index) => {
      const spinPromise = (reel as Reel).spinTheReel();
      spinPromises.push(spinPromise);
    });

    await Promise.all(spinPromises);
    Game.the.app.stage.emit(CommonConfig.SET_RESPONSE_AT_REEL);
    this.soundManager.play("SymbolDrop");
    const stopPromises : Promise<void>[] = [];
    this.reelsContainer.children.forEach((reel, index) => {
      const stopPromise = (reel as Reel).stopTheReel();
      stopPromises.push(stopPromise);
    });
    await Promise.all(stopPromises);
    Game.the.app.stage.emit(CommonConfig.SPIN_STOPPED);
  }
}