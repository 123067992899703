import { Spine } from "@esotericsoftware/spine-pixi-v8";
import { Container } from "pixi.js";
import gsap from "gsap";
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { CommonConfig } from "@/Common/CommonConfig";
import { Game } from "../game";
import SoundManager from "../Sound/SoundManager";


export class ZwoomFeatureContainer extends Container{
    private zwoomHead !: Spine;
    private state !: string;
    private soundManager !: SoundManager;

    constructor(state : string) {
        super();
        this.state = state;
        if(this.state === CommonConfig.FREE_Game){
            this.initAfterPostLoad();
         }
        Game.the.app.stage.on(CommonConfig.INIT_AFTER_POST_ASSETS_LOAD, this.initAfterPostLoad, this);        
    }

    private initAfterPostLoad() :void{
        this.soundManager = SoundManager.getInstance();
        this.init();
        this.addToStage();
        gsap.registerPlugin(MotionPathPlugin);
        Game.the.app.stage.on(CommonConfig.PLAY_PENTAGONAL_HEAD_ANIMATION, this.parabolicAnimation,this);
    }

    private init() :void{
        this.zwoomHead = Spine.from({skeleton: "Zwoom_Basculos_Head_animation_spine_data", atlas: "Zwoom_Basculos_Head_animation_spine_atlas"});
        // this.zwoomHead.pivot.set(this.zwoomHead.width, this.zwoomHead.height);
        this.zwoomHead.position.set(0,36.5);
        // this.zwoomHead.state.setAnimation(0,"On", true);
        this.zwoomHead.alpha = 0;
        // this.parabolicAnimation();
    }

    private addToStage() :void{
        this.addChild(this.zwoomHead);
    }

    private parabolicAnimation(callback? : any) : void{
        // this.zwoomHead.state.setAnimation(0,"On", true);
        const startX = window.innerHeight < window.innerWidth ? -this.zwoomHead.width/2 : 124;
        const startY = window.innerHeight < window.innerWidth ? -70 : -1074;
        const endX =   window.innerHeight > window.innerWidth ? -864 : -1384.5;
        const endY =   window.innerHeight < window.innerWidth ? -70 : -1074;
        this.zwoomHead.position.set(0,36.5);
        this.zwoomHead.alpha = 0;
        Game.the.app.stage.emit(CommonConfig.CHARACTER_HEAD_DISAPPEAR_ANIMATION);
        this.zwoomHead.scale.set(0,0);
        gsap.to(this.zwoomHead.scale, {
            x : 1,
            y : 1,
            duration : 0.7,
            ease :"power4.in",
        })
        this.soundManager.play("headdetach");
        gsap.delayedCall(2,()=>{
            this.soundManager.play("headspin");
        })
        gsap.to(this.zwoomHead, {
            alpha : 1,
            duration : 0.5,
            ease :"power4.in",
            onComplete : () =>{
                gsap.to(this.zwoomHead, {
                    x : startX,
                    y : startY,
                    delay : 0.2,
                    duration : 0.75,
                    ease :"power4.in",
                    onComplete : () =>{
                        const parabolicPath = [
                            { x: startX, y: startY },  // Start
                            { x: endX, y: startY + 200 },  // Control point (apex of the parabola)
                            { x: endX, y: endY },  // End
                        ];
                        
                        gsap.to(this.zwoomHead, {
                            motionPath: {
                                path: parabolicPath, // Path defined above
                                curviness: 1.5,      // Adjusts the smoothness of the curve
                                autoRotate: false,   // Set to true if you want the sprite to rotate along the path
                            },
                            duration: 2, // Duration of animation in seconds
                            ease: 'power1.inOut',
                            onComplete :() =>{
                                // this.ReverseparabolicAnimation();
                                this.soundManager.play("firingsound");
                                Game.the.app.stage.emit(CommonConfig.PLAY_SHOOTING_ANIMATION,'left');
                                gsap.delayedCall(1.4,()=>{
                                    this.ReverseparabolicAnimation(callback);
                                });
                            }
                        });
                    }
                })
            }
        })
    }

    private ReverseparabolicAnimation(callback ?: any) : void{
        const startX = window.innerHeight > window.innerWidth ? -864 : -1384.5;
        const startY = window.innerHeight < window.innerWidth ? -70 : -1074;
        const endX = window.innerHeight < window.innerWidth ? -this.zwoomHead.width/2 : 124;
        const endY = window.innerHeight < window.innerWidth ? -70 : -1074;
        const parabolicPath = [
            { x: startX, y: startY },  // Start
            { x: endX, y: startY + 200 },  // Control point (apex of the parabola)
            { x: endX, y: endY },  // End
        ];
        this.soundManager.play("headspin");
        gsap.to(this.zwoomHead, {
            motionPath: {
                path: parabolicPath, // Path defined above
                curviness: 1.5,      // Adjusts the smoothness of the curve
                autoRotate: false,   // Set to true if you want the sprite to rotate along the path
            },
            duration: 1, // Duration of animation in seconds
            ease: 'power1.inOut', 
            onComplete : () =>{
                this.soundManager.play("firingsound");
                Game.the.app.stage.emit(CommonConfig.PLAY_SHOOTING_ANIMATION,'right');
                gsap.to(this.zwoomHead, {
                    alpha : 0,
                    delay : 0.7,
                    duration : 0.6,
                    ease :"power4.in",
                    onStart : () =>{
                        Game.the.app.stage.emit(CommonConfig.CHARACTER_HEAD_APPEAR_ANIMATION);
                        gsap.to(this.zwoomHead.scale, {
                            x : 0,
                            y : 0,
                            duration : 0.62,
                            ease :"power4.in",
                        })
                        this.soundManager.play("headdetach");
                        gsap.to(this.zwoomHead, {
                            x : 0,
                            y : 36.5,
                            duration : 0.4,
                            ease :"power4.in",
                            onComplete :() =>{
                                gsap.delayedCall(0.2,()=>{
                                    callback();
                                    this.zwoomHead.state.clearListeners()
                                    this.zwoomHead.state.tracks = [];
                                });
                            }
                        })
                    }
                })
            }
        });
    }
}