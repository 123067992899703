// Game.ts
import { Application, Assets, Container, ICanvas, SCALE_MODES} from "pixi.js";
import { CommonConfig } from "../Common/CommonConfig";
import { CommonEvents } from "@/Common/CommonEvents";
import { GameConfig } from "./GameConfiguration/GameConfig";
import { StateManagement } from "./State/StateManagement";
import { LoadingScreen } from "./Loading/LoadingScreen";
import { Intro } from "./Intro/Intro";
export class Game {
  protected static _the: Game;
  public app: Application;
  private gameContainer!: Container;
  private isLocaltesting: boolean = false;
  private loadingScreen!: LoadingScreen;
  private intro ! : Intro;
  private aspectRatioWidth : number = 1;
  private aspectRatioHeight : number = 1;
  private aspectRatio : number = 1;

  static get the(): Game {
    if (!Game._the) {
      Game._the = new Game();
    }

    return Game._the;
  }

  constructor() {
    if (Game._the == null) Game._the = this;
    new CommonConfig();
    this.app = new Application();
    (globalThis as any).__PIXI_APP__ = this.app;
    this.init();
  }

  async init(): Promise<void> {
    await this.app.init({
    //   width: CommonConfig.GAME_WIDTH,
    //   height:  CommonConfig.GAME_HEIGHT,
    resolution: window.devicePixelRatio,
    autoDensity: true,
    antialias : false,
    // resizeTo: window,
    });

    const pixiContainer = document.getElementById("pixi-container");
    if (pixiContainer) {
      pixiContainer.appendChild(this.app.view);
    }
    this.app.resize = this.resize.bind(this);
    this.gameContainer = new Container();
    this.app.stage.addChild(this.gameContainer);
    this.loadAssetsAndInitialize();
    this.resize();
    window.onresize = this.resize.bind(this);
  }

  private initLoadingObj(): void {
    this.loadingScreen = new LoadingScreen();
    this.gameContainer.addChild(this.loadingScreen);
    this.intro = new Intro();
    this.gameContainer.addChild(this.intro);
    // this.resize();
  }

  private async loadLoadingSpine() {
    this.initLoadingObj();
    await Assets.init({ manifest: "./manifest.json" });
    await Assets.loadBundle([
      "Loading-assets"
    ])
    this.loadingScreen.playLogoSpine();
    this.loadImages();
    Game.the.app.stage.on(CommonConfig.HIDE_INTRO_PAGE_SHOW_BASEGAME,this.removeIntroAndLoading, this);
  }

  private async loadImages() {
    // await Assets.init({ manifest: "./manifest.json" });
    await Assets.loadBundle([
      "intro-assets",
      "intro-spine-assets",
      "pentagonal-spine",
      "background-image",
      "font-assets",
      "ReelFrame-Component",
      "static-button",
      "Feature-Assets",
      "win-text-assets",
      "ReelFrame-freegame-image",
      "bonus-background-image",
      "background-spine",
      "character-spine",
      "symbol-animation-assets",
      "win_animation",
      "bonus-background-spine",
      "randomfeature_animation"
    ],(data)=>{
      this.loadingScreen.loadingAnimation(data);
    });
    
    Game.the.app.stage.emit(CommonConfig.SHOW_INTRO_PAGE);
    this.intoBaseGame();
    this.gameContainer.setChildIndex(this.intro,this.gameContainer.children.length-1);
  }

  private intoBaseGame() :void{
    this.onLoadComplete();
  }


  private loadAssetsAndInitialize() {
    this.loadLoadingSpine();
    new CommonEvents();
    new GameConfig();
  }

  private async postLoadAnimation() :Promise<void>{
    

  }

  private async onLoadComplete() {
    this.loadingScreen.visible = false;
    this.loadingScreen.alpha = 0;
    this.gameContainer.addChild(new StateManagement());
    Game.the.app.stage.emit("RESIZE_THE_APP");
    this.aspectRatioWidth = 5350/1920;
    this.aspectRatioHeight = 4875/913;
    this.aspectRatio = 5350/4875;
    this.resize();
    this.postLoadAnimation();
    // new SoundManager();
  }

  resize() {
    if((window.innerHeight < window.innerWidth)){
      let currentScaleX = ((5350/1920) * window.innerWidth)/5350;
      let currentScaleY = ((4875/953) * window.innerWidth)/4875;
      let currentScale : number = currentScaleX > currentScaleY ? currentScaleY : currentScaleX;
      const scale = ((1920/953)* window.innerHeight)/1920;
      const newscale = currentScaleX < scale ? currentScale : scale;
      this.gameContainer.scale.set(newscale * 1.02);
      let y = (window.innerHeight - (953 * newscale))/2;
      let x = (window.innerWidth - (1920 * newscale))/2;
      this.gameContainer.y = y + (32 * newscale);
      this.gameContainer.x = x;
      
    }else{
      let currentScaleX = ((5350/800) * window.innerWidth)/5350;
      let currentScaleY = ((4875/1734) * window.innerHeight)/4875;
      let currentScale : number = currentScaleX > currentScaleY ? currentScaleY : currentScaleX;
      let scale = ((800/1734)* window.innerHeight)/800;
      scale = currentScaleX < scale ? currentScale : scale;
      this.gameContainer.scale.set(scale * 0.95);
      let y = (window.innerHeight - (1734 * scale * 0.95)) * 0.5;
      let x = (window.innerWidth - (800 * scale)) * 0.5;
      this.gameContainer.y = y + (20 * scale);
      this.gameContainer.x = x + (72 * scale);
    }
    Game.the.app.stage.emit("RESIZE_THE_APP");
    this.app.renderer.resize(window.innerWidth, window.innerHeight);
    // this.gameContainer.position.set((window.innerWidth - this.app.stage.width)/2,(window.innerHeight - this.app.stage.height)/2)
  }

  private removeIntroAndLoading() :void{
    this.intro.removeChildren(0,this.intro.children.length);
    this.loadingScreen.removeChildren(0,this.loadingScreen.children.length);
    Game.the.app.stage.emit(CommonConfig.INIT_AFTER_POST_ASSETS_LOAD);
    Game.the.app.stage.emit("RESIZE_THE_APP");
  }
}
